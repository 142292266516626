import React, { useContext, useEffect, useState } from "react";
import styles from "./Meetings.module.scss";
import _ from "lodash";
import { useMutation, useQuery } from "@apollo/client";
import gql from "graphql-tag";
import useCorpPlan from "../../hooks/useCorpPlan";
import useTableFilters from "../../hooks/useTableFilters";
import CardTitle from "../../components/CardTitle/CardTitle";
import { Button, Container, Grid } from "@material-ui/core";
import {
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  Card,
  CardContent,
  CardActions,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  TableSortLabel,  
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Hidden,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";

import { DialogContext } from "../../context/dialogContext";
import { FetchContext } from "../../context/fetchContext";
import { SnackbarContext } from "../../context/snackbarContext";
import Loading from "../../components/Loading/Loading";
import { mdiPlus } from "@mdi/js";
import Icon from "@mdi/react";
import { isAuthed } from "../../utils/authorization";
import MeetingCard from "./MeetingCard";
import MeetingRow from "./MeetingRow";

import { GET_MEETINGS } from "../../utils/query";
import { MEETING_FIELDS } from "../../utils/fragments";
import { MEETINGS_SUBSCRIPTION } from "../../utils/subscriptions";

import { format, isToday } from "date-fns";

const getDateString = (date) => {
  const dateObj = new Date(parseInt(date));
  const dateIsToday = isToday(dateObj);
  const formattedDate = format(dateObj, "MMM dd, yyyy");
  const formattedTime = format(dateObj, "h:mm a");

  return `${dateIsToday ? "Today" : formattedDate} @ ${formattedTime}`;
};


const Meetings = ({ user, params, org }) => {
  const { dialog, setDialog } = useContext(DialogContext);
  const { snack } = useContext(SnackbarContext);
  const isAdmin = isAuthed(user.user, "department facilitator");

  const { corpForSelectedYear } = useCorpPlan({ orgId: params.org, fiscalYear: org.fiscalYear });

  const [selectedMeeting, setSelectedMeeting] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [showArchived, setShowArchived] = useState(JSON.parse(localStorage.getItem(`meetings.showArchived`)) || false);

  // pagination
  /*const { page, rowsPerPage, searchTerm, debouncedSearchTerm, handleChangePage, handleRowsPerPage, setSearchTerm } = useTableFilters({
    initialValue: {},
  });*/
  const location = 'meetings';
  const {
    page,
    rowsPerPage,
    sort,
    searchTerm,
    debouncedSearchTerm,
    showCompleted,
    handleChangePage,
    handleRowsPerPage,
    setSort,
    setSearchTerm,
    setShowCompleted,
  } = useTableFilters({
    location,
    initialValue: {
      rowsPerPage: JSON.parse(localStorage.getItem(`${location}.rowsPerPage`)) || 10,
      showCompleted: JSON.parse(localStorage.getItem(`${location}.showCompleted`)) || false,
      sortValue: JSON.parse(localStorage.getItem(`${location}.sortValue`)) || "startTime",
      sortOrder: JSON.parse(localStorage.getItem(`${location}.sortOrder`)) || "asc",
    },
  });


  let variables;
  if (!showArchived) {
    variables = {
      organization: params.org,
      sharedPlanId: user.departmentFilter.sharedPlanId,
      meetingUser: user.user.id,
      archived: false,
      sortBy: 'startTime',
      sortDir: 'desc'      
    }
  }
  else {
    variables = {
      organization: params.org,
      sharedPlanId: user.departmentFilter.sharedPlanId,
      meetingUser: user.user.id,
      page,
      rowsPerPage, 
      searchTerm: debouncedSearchTerm,
      sortBy: sort.value,
      sortDir: sort.order
    }    
  }

  const { data, loading, subscribeToMore } = useQuery(GET_MEETINGS, {
    variables: variables,
    fetchPolicy: "network-only",
  });

  useEffect(
    () => {

      if (loading) {
        return;
      }

      const unsubscribe = subscribeToMore({
        document: MEETINGS_SUBSCRIPTION,
        variables: { meetingUser: user.user.id },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) {
            //console.log("*** MEETINGS_SUBSCRIPTION Returning Previous");
            return prev;
          }

          const meeting = subscriptionData.data.meetingPayload.meetingMutated;
          const action = subscriptionData.data.meetingPayload.action;

          // Initialize prev.meetings if it's null, undefined, or empty
          const currentMeetings = prev?.meetings || [];

          let newMeetings;
          let exists;
          switch (action) {
            case "update":
              exists = currentMeetings.some((t) => t.id === meeting.id);
              newMeetings = exists ? currentMeetings.map((t) => (t.id === meeting.id ? meeting : t)) : [...currentMeetings, meeting];
              break;
            case "delete":
              newMeetings = currentMeetings.filter((t) => t.id !== meeting.id);
              break;
            case "create":
              exists = currentMeetings.some((t) => t.id === meeting.id);
              newMeetings = exists ? currentMeetings : [...currentMeetings, meeting];
              break;
            default:
              break;
          }

          return Object.assign({}, prev, {
            meetings: newMeetings,
          });
        },
      });
      return () => {
        unsubscribe();
      };
    },
    [loading, subscribeToMore, user.user.id]
  );

  const [deleteMeeting, { loading: deleteLoading }] = useMutation(DELETE_MEETING, {
    // update(cache, { data: { deleteMeeting } }) {
    //   const deleteMeetingId = cache.identify(deleteMeeting);
    //   cache.modify({
    //     fields: {
    //       meetings: (existingMeetings) => {
    //         return existingMeetings.filter((meetingRef) => {
    //           const meetingId = cache.identify(meetingRef);
    //           return meetingId !== deleteMeetingId;
    //         });
    //       },
    //     },
    //   });
    // },
  });

  const handleOpenDialog = () => {
    setDialog({
      ...dialog,
      addMeetingDialog: { open: true, corpPlanId: _.get(corpForSelectedYear, "id"), fiscalYear: org.fiscalYear }, // also need to pass the query variables if you want to update the cache after the mutation
    });
  };

  const handleDelete = (id, scheduleNext = false) => async () => {

    const response = await deleteMeeting({
      variables: { id, scheduleNext },
    });
    if (response.data.deleteMeeting) {
      snack(`Meeting deleted`);
      // requestFetch();
      setDeleteDialogOpen(false);
      setSelectedMeeting(null);  
    } else {
      snack("Something went wrong, please try again later", "error");
    }
        
  };

  const handleConfirmDelete = (meeting, scheduleNext) => () => {
      setDeleteDialogOpen(true);
      setSelectedMeeting({ meeting, scheduleNext });  
      console.log("handleConfirmDelete", meeting, scheduleNext);
  }

   const handleShowArchived = () => {
     localStorage.setItem(`meetings.showArchived`, JSON.stringify(!showArchived));
     setShowArchived(!showArchived);
   };

   const handleSort = (value) => () => {
    const order = sort.value === value && sort.order === "desc" ? "asc" : "desc";
    if (value) {
      localStorage.setItem(`${location}.sortValue`, JSON.stringify(value));
    }
    localStorage.setItem(`${location}.sortOrder`, JSON.stringify(order));
    setSort({ value, order });
  };

  if (loading || !data) return <Loading />;

  const meetings = _.get(data, "meetings", []);
  const count = _.get(data, "meetingsCount", meetings.length);

  return (
    <>
      {!showArchived ? (
        <Container maxWidth={false}>
          <div style={{ display: "flex", justifyContent: "flex-end", borderBottom: "1px solid gray" }}>
            <Button variant="outlined" onClick={handleShowArchived} size="large" className={styles.menuButton}>
              {showArchived ? "Show Upcoming" : "Show Archived"}
            </Button>   
            <Button
              startIcon={<Icon path={mdiPlus} size={1} color="#fff" />}
              className={styles.menuButtonColor}
              onClick={handleOpenDialog}
              variant="contained"
              color="primary"
              data-test='button-new-meeting1'
              disabled={!isAdmin}
            >
              New Meeting
            </Button>
          </div>
          {_.isEmpty(meetings) ? (
            <div>No meetings created</div>
          ) : (
            <Grid container spacing={3} style={{ marginTop: "16px" }}>
              {meetings.map((meeting, idx) => (
                <Grid item xs={12} sm={6} md={4} key={meeting.id}>
                  <MeetingCard
                    meeting={meeting}
                    org={org}
                    handleDelete={handleConfirmDelete}
                    isAdmin={isAdmin}
                    currentCorpPlan={corpForSelectedYear}
                  />
                </Grid>
              ))}
              <Grid item xs={12}></Grid>
            </Grid>
          )}
        </Container>
       ) : (

        <Container maxWidth={false}>
          <Grid container spacing={3}>
            <Hidden xsDown>
              <Grid item xs={12}>
                <Typography variant="h6" className={styles.label}>
                  Manage Meetings
                </Typography>
              </Grid>
            </Hidden>
            <Grid item xs={12}>
              <Card className={styles.card}>
                <CardTitle vertical color="deepPurple">
                  <Typography variant="h5" className={styles.title}>
                    Meetings
                  </Typography>
                </CardTitle>
                <CardActions className={styles.cardActions}>
                 <TextField
                  className={styles.searchField}
                  label="Search"
                  type="search"
                  variant="outlined"
                  size="small"
                  value={searchTerm || ""}
                  onChange={(event) => {
                    setSearchTerm(event.target.value);
                  }}
                  />                  
                  <Button variant="outlined" onClick={handleShowArchived} size="large" className={styles.menuButton}>
                    {showArchived ? "Show Upcoming" : "Show Archived"}
                  </Button>         
                  <Button
                    startIcon={<AddIcon />}
                    className={styles.menuButtonColor}
                    onClick={handleOpenDialog}
                    variant="contained"
                    color="primary"
                    data-test='button-new-meeting2'                    
                    disabled={!isAdmin}
                  >
                    New Meeting
                  </Button>                  
                </CardActions>
                <CardContent className={styles.cardContent}>
                  <Table size="small" className={styles.table}>
                    <colgroup>
                      <col style={{ width: "15%" }} />
                      <col style={{ width: "15%" }} />
                      <col style={{ width: "30%" }} />
                      <col style={{ width: "15%" }} />
                      <col style={{ width: "10%" }} />
                      <col style={{ width: "15%" }} />
                    </colgroup>                    
                    <TableHead>
                      <TableRow>
                        <TableCell className={styles.firstCell} align="center" sortDirection={sort.value === "status" ? sort.order : false}>
                          <TableSortLabel
                            onClick={handleSort("status")}
                            active={sort.value === "status"}
                            direction={sort.value === "status" ? sort.order : "asc"}
                          >
                            Status
                          </TableSortLabel>
                        </TableCell>

                        <TableCell align="center" sortDirection={sort.value === "startTime" ? sort.order : false}>
                          <TableSortLabel
                            onClick={handleSort("startTime")}
                            active={sort.value === "startTime"}
                            direction={sort.value === "startTime" ? sort.order : "asc"}
                          >
                            Scheduled Date/Time
                          </TableSortLabel>
                        </TableCell>
                        <TableCell sortDirection={sort.value === "title" ? sort.order : false}>
                          <TableSortLabel
                            onClick={handleSort("title")}
                            active={sort.value === "title"}
                            direction={sort.value === "title" ? sort.order : "asc"}
                          >
                            Title
                          </TableSortLabel>
                        </TableCell>                        
                        <TableCell align="center" >Department</TableCell>
                        <TableCell align="center" >Invitees</TableCell>
                        <TableCell align="center" ></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>                  
                      {meetings &&
                        meetings.map((meeting) => {
                          return (
                            <MeetingRow
                              key={meeting.id}
                              meeting={meeting}
                              snack={snack}
                              handleDelete={handleConfirmDelete}
                              org={org}
                              isAdmin={isAdmin}
                              currentCorpPlan={corpForSelectedYear}                              
                            />
                          );
                        })}
                    </TableBody>
                  </Table>
                  <TablePagination
                    page={page}
                    rowsPerPage={rowsPerPage}
                    count={count}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleRowsPerPage}
                    component="div"
                  />
                </CardContent>
              </Card>
            </Grid>

          </Grid>
        </Container>

       )}

      {/* Delete Dialog */}
      {selectedMeeting && (
        <Dialog
          open={deleteDialogOpen}
          onClose={(event, reason) => {
            if (reason !== "backdropClick") {
              setDeleteDialogOpen(false);
            }
          }}
        >
          <DialogTitle>Delete Meeting</DialogTitle>
          <DialogContent>
            Are you sure you want to delete the following meeting?<br /><ul><li>{selectedMeeting?.meeting?.title} ({getDateString(selectedMeeting?.meeting?.startTime)})</li></ul>?
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              onClick={() => {
                setDeleteDialogOpen(false);
                setSelectedMeeting(null);
              }}
            >
              Cancel
            </Button>
            <Button color="primary" onClick={() => handleDelete(selectedMeeting?.meeting?.id, selectedMeeting?.scheduleNext)()} variant="contained">
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      )}

    </>
  );
};

export default Meetings;

const DELETE_MEETING = gql`
  mutation Meetings_DeleteMeeting($id: ID!, $scheduleNext: Boolean!) {
    deleteMeeting(id: $id, scheduleNext: $scheduleNext) {
      id
    }
  }
`;

