import React, { useEffect, useState } from "react";
import styles from "./AddStepContentDialog.module.scss";
import useAddToMeeting from "../../hooks/useAddToMeeting";
import _ from "lodash";
import useForm from "../../hooks/useForm";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, useMediaQuery } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Loading from "../Loading/Loading";
import StepContentItemEditorForm from "../MeetingStepContent/StepContentItemEditorForm"

import { getReferenceModelFromType } from "../../utils/misc";

import { HEADLINE_CATEGORIES } from "../../utils/const";
import { addToDate } from "../../utils/dates";
import { constant } from "lodash/fp";

const AddStepContentDialog = ({ dialog, setDialog, params, snack, user }) => {

  //const params = useParams();
  const [loading, setLoading] = useState(false);
  const [itemForm, setItemForm] = useState({isValid: false, form: null});

  const handleLoadingChange = (loadingState) => {
    setLoading(loadingState);
  };

  const handleItemFormChange = (itemForm) => {
    setItemForm((prevState) => ({
      ...prevState,
      ...itemForm,
    }));
  };

  const [meeting, setMeeting] = useState({});

  const contentType = _.get(dialog, "addStepContentDialog.contentType", null);
  const referenceModel = getReferenceModelFromType(contentType);

  const { addToMeeting, getItemStepIndices, updateMeetingSteps } = useAddToMeeting();
  //const fs = useMediaQuery("(max-width: 600px)");

  const {stepIndex, contentIndex } =  getItemStepIndices(meeting, referenceModel);
  let stepDescription = '';
  let lockContentTypes = [contentType];
  let contentItem = null;
  if (!_.isNil(stepIndex)) {
    if (!_.isNil(contentIndex)) {
      contentItem=_.get(meeting, `steps[${stepIndex}].content[${contentIndex}]`, null);
    }

    const stepValue = _.get(meeting, `steps[${stepIndex}].value`, []);
    stepDescription = _.capitalize(stepValue);
  }

  const defaultType = Object.values(HEADLINE_CATEGORIES).find(category => category.default)?.value || HEADLINE_CATEGORIES['general'].value;

  const initForm = {
      meeting: null,
  };
  const initErrorForm = {
      meeting: ["required"]   
  };

  const { form, formErrors, handleChange, handleChangeManual, resetForm, validateForm } = useForm({
      initForm,
      initErrorForm,
  });

  
  const handleAddEdit = async (values) => {
    let newSteps= _.cloneDeep(_.get(meeting, "steps", null));
    let newMSs = _.cloneDeep(_.get(newSteps, `[${stepIndex}].content`,null));
    if (_.isNil(contentIndex)) {
      newMSs.push(values);
    } else {
      let msToUpdate = newMSs[contentIndex];
      _.set(newMSs, [contentIndex], { ...msToUpdate, ...values });
    }
    _.set(newSteps, `[${stepIndex}].content`, newMSs );
    
    const flattenedSteps =  newSteps.map((step) => {
      step.content = (step.content || []).map((contentItem) => {
        contentItem.referenceIds = (contentItem.referenceIds || []).map((refId) => _.get(refId, "id", _.get(refId, "_id")));
        return contentItem;
      });
      return step;
    });

    if (await updateMeetingSteps (meeting, flattenedSteps)) {
      snack(`Successfully updated the meeting's steps`);
    }
    else {
      snack(`Unable to update the meeting`, `error`);
    }

  };

  const handleSubmit = (close) => async () => {
    if (!itemForm.isValid) return;
    handleAddEdit(itemForm.form);
    
    if (close) {
      handleClose();
    }
    
  };
  
  const handleClose = () => {
    setDialog({ ...dialog, addStepContentDialog: { open: false } });
  };

  useEffect(() => {
    if (_.get(dialog, "addStepContentDialog.open", false)) {
      const existingMeeting = _.get(dialog, "addStepContentDialog.meeting", {});
      setMeeting(existingMeeting);
    }
  }, [dialog.addStepContentDialog]);

  const corpPlanId = _.get(meeting, "plan.id", null) || _.get(dialog, "addStepContentDialog.corpPlanId");
  return (
    <Dialog
      open={Boolean(dialog.addStepContentDialog.open)}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
      fullWidth
    >
      <DialogTitle>
        <div className={styles.title}>
          {contentType === "prompt" ? 'Add Ice-breakers to Meeting' : 'Add Text Block Content to Meeting'}
          <div>
            <IconButton onClick={handleClose} size="small">
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </div>
        </div>
      </DialogTitle>
      <DialogContent>      
        <StepContentItemEditorForm
          contentItem={contentItem}
          lockContentTypes={lockContentTypes}
          stepDescription={stepDescription}
          org={params.org}
          planId={corpPlanId} 
          sharedPlanId={_.get(meeting, "sharedPlanId", null)}
          multiple
          onLoadingChange={handleLoadingChange}
          onFormChange={handleItemFormChange}
        />
      </DialogContent>
      <DialogActions>     
        <Button onClick={handleSubmit(true)} color="primary" variant="contained" disabled={loading} className={styles.button}>
          {loading ? <Loading size={24} color="#fff" /> : "Update"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddStepContentDialog;


