import React, { useState, useEffect, useContext } from "react";
import styles from "./Reports.module.scss";
import _ from "lodash";
import { useLazyQuery, useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { FetchContext } from "../../context/fetchContext";
import { UserContext } from "../../context/userContext";
import { LoadingContext } from "../../context/loadingContext";
import { Container, Grid, Typography, Button } from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import FilterMenus from "./FilterMenus";
import { year as getYear, fiscalYearStart, formatAs, endOfWeek, startOfWeek, after, subToDate, getCurrentQuarter, quarterDates, addToDate } from "../../utils/dates";
import Loading from "../../components/Loading/Loading";
import Rocks from "../../components/Rocks/Rocks";
import WeeklyTargets from "../../components/WeeklyTargets/WeeklyTargets";
import Objectives from "../../components/Objectives/Objectives";
import Metrics from "../../components/Metrics/Metrics";
import Todos from "../../components/Todos/Todos";
import {
  convertToMetricsReferenceMap,
  convertToWeeklyTargetsReferenceMap,
  getCurrentThreeYearPlan,
  parseMetricValueUsingFormula,
  parseWTValueUsingFormula,
} from "../../utils/misc";
import { add, format, isAfter, isBefore, isEqual } from "date-fns";
import useTableFilters from "../../hooks/useTableFilters";
import { METRIC_FIELDS, OBJECTIVE_FIELDS, ROCK_FIELDS, TODO_FIELDS, WEEKLY_TARGET_FIELDS } from "../../utils/fragments";
import useCorpPlan from "../../hooks/useCorpPlan";

const VIEW_MENU = ["To Dos", "Issues", "Rocks", "KPIs", "1 Year Objectives", "3 Year Objectives", "3 Year Metrics"];

const Reports = ({ params, org }) => {
  const { user } = useContext(UserContext);
  const { resetLoading } = useContext(LoadingContext);
  const { corpForSelectedYear } = useCorpPlan({ orgId: params.org, fiscalYear: org.fiscalYear });
  const { fetch } = useContext(FetchContext);
  const [filters, setFilters] = useState({
    owner: [],
    quarter: [],
    objective: [],
    status: [],
  });
  const [views, setViews] = useState();
  const [options, setOptions] = useState({ todoShowComplete: false, issueShowComplete: false });

  // todo
  const {
    page,
    rowsPerPage,
    sort,
    searchTerm,
    debouncedSearchTerm,
    showCompleted,
    handleChangePage,
    handleRowsPerPage,
    setSort,
    setSearchTerm,
    setShowCompleted,
  } = useTableFilters({
    initialValue: {
      showCompleted: false,
    },
  });

  // issue
  const {
    page: page2,
    rowsPerPage: rowsPerPage2,
    sort: sort2,
    searchTerm: searchTerm2,
    debouncedSearchTerm: debouncedSearchTerm2,
    showCompleted: showCompleted2,
    handleChangePage: handleChangePage2,
    handleRowsPerPage: handleRowsPerPage2,
    setSort: setSort2,
    setSearchTerm: setSearchTerm2,
    setShowCompleted: setShowCompleted2,
  } = useTableFilters({
    initialValue: {
      showCompleted: false,
    },
  });

  useEffect(() => {
    handleChangePage({}, 0);
    handleChangePage2({}, 0);
  }, [user.departmentFilter.sharedPlanId]);

  const { data: threeYearCorpPlansData, loading: threeYearCorpLoading } = useQuery(GET_THREE_YEAR_CORP_PLANS, {
    variables: {
      organization: params.org,
    },
  });

  const [getPlanAndData, { loading, data, refetch }] = useLazyQuery(GET_PLAN_AND_DATA);

  const handleChangeFilters = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  const handleChangeViews = (e, value) => {
    resetLoading();
    setViews(value);
  };

  const handleSetOptions = (key) => (value) => {
    setOptions({ ...options, [key]: value });
  };

  const handleDownloadPdf = () => {
    const doc = new jsPDF();
    if (views === "To Dos" || views === "Issues") {
      let items, showCompleted;
      if (views === "To Dos") {
        items = data.todos;
        showCompleted = options.todoShowComplete;
      } else {
        items = data.issues;
        showCompleted = options.issueShowComplete;
      }

      const body = items
        .filter(({ done }) => showCompleted || !done)
        .map((item) => {
          const { value, createdAt, done, priority, user } = item;

          return [
            value,
            done ? "Complete" : "Incomplete",
            _.startCase(priority),
            formatAs(createdAt, "d MMM, yyyy"),
            `${_.get(user, "name.first", "")} ${_.get(user, "name.last", "")}`,
          ];
        });

      doc.autoTable({
        head: [[views, "Status", "Priority", "Date Created", "Accountable"]],
        body: [...body],
        margin: 3,
      });
    } else if (views === "Rocks") {
      const body = data.rocks.map((rock) => {
        const { value, index, status, users, successCriteria } = rock;
        const completedSC = successCriteria ? successCriteria.reduce((sum, val) => (val.done ? sum + 1 : sum), 0) : 0;
        return [
          value,
          index,
          `${completedSC} done / ${successCriteria ? successCriteria.length : 0} total`,
          status,
          `${_.get(users, "[0].name.first", "")} ${_.get(users, "[0].name.last", "")}`,
        ];
      });

      doc.autoTable({
        head: [[views, "Quarter", "Success Criteria", "Status", "Accountable"]],
        body: [...body],
        margin: 3,
      });
    } else if (views === "KPIs") {
      const targets = {
        gt: ">",
        lt: "<",
        eq: "=",
        gte: ">=",
        lte: "<=",
        btw: "[ ]",
      };

      const referenceMap = convertToWeeklyTargetsReferenceMap(data.weeklyTargets);
      const currentDate = new Date();
      const last14Weeks = Array(14)
        .fill(null)
        .map((item, idx) => {
          return subToDate(startOfWeek(currentDate), { weeks: 14 - idx });
        });

      const body = data.weeklyTargets.filter((wt) => !(!_.isNil(_.get(wt, "display")) && _.get(wt, "display") === false ) ).map((wt) => {
        const { value, accuracy, target, targetMin, targetMax, user, unit, measurables, enableFormula, formula, formulaScope } = wt;

        const wtValues = measurables.map(({ value, week }) => {
          const dateWeek = new Date(week);
          return `${value}(${formatAs(dateWeek, "MM/dd")}-${formatAs(endOfWeek(dateWeek), "MM/dd")}) |`;
        });

        let formulatedWtValues = [];
        if (enableFormula) {
          // calculate values for the last 14 weeks
          formulatedWtValues = last14Weeks.map((week) => {
            const formattedWeek = format(add(new Date(week), { hours: 12 }), "yyyyMMdd");
            const valueForWeek = parseWTValueUsingFormula({ formula, formulaScope, formattedWeek, referenceMap });

            if (!valueForWeek) {
              return null;
            } else {
              return `${valueForWeek}(${formatAs(week, "MM/dd")}-${formatAs(endOfWeek(week), "MM/d")}) |`;
            }
          });
          // remove null values
          formulatedWtValues = formulatedWtValues.filter((value) => !_.isNil(value));
        }

        const targetString =
          targets[accuracy] === "[ ]" ? `${targets[accuracy]} ${targetMin} - ${targetMax}` : `${targets[accuracy]} ${target}`;

        return [
          `${value} ${unit ? `(${unit})` : ""}`,
          targetString,
          `${_.get(user, "name.first", "")} ${_.get(user, "name.last", "")}`,
          enableFormula ? formulatedWtValues : wtValues,
        ];
      });

      doc.autoTable({
        head: [[views, "Target", "Accountable", "Measurables"]],
        body: [...body],
        margin: 3,
      });
    } else if (views === "1 Year Objectives" || views === "3 Year Objectives") {
      const items = views === "1 Year Objectives" ? _.get(data, "oneYearObjectives", []) : data.threeYearObjectives;

      const body = items.map((item) => {
        const { value, unit, status, users } = item;

        return [
          `${value} ${unit ? `(${unit})` : ""}`,
          _.startCase(status),
          `${_.get(users, "[0].name.first", "")} ${_.get(users, "[0].name.last", "")}`,
        ];
      });

      doc.autoTable({
        head: [[views, "Status", "Accountable"]],
        body: [...body],
        margin: 3,
      });
    } else if (views === "3 Year Metrics") {
      const referenceMap = convertToMetricsReferenceMap(data.threeYearMetrics);
      let yearHeaders = [];
      const body = data.threeYearMetrics.map((metric) => {
        const { value, measurables, unit, status, users, enableFormula, formula, formulaScope } = metric;

        yearHeaders = measurables.map(([year, projected, actual]) =>
          year.value.includes("Y") ? year.value.replace("Y", "Year ") : year.value
        );
        const yearValues = measurables.map(([year, projected, actual], idx) => {
          let formulaProjected;
          let formulaActual;
          if (enableFormula) {
            formulaProjected = parseMetricValueUsingFormula({ formula, formulaScope, index: idx, valueIndex: 1, referenceMap });
            formulaActual = parseMetricValueUsingFormula({ formula, formulaScope, index: idx, valueIndex: 2, referenceMap });
            return `${formulaProjected || "-"} | ${formulaActual || "-"}`;
          }

          return `${projected.value || "-"} | ${actual.value || "-"}`;
        });

        return [
          `${value} ${unit ? `(${unit})` : ""}`,
          ...yearValues,
          _.startCase(status),
          `${_.get(users, "[0].name.first", "")} ${_.get(users, "[0].name.last", "")}`,
        ];
      });

      doc.autoTable({
        head: [[views, ...yearHeaders, "Status", "Accountable"]],
        body: [...body],
        margin: 3,
      });
    }

    doc.save("ventrek report.pdf");
  };

  const parseYear = (date) => {
    const start = getYear(fiscalYearStart(date));
    const end = getYear(date);

    if (start === end) return start;
    return `${start}-${end}`;
  };

  useEffect(() => {
    if (threeYearCorpPlansData) {
      const { threeYearCorpPlans } = threeYearCorpPlansData;
      const currentThreeYearCorpPlan = getCurrentThreeYearPlan(threeYearCorpPlans, _.get(org, "fiscalYear"));
      getPlanAndData({
        variables: {
          organization: params.org,
          objectives: filters.objective,
          users: filters.owner,
          indexes: filters.quarter,
          statuses: filters.status,
          sharedPlanId: user.departmentFilter.sharedPlanId,
          planId: _.get(corpForSelectedYear, "id", null),
          threeYearPlanId: currentThreeYearCorpPlan.id,
          // todos
          page,
          rowsPerPage,
          searchTerm: debouncedSearchTerm,
          sortBy: sort.value,
          sortDir: sort.order,
          done: showCompleted === true ? null : false,
          //issues
          page2,
          rowsPerPage2,
          searchTerm2: debouncedSearchTerm2,
          sortBy2: sort2.value,
          sortDir2: sort2.order,
          done2: showCompleted2 === true ? null : false,
          includeFormulaReferences: true,
        },
      });
    }
  }, [
    threeYearCorpPlansData,
    user,
    filters,
    sort,
    sort2,
    org,
    params.org,
    corpForSelectedYear,
    page,
    rowsPerPage,
    debouncedSearchTerm,
    showCompleted,
    page2,
    rowsPerPage2,
    debouncedSearchTerm2,
    showCompleted2,
  ]);

  if (loading || threeYearCorpLoading) return <Loading />;

  if (!_.get(data, "plan")) return <NoOneYear />;
  const { fiscalYear, plansOrder } = data.organization;

  const threeYearCorpPlans = _.get(threeYearCorpPlansData, "threeYearCorpPlans");
  const currentThreeYearCorpPlan = getCurrentThreeYearPlan(threeYearCorpPlans, fiscalYear);

  const { theme } = data.plan;

  const { threeYearObjectives, rocks, todos, todosCount, issues, issuesCount, threeYearMetrics, weeklyTargets } = data;
  const objectives = _.get(data, "oneYearObjectives", []);
  const currentQuarter = getCurrentQuarter(corpForSelectedYear.year);
  const [start, end] = quarterDates(subToDate(addToDate(fiscalYear, { days: 1 }), {years: 1}), currentQuarter);
  const currentQuarterDates = { quarter:currentQuarter, start, end };

  return (
    <Container maxWidth={false}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <div className={styles.flexBetween}>
            <div className={styles.yearDisplay}>
              <Typography variant="h5">
                <span className={styles.label}>Theme: </span>
                {theme}
              </Typography>
              <Typography variant="subtitle1" className={styles.emphasis}>
                <span className={styles.label}>Year: </span>
                {parseYear(fiscalYear)}
              </Typography>
            </div>
            <div className={styles.filterButtons}>
              <ToggleButtonGroup value={views} onChange={handleChangeViews} className={styles.views} exclusive>
                {VIEW_MENU.map((view) => {
                  return (
                    <ToggleButton value={view} key={view}>
                      {view}
                    </ToggleButton>
                  );
                })}
              </ToggleButtonGroup>

              <FilterMenus
                filters={filters}
                handleChange={handleChangeFilters}
                users={_.get(data, "users", null)}
                objectives={_.sortBy(data.allObjectives, ["value"])}
              />
            </div>
          </div>
        </Grid>

        {views === "To Dos" && (
          <Grid item xs={12}>
            <Todos
              todos={todos}
              onChangeShowComplete={handleSetOptions("todoShowComplete")}
              // filterUsers={userIds}
              handleChangePage={handleChangePage}
              handleRowsPerPage={handleRowsPerPage}
              page={page}
              rowsPerPage={rowsPerPage}
              sort={sort}
              searchTerm={searchTerm}
              setSort={setSort}
              setSearchTerm={setSearchTerm}
              setShowCompleted={setShowCompleted}
              showCompleted={showCompleted}
              total={todosCount.total}
              totalCompleted={todosCount.totalCompleted}
              planId={corpForSelectedYear.id}
            />
          </Grid>
        )}

        {views === "Issues" && (
          <Grid item xs={12}>
            <Todos
              todos={issues}
              issues
              onChangeShowComplete={handleSetOptions("issueShowComplete")}
              // filterUsers={userIds}
              handleChangePage={handleChangePage2}
              handleRowsPerPage={handleRowsPerPage2}
              page={page2}
              rowsPerPage={rowsPerPage2}
              sort={sort2}
              searchTerm={searchTerm2}
              setSort={setSort2}
              setSearchTerm={setSearchTerm2}
              setShowCompleted={setShowCompleted2}
              showCompleted={showCompleted2}
              total={issuesCount.total}
              totalCompleted={issuesCount.totalCompleted}
              planId={corpForSelectedYear.id}
            />
          </Grid>
        )}
        {views === "Rocks" && (
          <Grid item xs={12}>
            <Rocks rocks={rocks} fiscalYear={fiscalYear} planId={corpForSelectedYear.id} allQuarters />
          </Grid>
        )}
        {views === "KPIs" && (
          <Grid item xs={12}>
            <WeeklyTargets
              weeklyTargets={weeklyTargets}
              fiscalYear={fiscalYear}
              planId={_.get(corpForSelectedYear, "id")}
              currentQuarterDates={currentQuarterDates}
              variables={{
                organization: params.org,
                users: filters.owner,
                sharedPlanId: user.departmentFilter.sharedPlanId,
                includeFormulaReferences: true
              }}
            />
          </Grid>
        )}
        {views === "1 Year Objectives" && (
          <Grid item xs={12}>
            <Objectives
              objectives={objectives}
              fiscalYear={fiscalYear}
              category="1 year"
              plansOrder={plansOrder}
              corpForSelectedYear={corpForSelectedYear}
              planId={_.get(corpForSelectedYear, "id")}
              currentQuarter={currentQuarter}
              variables={{
                organization: params.org,
                objectives: filters.objective,
                users: filters.owner,
                category: "1 year",
                statuses: filters.status,
                sharedPlanId: user.departmentFilter.sharedPlanId,
                planId: _.get(corpForSelectedYear, "id", null),
              }}
            />
          </Grid>
        )}
        {views === "3 Year Objectives" && (
          <Grid item xs={12}>
            <Objectives
              planId={_.get(currentThreeYearCorpPlan, "id")}
              objectives={threeYearObjectives}
              fiscalYear={fiscalYear}
              category="3 year"
              plansOrder={plansOrder}
              corpForSelectedYear={corpForSelectedYear}
              variables={{
                organization: params.org,
                category: "3 year",
                objectives: filters.objective,
                users: filters.owner,
                statuses: filters.status,
              }}
            />
          </Grid>
        )}

        {views === "3 Year Metrics" && (
          <Grid item xs={12}>
            <Metrics
              planId={_.get(currentThreeYearCorpPlan, "id")}
              metrics={threeYearMetrics}
              fiscalYear={fiscalYear}
              category="3 year"
              plansOrder={plansOrder}
              corpForSelectedYear={corpForSelectedYear}
              variables={{
                organization: params.org,
                category: "year",
                users: filters.owner,
                sharedPlanId: user.departmentFilter.sharedPlanId,
              }}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <div className={styles.center}>
            <Button onClick={handleDownloadPdf} color="primary" variant="contained" disabled={_.isEmpty(views)}>
              Download Report
            </Button>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Reports;

const NoOneYear = () => {
  return (
    <Container maxWidth={false}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h6">Please create a 1 year theme to continue</Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

const GET_PLAN_AND_DATA = gql`
  query Reports_GetPlanData(
    $organization: ID!
    $users: [ID!]
    $objectives: [ID!]
    $indexes: [Int!]
    $statuses: [String]
    $sharedPlanId: ID
    $planId: ID
    $threeYearPlanId: ID
    $page: Int
    $rowsPerPage: Int
    $searchTerm: String
    $sortBy: String
    $sortDir: String
    $done: Boolean
    $page2: Int
    $rowsPerPage2: Int
    $searchTerm2: String
    $sortBy2: String
    $sortDir2: String
    $done2: Boolean
    $includeFormulaReferences: Boolean
  ) {
    organization(id: $organization) {
      id
      fiscalYear
      plansOrder
    }

    plan(organization: $organization, category: "1 year", closed: false, sharedPlanId: $sharedPlanId) {
      id
      theme
    }

    threeYearObjectives: objectives(
      organization: $organization
      category: "3 year"
      objectives: $objectives
      sharedPlanId: $sharedPlanId
      users: $users
      statuses: $statuses
      corpPlan: $threeYearPlanId
    ) {
      ...ObjectiveFields
    }

    oneYearObjectives: objectives(
      organization: $organization
      corpPlan: $planId
      sharedPlanId: $sharedPlanId
      category: "1 year"
      objectives: $objectives
      users: $users
      statuses: $statuses
    ) {
      ...ObjectiveFields
    }

    threeYearMetrics: metrics(
      organization: $organization
      users: $users
      category: "year"
      sharedPlanId: $sharedPlanId
      corpPlan: $threeYearPlanId
    ) {
      ...MetricFields
    }

    allObjectives: objectives(organization: $organization) {
      id
      value
    }

    users(organization: $organization) {
      ...UserFields
    }

    rocks(
      organization: $organization
      users: $users
      objectives: $objectives
      oneYearCorpPlan: $planId
      indexes: $indexes
      statuses: $statuses
      sharedPlanId: $sharedPlanId
    ) {
      ...RockFields
    }

    todos(
      organization: $organization
      users: $users
      category: "todo"
      referenceIds: $objectives
      sharedPlanId: $sharedPlanId
      oneYearCorpPlan: $planId
      page: $page
      rowsPerPage: $rowsPerPage
      searchTerm: $searchTerm
      sortBy: $sortBy
      sortDir: $sortDir
      done: $done
    ) {
      ...TodoFields
    }

    todosCount: todosCount(
      organization: $organization
      users: $users
      category: "todo"
      referenceIds: $objectives
      sharedPlanId: $sharedPlanId
      searchTerm: $searchTerm
    ) {
      total
      totalCompleted
    }

    issues: todos(
      organization: $organization
      users: $users
      category: "issue"
      referenceIds: $objectives
      sharedPlanId: $sharedPlanId
      oneYearCorpPlan: $planId
      page: $page2
      rowsPerPage: $rowsPerPage2
      searchTerm: $searchTerm2
      sortBy: $sortBy2
      sortDir: $sortDir2
      done: $done2
    ) {
      ...TodoFields
    }

    issuesCount: todosCount(
      organization: $organization
      users: $users
      category: "issue"
      referenceIds: $objectives
      sharedPlanId: $sharedPlanId
      searchTerm: $searchTerm
    ) {
      total
      totalCompleted
    }

    weeklyTargets(organization: $organization, users: $users, sharedPlanId: $sharedPlanId, includeFormulaReferences: $includeFormulaReferences) {
      ...WeeklyTargetFields
    }
  }

  ${OBJECTIVE_FIELDS}
  ${METRIC_FIELDS}
  ${WEEKLY_TARGET_FIELDS}
  ${TODO_FIELDS}
  ${ROCK_FIELDS}
`;

const GET_THREE_YEAR_CORP_PLANS = gql`
  query ($organization: ID!) {
    threeYearCorpPlans: plans(organization: $organization, category: "3 year", departmentName: "Corporate", closed: false) {
      id
      targetDate
    }
  }
`;
