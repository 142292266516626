import React, { useState, useContext, useEffect } from "react";
import _ from "lodash";
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";

import { DialogContext } from "../../context/dialogContext";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { mdiDelete, mdiCalendarRemoveOutline, mdiDotsHorizontal, mdiPencilBox, mdiBullhorn, mdiAccountGroup } from "@mdi/js";
import Icon from "@mdi/react";
import { format, isToday } from "date-fns";
import { useHistory } from "react-router-dom";
import PlanPill from "../../components/PlanPill/PlanPill";
import styles from "./Meetings.module.scss";

const MeetingCard = ({ meeting, org, handleDelete, isAdmin, currentCorpPlan }) => {
  const { id, status, title, plan, startTime, sharedPlanId } = meeting;

  const { dialog, setDialog } = useContext(DialogContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [departmentPlan, setDepartmentPlan] = useState(null);

  const open = Boolean(anchorEl);
  const history = useHistory();


  const {
    data: planData,
    refetch: planDataRefetch,
    loading: planDataLoading,
  } = useQuery(GET_PLANS, {
    variables: { organization: org.id },
  });

  const getDateString = (date) => {
    const dateObj = new Date(parseInt(date));
    const dateIsToday = isToday(dateObj);
    const formattedDate = format(dateObj, "MMM dd, yyyy");
    const formattedTime = format(dateObj, "h:mm a");

    return `${dateIsToday ? "Today" : formattedDate} @ ${formattedTime}`;
  };

  useEffect(() => {
    if (planData) {
      if (sharedPlanId) {
        //doesn't matter what plan year, we are just using this to populate the pill with the department name
        const departmentPlan = _.find(planData.plans, { sharedPlanId });
        setDepartmentPlan(departmentPlan);
      }
      else {
        setDepartmentPlan(null);
      }
    }
  }, [planData, sharedPlanId]);

  const handleOpenMenu = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const handleJoinMeeting = (e) => {
    e.stopPropagation();
    history.push(`/${org.id}/meeting/${id}`);
  };

  const handleEditMeeting = (e) => {
    e.stopPropagation();
    setDialog({
      ...dialog,
      addMeetingDialog: { open: true, corpPlanId: _.get(currentCorpPlan, "id"), fiscalYear: org.fiscalYear, meeting },
    });
  };

  /*const handleDeleteMeeting = (e) => {
    e.stopPropagation();
    handleDelete();
  };
*/
  const handleAddHeadline = (e) => {
    e.stopPropagation();
    setDialog({
      ...dialog,
      addHeadlineDialog: { open: true, meeting: meeting},
    });
  };
  const handleAddPromptStepContent= (e) => {
    e.stopPropagation();
    setDialog({
      ...dialog,
      addStepContentDialog: { open: true, contentType: 'prompt', corpPlanId: _.get(currentCorpPlan, "id"), fiscalYear: org.fiscalYear, meeting },
    });
  };

  const statusColor = status === "finished" ? "#009b72" : status === "scheduled" ? "#2589bd" : "#ff7900";
  const dateString = getDateString(startTime);

  const hasHeadlineStep = _.get(meeting, "steps", []).some((step) =>
    _.get(step, "content", []).some((content) => content.type === 'headline') || step.value === 'headlines'
  );
  const hasCheckInStep = _.get(meeting, "steps", []).some((step) =>
    _.get(step, "content", []).some((content) => content.type === 'prompt') || step.value === 'check-in'
  );

  const isRecurring = meeting.frequency !== 0;

  return (
    <Card variant="outlined" className={styles.listCard}>
      <CardHeader
        title={
          <Typography variant="button" style={{ color: statusColor, fontWeight: "bold" }}>
            {status} <PlanPill plan={departmentPlan} />
          </Typography>
        }
        subheader={
          <div style={{ marginTop: 8 }}>
            <Typography variant="h6" style={{ fontWeight: "bold", textTransform: "uppercase" }}>
              {title}
            </Typography>
            <Typography style={{ color: "#a1a1a1" }}>{dateString}</Typography>
          </div>
        }
        action={
          <>
            {isAdmin && (
              <>
              <IconButton onClick={handleOpenMenu} id="card-icon">
                <Icon path={mdiDotsHorizontal} size={1} />
              </IconButton>
                <Menu anchorEl={anchorEl} open={open} onClose={handleCloseMenu}>
                  {status !== 'finished' && (
                    <div>
                      <MenuItem key={4} onClick={handleEditMeeting}>
                        <ListItemIcon>
                          <Icon path={mdiPencilBox} size={1} />
                        </ListItemIcon>
                        <ListItemText>Edit</ListItemText>
                      </MenuItem>
                      {hasHeadlineStep && (
                        <MenuItem key={5} onClick={handleAddHeadline}>
                          <ListItemIcon>
                            <Icon path={mdiBullhorn} size={1} />
                          </ListItemIcon>
                          <ListItemText>Add Headline to Meeting</ListItemText>
                        </MenuItem>
                      )}
                      {hasCheckInStep && (
                        <MenuItem key={6} onClick={handleAddPromptStepContent}>
                          <ListItemIcon>
                            <Icon path={mdiAccountGroup} size={1} />
                          </ListItemIcon>
                          <ListItemText>Add Ice-breakers to Meeting</ListItemText>
                        </MenuItem>
                      )}
                    </div>
                 )}
                <MenuItem onClick={handleDelete(meeting, status !== 'finished')} key={2}>
                  <ListItemIcon>
                    <Icon path={mdiCalendarRemoveOutline} size={1} />
                  </ListItemIcon>
                  <ListItemText>Delete this Meeting</ListItemText>
                </MenuItem>                
                {(isRecurring && status !== 'finished') && (
                  <MenuItem onClick={handleDelete(meeting, false)} key={3}>
                    <ListItemIcon>
                      <Icon path={mdiDelete} size={1} />
                    </ListItemIcon>
                    <ListItemText>Delete this & all following Meetings</ListItemText>
                  </MenuItem>          
                )}
              </Menu>
              </>
            )}
          </>
        }
      />
      <CardContent style={{ borderTop: "1px solid rgb(209 213 219)" }}>
        <div style={{ display: "flex", justifyContent: "flex-end", gap: 8 }}>
          {status === "finished" ? (
            <Button className={styles.menuButton} variant={`outlined`} onClick={handleJoinMeeting}>
              View Minutes
            </Button>            
          ) : (
            <Button className={styles.menuButtonColor} color="primary" variant={`contained`} onClick={handleJoinMeeting}>
              Join
            </Button>            
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default MeetingCard;



const GET_PLANS = gql`
  query MeetingCard_GetPlans($organization: ID!) {
    plans(organization: $organization, category: "1 year") {
      id
      color
      departmentName
      sharedPlanId
      year
    }
  }
`;