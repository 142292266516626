import React from 'react';
import _ from 'lodash';
import styles from "./AttendeesStatus.module.scss";

import { Typography, Box } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';

const AttendeesStatus = ({ invited, attendees }) => {

  const waitingFor = invited.filter(invitedUser =>
    !attendees.some(attendee => attendee.id === invitedUser.id)
  );

  return (
    <Box>
      <Typography variant="h6">Waiting For</Typography>
      <Box display="flex" flexDirection="row" flexWrap="wrap" marginBottom={"20px"}>
        {(waitingFor && !_.isEmpty(waitingFor)) ? ( waitingFor.map((user, i) => (
          <Box
          key={`waiting-${i}`}
          className={styles.userContainer}
          display="flex"
          flexDirection="column"
          alignItems="center"
          mr={2}
          mb={2}
        >
          <Avatar className={styles.avatar} src={user.profilePicture}>
            {user.name.first[0] || ""}
          </Avatar>
          <Typography
            variant="body2"
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 2, // Limit to 2 lines
              maxWidth: "100%", // Adjust as per your layout
              textAlign: "center",
            }}
            title={`${user.name.first} ${user.name.last}`} // Tooltip for full name
          >
            {`${user.name.first} ${user.name.last}`}
          </Typography>
        </Box>
        ))) : (
          <Typography
            variant="body2"
            style={{ marginTop: 4 }}
          >
            {`No one! Everyone has arrived.`}
          </Typography>          
        )}


      </Box>
          
      <Typography variant="h6">Present</Typography>
      <Box display="flex" flexDirection="row" flexWrap="wrap">
        {attendees && attendees.map((user, i) => (
          <Box key={`attendee-${i}`} className={styles.userContainer} display="flex" flexDirection="column" alignItems="center" mr={2} mb={2}>
            <Avatar className={styles.avatar} src={user.profilePicture}>
              {user.name.first[0] || ""}
            </Avatar>
            <Typography variant="body2" style={{ marginTop: 4, textAlign: 'center' }}>
              {user.name.first} {user.name.last}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default AttendeesStatus;