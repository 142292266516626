import React from "react";
import _ from "lodash";
import { FormControl, FormHelperText, InputLabel, Select, MenuItem } from "@material-ui/core";
import { setYear, year as yearUtil, fullDate, fiscalYearStart } from "../../utils/dates";

const SelectPlan = ({ plans, name, value = null, handleChange, helperText, error, showAll, multiple, disabled = false, fiscalYear }) => {

  const chooseCurrentYearValue = 'Current Plan Year';
  let currentValue = value || chooseCurrentYearValue;

  const handleSelectionChange = (event) => {
    const selectedValue = event.target.value === chooseCurrentYearValue ? null : event.target.value;
    const syntheticEvent = {
      target: {
        name: event.target.name,
        value: selectedValue,
      },
    };
    handleChange(syntheticEvent);
  }

  return (
    <FormControl fullWidth variant="outlined" margin="normal" error={error} data-test='formcontrol-select-corporate-year'>
      <InputLabel>Select Plan Year</InputLabel>
      <Select
        name={name}
        value={currentValue || ''}
        multiple={multiple}
        onChange={handleSelectionChange}
        label="Select Plan Year"
        error={error}
        disabled={disabled}
        data-test='select-plan-year'    
      >
        {showAll && <MenuItem value={chooseCurrentYearValue} data-test='select-item-plan-year'>Use Active Fiscal Year's Strategic Plan</MenuItem>}
        {fiscalYear && !_.isEmpty(plans) && _.sortBy(plans, (p) => parseInt(-p.year)).map((plan) => (
          <MenuItem
            key={plan.id}
            value={plan.id}
          >
            {fullDate(fiscalYearStart(setYear(fiscalYear, yearUtil(plan.year))))} - {fullDate(setYear(fiscalYear, yearUtil(plan.year)))}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText error={error}>{helperText}</FormHelperText>
    </FormControl>

  );

};

export default SelectPlan;
