import _ from "lodash";
import React, { createContext, useState, useCallback, useMemo} from "react";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";

export const MeetingContext = createContext();

export const MeetingContextProvider = ({ children }) => {
  const [data, setData] = useState({ meeting: null, filterOnSelected: false, corpPlanIdForMeeting: null, departmentPlanIdForMeeting: null });

  const [unstableUpdateCurrentSession] = useMutation(UPDATE_CURRENT_SESSION, { fetchPolicy: "no-cache" });  

  const updateCurrentSession = useCallback(
    (variables) => {
      return unstableUpdateCurrentSession(variables);
    },
    [unstableUpdateCurrentSession]
  );


  const unstableActiveMeeting = (updatedFields) => {
    setData((prev) => ({
      ...prev,
      ...updatedFields
    }));
  };

  const activeMeeting = useCallback(
    (updatedFields) => {
      return unstableActiveMeeting(updatedFields);
    },
    []
  );

  // Memoize the context value so it doesn't change on every render.
  const contextValue = useMemo(() => ({ data, activeMeeting, updateCurrentSession}), [data, activeMeeting, updateCurrentSession]);


  return (
    <MeetingContext.Provider
      value={contextValue}
    >
      {children}
    </MeetingContext.Provider>
  );
};


const UPDATE_CURRENT_SESSION = gql`
  mutation MeetingContext_UpdateCurrSession($id: ID!, $currentSession: InputCurrentSession!) {
    updateCurrentSession(id: $id, currentSession: $currentSession)
  }
`;
