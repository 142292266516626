import React, { useState, useContext, useEffect } from "react";
import styles from "./Headlines.module.scss";
import _ from "lodash";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import gql from "graphql-tag";
import {
  Container,
  Grid,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  Button,
  Card,
  CardContent,
  CardActions,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Hidden,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";
import { FetchContext } from "../../context/fetchContext";
import { SnackbarContext } from "../../context/snackbarContext";
import { UserContext } from "../../context/userContext";
import CardTitle from "../../components/CardTitle/CardTitle";
import { useForm } from "../../hooks";
import Loading from "../../components/Loading/Loading";
import Headline from "./Headline";
import EditDialog from "./EditDialog";
import useTableFilters from "../../hooks/useTableFilters";
import { addToDate } from "../../utils/dates";

import { USER_FIELDS } from "../../utils/fragments";
import { HEADLINE_CATEGORIES } from "../../utils/const";

const Headlines = ({ params, org }) => {
  // const url = `${process.env.REACT_APP_URL}/signup?org=${params.org}`;
  const { fetch, requestFetch } = useContext(FetchContext);
  const { snack } = useContext(SnackbarContext);
  const { user } = useContext(UserContext);

  // dialog
  const [open, setOpen] = useState(false);
  const [selectedHeadline, setSelectedHeadline] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  // pagination
  const { page, rowsPerPage, searchTerm, debouncedSearchTerm, handleChangePage, handleRowsPerPage, setSearchTerm } = useTableFilters({
    initialValue: {},
  });
  const [getHeadlines, { data, loading, refetch }] = useLazyQuery(GET_HEADLINES);
  const [createHeadline] = useMutation(CREATE_HEADLINE);
  const [updateHeadline] = useMutation(UPDATE_HEADLINE);
  const [deleteHeadline] = useMutation(DELETE_HEADLINE);
  const {
    data: plansData,
    loading: plansLoading,
    refetch: plansRefetch,
  } = useQuery(GET_DEPARTMENTS, {
    variables: { organization: params.org },
  });


  const defaultType = Object.values(HEADLINE_CATEGORIES).find(category => category.default)?.value || HEADLINE_CATEGORIES['general'].value;

  const initForm = {
    organization: params.org,
    category: defaultType,
    value: null,
    summary: "",
    author: user.user.id,
    displayUntilDate: addToDate(new Date(), { days: 7 }),
    sharedPlanIds: [],
  };
  const initErrorForm = {
    value: ["required"],
    category: ["required"],
  };

  const { form, formErrors, handleChange, handleChangeDate, resetForm, validateForm } = useForm({
    initForm,
    initErrorForm,
  });

  const handleDialog =
    (open, headline = null) =>
      () => {
        setSelectedHeadline(headline);
        setOpen(open);
      };

  const handleCreateSubmit = async (e) => {

    if (!validateForm()) return;
    e.preventDefault();

    const { organization, category, value, summary, author, displayUntilDate, sharedPlanIds } = form;
    const startOfDayDisplayUntilDate = new Date(displayUntilDate);
    startOfDayDisplayUntilDate.setHours(0, 0, 0, 0);

    const { data } = await createHeadline({
      variables: { organization, category, value, summary, author, displayUntilDate: startOfDayDisplayUntilDate, sharedPlanIds },
    });

    if (data.createHeadline) {
      snack("Created new headline");
      requestFetch();
      resetForm();
      setOpen(false);
    } else {
      snack("Could not create the new headline", "error");
    }

  };

  const handleEditSubmit = async (e) => {
    if (!validateForm()) return;
    e.preventDefault();

    const { id, organization, category, value, summary, author, displayUntilDate, sharedPlanIds } = form;
    const startOfDayDisplayUntilDate = new Date(displayUntilDate);
    startOfDayDisplayUntilDate.setHours(0, 0, 0, 0);

    const { data } = await updateHeadline({
      variables: { id, organization, category, value, summary, author, displayUntilDate: startOfDayDisplayUntilDate, sharedPlanIds },
    });

    if (data.updateHeadline) {
      snack(`Updated Headline: ${value}`);
      requestFetch();
      setOpen(false);
    }
  };


  const handleCreateCancel = (form) => {
    setSelectedHeadline(null);
    resetForm();
    setOpen(false);
  };

  const handleDelete = async (headline) => {
    const { id, value } = headline;
    const ok = await deleteHeadline({ variables: { id } });

    if (ok) {
      snack(`Deleted Headline: ${value} ${value}`);
      requestFetch();
      setDeleteDialogOpen(false);
      setSelectedHeadline(null);
    }
  };

  useEffect(() => {
    getHeadlines({ variables: { organization: params.org, category: null, offset: page, size: rowsPerPage, search: debouncedSearchTerm } });
  }, [page, rowsPerPage, debouncedSearchTerm]);

  useEffect(() => {
    if (refetch) {
      refetch();
    }
  }, [fetch]);

  if (loading || !data || plansLoading) return <Loading />;
  const headlines = _.get(data, "headlines", []);
  const count = !_.isNil(headlines) ? headlines.length : 0;

  return (
    <>
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <Hidden xsDown>
            <Grid item xs={12}>
              <Typography variant="h6" className={styles.label}>
                Manage Headlines
              </Typography>
            </Grid>
          </Hidden>
          <Grid item xs={12}>
            <Card className={styles.card}>
              <CardTitle vertical color="teal">
                <Typography variant="h5" className={styles.title}>
                  Headlines
                </Typography>
              </CardTitle>
              <CardActions className={styles.cardActions}>
                <TextField
                  variant="outlined"
                  label="Search"
                  name="search"
                  value={searchTerm || ""}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  // onKeyPress={(e) => {
                  //   if (e.key === "Enter") handleSearch();
                  // }}
                  className={styles.left}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton disabled={true}>
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <Button
                  startIcon={<AddIcon />}
                  className={styles.leftIcon}
                  onClick={handleDialog(true)}
                  variant="contained"
                  color="primary"
                  data-test='button-new-headline'
                >
                  New Headline
                </Button>
              </CardActions>
              <CardContent className={styles.cardContent}>
                <Table size="small" className={styles.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell className={styles.firstCell}>Category</TableCell>
                      <TableCell>Headline</TableCell>
                      <TableCell>Organization</TableCell>
                      <TableCell>Departments</TableCell>
                      <TableCell>Summary</TableCell>
                      <TableCell>Author</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {headlines &&
                      headlines.map((headline) => {
                        return (
                          <Headline
                            key={headline.id}
                            headline={headline}
                            plansData={plansData}
                            snack={snack}
                            handleEdit={handleDialog(true, headline)}
                            handleDelete={() => {
                              setDeleteDialogOpen(true);
                              setSelectedHeadline(headline);
                            }}
                          />
                        );
                      })}
                  </TableBody>
                </Table>
                <TablePagination
                  page={page}
                  rowsPerPage={rowsPerPage}
                  count={count}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleRowsPerPage}
                  component="div"
                />
              </CardContent>
            </Card>
          </Grid>

        </Grid>
      </Container>

      <EditDialog
        open={open}
        handleClose={handleDialog(false)}
        headline={selectedHeadline}
        plansData={plansData}
        form={form}
        resetForm={resetForm}
        formErrors={formErrors}
        handleChange={handleChange}
        handleChangeDate={handleChangeDate}
        handleSubmit={selectedHeadline ? handleEditSubmit : handleCreateSubmit}
        handleCreateCancel={handleCreateCancel}
      />

      {/* Delete Dialog */}
      {selectedHeadline && (
        <Dialog
          open={deleteDialogOpen}
          onClose={(event, reason) => {
            if (reason !== "backdropClick") {
              setDeleteDialogOpen(false);
            }
          }}
        >
          <DialogTitle>Delete Headline</DialogTitle>
          <DialogContent>
            Are you sure you want to delete they following headline?<br /><ul><li>{selectedHeadline.value}</li></ul>?
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              onClick={() => {
                setDeleteDialogOpen(false);
                setSelectedHeadline(null);
              }}
            >
              Cancel
            </Button>
            <Button color="primary" onClick={() => handleDelete(selectedHeadline)} variant="contained">
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default Headlines;

const GET_HEADLINES = gql`
  ${USER_FIELDS}
  query Headlines_GetHeadlines($organization: ID) {
    headlines(organization: $organization) {
      id
      organization
      category
      value
      summary
      author {
        ...UserFields
      }
      displayUntilDate
      sharedPlanIds
    } 
  }
`;

const CREATE_HEADLINE = gql`
  mutation Headlines_CreateHeadline(
    $organization: ID
    $category: String!
    $value: String!
    $summary: String
    $author: ID
    $displayUntilDate: String!
    $sharedPlanIds: [ID]
  ) {
    createHeadline(
      organization: $organization
      category: $category
      value: $value
      summary: $summary
      author: $author
      displayUntilDate: $displayUntilDate
      sharedPlanIds: $sharedPlanIds
    ) {
      id
      organization
      category
      value
      summary
      author {
        id
        name {
          first
          last
        }
      }
      displayUntilDate
      sharedPlanIds
    }
  }
`;

const UPDATE_HEADLINE = gql`
  mutation (
    $id: ID!
    $organization: ID
    $category: String
    $value: String
    $summary: String
    $displayUntilDate: String
    $sharedPlanIds: [ID]
  ) {
    updateHeadline(
      id: $id
      organization: $organization
      category: $category
      value: $value
      summary: $summary
      displayUntilDate: $displayUntilDate
      sharedPlanIds: $sharedPlanIds
    ) {
      id
      organization
      category
      value
      summary
      author {
        id
        name {
          first
          last
        }
      }
      displayUntilDate
      sharedPlanIds
    }
  }
`;

const DELETE_HEADLINE = gql`
  mutation ($id: ID!) {
    deleteHeadline(id: $id)
  }
`;

const GET_DEPARTMENTS = gql`
  query Headlines_GetDepts($organization: ID!) {
    plans(organization: $organization, category: "1 year", closed: false) {
      id
      departmentName
      sharedPlanId
    }
  }
`;