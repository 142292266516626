import React, { useMemo, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import _ from "lodash";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Typography,
  Select,
  MenuItem,
  Switch,
  FormControl,
  InputLabel,
  Avatar,
} from "@material-ui/core";

import { UserContext } from "../../context/userContext";
import { SYSTEMPROMPT_CATEGORIES, SYSTEMPROMPT_TYPES } from "../../utils/const";

const EditDialog = ({
  open,
  handleClose,
  systemprompt,
  form,
  resetForm,
  formErrors,
  handleChange,
  handleSubmit,
  handleCreateCancel,
  canAdmin = false,
}) => {
  const { user: currentUser } = useContext(UserContext);

  useEffect(() => {
    if (systemprompt) {
      const { id, organization, category, value, summary, author, alias, type } = systemprompt;
      let editForm = {
        id,
        organization: organization?.id,
        category,
        value,
        summary,
        author,
        alias,
        type,
      };
      resetForm(editForm);
    }
  }, [systemprompt]);

  return (
    <>
      <Dialog
        open={open}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        onExited={() => resetForm()}
      >
        <DialogTitle>{systemprompt ? "Edit" : "Create"} System Prompt</DialogTitle>
        <DialogContent>
          <TextField
            label="Question or Prompt"
            name="value"
            fullWidth
            variant="outlined"
            margin="normal"
            value={form.value || ""}
            onChange={handleChange}
            helperText={formErrors.value}
            error={Boolean(formErrors.value)}
          />
          <TextField
            label="Description or Instructions (Optional)"
            name="summary"
            fullWidth
            variant="outlined"
            margin="normal"
            value={form.summary || ""}
            onChange={handleChange}
            helperText={formErrors.summary}
            error={Boolean(formErrors.summary)}
          />
          <TextField
            label="Alias (Optional)"
            name="alias"
            fullWidth
            variant="outlined"
            margin="normal"
            value={form.alias || ""}
            onChange={handleChange}
            helperText={formErrors.alias}
            error={Boolean(formErrors.alias)}
          />
          {canAdmin && (       
            <>   
              <FormControl fullWidth margin="normal">
                <InputLabel variant="outlined">Category</InputLabel>
                <Select
                  variant="outlined"
                  label="Category"
                  name="category"
                  value={form.category}
                  onChange={handleChange}
                >
                {Object.values(SYSTEMPROMPT_CATEGORIES).map((category) => (
                  <MenuItem key={category.value} value={category.value}>
                    {category.name}
                  </MenuItem>
                ))}  
                </Select>
              </FormControl>           
              <FormControl fullWidth margin="normal">
                <InputLabel variant="outlined">Type</InputLabel>
                <Select
                  variant="outlined"
                  label="Type"
                  name="type"
                  value={form.type}
                  onChange={handleChange}
                >
                {Object.values(SYSTEMPROMPT_TYPES).map((type) => (
                  <MenuItem key={type.value} value={type.value}>
                    {type.name}
                  </MenuItem>
                ))}  
                </Select>
              </FormControl>
            </>
          )}                     
        </DialogContent>
        <DialogActions>
          <Button onClick={systemprompt ? handleClose : () => handleCreateCancel(form)} variant="outlined">
            Cancel
          </Button>
          <Button color="primary" onClick={handleSubmit} variant="contained" data-test='cud-button-save'>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditDialog;
