import React, { useState } from "react";
import _ from "lodash";
import Loading from "../Loading/Loading";
import StepContentItemEditorForm from "./StepContentItemEditorForm"

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";



const ContentItemDialog = ( { contentItem, lockContentTypes, stepDescription, open, onClose, onSubmit, org, planId, sharedPlanId, multiple = true }) => {
  const [loading, setLoading] = useState(false);
  const [itemForm, setItemForm] = useState({isValid: false, form: null});

  const handleLoadingChange = (loadingState) => {
    setLoading(loadingState);
  };

  const handleItemFormChange = (itemForm) => {
    setItemForm((prevState) => ({
      ...prevState,
      ...itemForm,
    }));
  };


  const handleSubmit = () => {
    if (itemForm.isValid) {
      onSubmit(itemForm.form);
    }
  };

  const type = _.get(contentItem, "type", _.get(lockContentTypes,0,"prompt"));

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          onClose();
        }
      }}
      fullWidth
    >
      <DialogTitle>{_.isEmpty(type) ? "Add" : "Edit"} {`${stepDescription}`} Step Content</DialogTitle>
      <DialogContent>
        <StepContentItemEditorForm
          contentItem={contentItem}
          lockContentTypes={lockContentTypes}
          stepDescription={stepDescription}
          org={org}
          planId={planId}
          sharedPlanId={sharedPlanId}
          multiple={multiple}
          onLoadingChange={handleLoadingChange}
          onFormChange={handleItemFormChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button color="primary" onClick={handleSubmit}>
          {loading ? <Loading size={24} color="primary" /> : "Done"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ContentItemDialog;

