import React, { createContext, useState } from "react";

export const DialogContext = createContext();

export const DialogContextProvider = ({ children }) => {
  const [dialog, setDialog] = useState({
    addOrgDialog: false,
    addPlanDialog: false,
    addObjectiveDialog: { open: false },
    addMetricDialog: { open: false },
    addRockDialog: { open: false, quarter: 1, value: null, objective: [], duplicate: null },
    addSuccessCriteriaDialog: false,
    addHeadlineDialog: { open: false },
    addStepContentDialog: { open: false },
    addToMeetingDialog: { open: false },
    addTodoDialog: { open: false, referenceId: null, referenceModel: null, category: null, value: null, user: null },
    addWeeklyTargetDialog: { open: false },
    addMeetingDialog: { open: false },
  });
  return <DialogContext.Provider value={{ dialog, setDialog }}>{children}</DialogContext.Provider>;
};
