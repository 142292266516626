import React, { useEffect, useState } from "react";
import styles from "./AddMeetingDialog.module.scss";
import _ from "lodash";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import useForm from "../../hooks/useForm";
import {
  styled,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  FormControlLabel,
  FormGroup,
  Switch,
  InputLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  useMediaQuery,
  Stepper,
  Step,
  StepLabel,
  Chip,
  Avatar,
  Checkbox,
  StepButton,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import CloseIcon from "@material-ui/icons/Close";
import { TimePicker, DatePicker } from "@material-ui/pickers";
import Loading from "../../components/Loading/Loading";
import { getDay, mergeDateAndTime } from "../../utils/dates";

import useCorpPlan from "../../hooks/useCorpPlan";
import SelectUsers from "../SelectUsers/SelectUsers";
import SelectDepartment from "../SelectDepartment/SelectDepartment";
import SelectPlan from "../SelectPlan/SelectPlan";
import SelectTimezone from "../SelectTimezone/SelectTimezone";
import MeetingStepsList from "./MeetingStepsList";
import { utcToZonedTime } from "date-fns-tz";
import { MEETING_FIELDS } from "../../utils/fragments";
import { getMeetingStepsFromType } from "../../utils/misc";

const StyledChip = styled(Chip)({
  color: "white",
  backgroundColor: "#7e57c2",
  "& .MuiChip-deleteIcon": {
    color: "white",
  },
});

const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const initErrorForm = {
  title: ["required"],
  sharedPlanId: ["required"],
};

function getDialogSteps() {
  return ["General", "Agenda", "Scheduling"];
}

const AddMeetingDialog = ({ dialog, setDialog, user, initialStep = "General", requestFetch, fetch, params, snack }) => {
  const newDate = getDefaultMeetingStartDate();
  const initForm = {
    id: null,
    title: "",
    startDate: newDate,
    startTime: newDate,
    timezone: userTimezone,
    frequency: 0,
    type: "hem",
    steps: getMeetingStepsFromType("hem"),
    plan: "",
    sharedPlanId: "",
    users: [],
  };

  // Initialize state with the index of initialStep
  const initialStepIndex = getDialogSteps().indexOf(initialStep);
  const [activeStep, setActiveStep] = useState(initialStepIndex !== -1 ? initialStepIndex : 0);

  const [allowAlterFrequency, setAllowAlterFrequency] = useState(true);

  const [usersById, setUsersById] = useState();

  const [createMeeting, { loading: createLoading }] = useMutation(CREATE_MEETING, {
    // update(cache, { data: { createMeeting } }) {
    //   cache.modify({
    //     fields: {
    //       meetings(existingMeetings = []) {
    //         const newMeetingRef = cache.writeFragment({
    //           data: createMeeting,
    //           fragment: MEETING_FIELDS,
    //         });
    //         return [...existingMeetings, newMeetingRef];
    //       },
    //     },
    //   });
    // },
  });
  const [updateMeeting, { loading: updateLoading }] = useMutation(UPDATE_MEETING, {
    refetchQueries: ["Meetings_GetMeetings"], // even when returning id of updated meeting, meetings page does not see changes unless refetch is made on query
  });

  const {
    loading: oneYear_corpPlansOrgLoading,
    data: corpPlansOrgData,
    refetch: corpPlansOrgRefetch,
  } = useQuery(GET_CORP_ONE_YEAR_PLANS_ORG, {
    variables: {
      organization: params.org,
    },
  });
  
  const [isInitializing, setIsInitializing] = useState(true);

  const [getUsers, { data, loading, refetch }] = useLazyQuery(GET_USERS_AND_DEPARTMENTS);

  const fiscalYear = _.get(dialog, "addMeetingDialog.fiscalYear");
  const corpPlans = _.get(corpPlansOrgData, "corporatePlans", []);


  const { form, formErrors, handleChange, handleChangeDate, handleChangeManual, resetForm, validateForm } = useForm({
    initForm: initForm,
    initErrorForm,
  });

  const fs = useMediaQuery("(max-width: 600px)");

  const dialogSteps = getDialogSteps();

  const formatExistingMeeting = (meeting = {}) => {
    if (_.isEmpty(meeting)) return meeting;

    let meetingCopy = _.cloneDeep(meeting);

    const startTime = _.get(meetingCopy, "startTime", "");
    const timezone = _.get(meetingCopy, "timezone", userTimezone);

    const startDateTime = _.isEmpty(startTime) ? newDate : new Date(parseInt(startTime));
    const zonedTime = utcToZonedTime(startDateTime, timezone);

    _.set(meetingCopy, "startTime", zonedTime);
    _.set(meetingCopy, "startDate", zonedTime);

    const users = _.get(meetingCopy, "users", []);
    _.set(
      meetingCopy,
      "users",
      users.map((user) => user.id)
    );

    const plan = _.get(meetingCopy, "plan", {});
    _.set(meetingCopy, "plan", _.get(plan, "id"));

    return meetingCopy;
  };

  const validateStep = (step) => {
    let proceed = true;
    switch (activeStep) {
      case 0:
        proceed = validateForm(["title"]) & validateForm(["sharedPlanId"]);
        break;
      default:
        proceed = true;
        break;
    }    

    return proceed;
  }

  const handleNext = () => () => {
    if (validateStep(activeStep)) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleStep = (step) => () => {
    if (validateStep(activeStep)) {    
      setActiveStep(step);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleClose = () => {
    resetForm(initForm);
    setIsInitializing(true);
    setActiveStep(0);
    setDialog({ ...dialog, addMeetingDialog: { open: false } });
  };

  const handleSubmit = () => async () => {
    if (!validateForm()) return;

    const { id, title, frequency, startDate, startTime, timezone, type, steps, plan, users, sharedPlanId } = form;
    const mergedDateTime = mergeDateAndTime(startDate, startTime, timezone);
    const isCreate = _.isNil(id);
    const sanitizedSteps = steps.map((step) => _.omit(step, ["dragId"]));

    let ok;
    if (isCreate) {
      ok = await createMeeting({
        variables: {
          organization: params.org,
          owner: user.user.id,
          title,
          frequency,
          startTime: mergedDateTime,
          timezone,
          type,
          steps: sanitizedSteps.map((step) => {
            step.content = (step.content || []).map((contentItem) => {
              contentItem.referenceIds = (contentItem.referenceIds || []).map((refId) => _.get(refId, "id", _.get(refId, "_id")));
              return contentItem;
            });
            return step;
          }),
          users,
          plan: plan === "" ? null : plan,
          sharedPlanId: sharedPlanId === "" ? null : sharedPlanId,
        },
      });
    } else {
      const startTimeRecurring = allowAlterFrequency ? mergedDateTime : null;
      const timezoneRecurring = allowAlterFrequency ? timezone : null;
      const updatedFrequency = allowAlterFrequency ? frequency : null;
      ok = await updateMeeting({
        variables: {
          id,
          title,
          frequency: updatedFrequency,
          startTime: mergedDateTime,
          timezone,
          startTimeRecurring: startTimeRecurring,
          timezoneRecurring: timezoneRecurring,          
          type,
          steps: steps.map((step) => {
            step.content = (step.content || []).map((contentItem) => {
              contentItem.referenceIds = (contentItem.referenceIds || []).map((refId) => _.get(refId, "id", _.get(refId, "_id")));
              return contentItem;
            });
            return step;
          }),
          users,
          plan: plan === "" ? null : plan,
          sharedPlanId: sharedPlanId === "" ? null : sharedPlanId,
        },
      });
    }

    if (_.get(ok, `data.${isCreate ? "createMeeting" : "updateMeeting"}`, false)) {
      snack(`${isCreate ? "Created" : "Updated"} "${title}" meeting`);
      // if (!isCreate) requestFetch();
      resetForm();
      handleClose();
    }
  };

  const handleAddAttendee = (event, users) => {
    handleChangeManual({ name: "users", value: users });
  };

  const handleToggleAllowAlterFrequency = () => {
    setAllowAlterFrequency(!allowAlterFrequency);
  }

  useEffect(() => {
    if (_.get(dialog, "addMeetingDialog.open", false)) {
      const existingMeeting = _.get(dialog, "addMeetingDialog.meeting", {});
      const meeting = _.isEmpty(existingMeeting)
        ? initForm
        : formatExistingMeeting(
            _.pick(existingMeeting, ["id", "title", "startDate", "startTime", "timezone", "frequency", "type", "steps", "plan", "users", "sharedPlanId"])
          );
      
      if (meeting?.id && meeting?.frequency > 0) {
        setAllowAlterFrequency(false);
      }
      else {
        setAllowAlterFrequency(true);
      }

      resetForm(meeting);
      setIsInitializing(false);
    }
  }, [dialog.addMeetingDialog]);
  
  useEffect(() => {
    if (!_.isNil(usersById)) {
      const selectedUsersCopy = _.cloneDeep(form.users);

      handleChangeManual({
        name: "users",
        value: selectedUsersCopy.filter((userId) => {
          const user = usersById[userId];
          return !(form.sharedPlanId) || _.some(user.plan, ["sharedPlanId", form.sharedPlanId]);          
        }),
      });
    }
  }, [usersById, form.sharedPlanId]);

  useEffect(() => {
    const filterWithCorpPlan = form.plan ? form.plan : _.get(dialog, "addMeetingDialog.corpPlanId");
    getUsers({ variables: { organization: params.org, oneYearCorpPlan: filterWithCorpPlan } });
    //if (!isInitializing) {
      //handleChangeManual({ name: "sharedPlanId", value: null });
    //}
  }, [form.plan, dialog]);

  /*
  useEffect(() => {

    //clear the user selectin list if the department get's changed
    if (!isInitializing) {
      handleChangeManual({ name: "users", value: [] });
    }

  }, [form.sharedPlanId]);  
*/

  useEffect(() => {
    if (data) {
      const users = _.get(data, "users", []);
      setUsersById(_.keyBy(users, "id"));

      if (form.sharedPlanId && !_.some(_.get(data, "plans", []), ["sharedPlanId", form.sharedPlanId])) {
        handleChangeManual({ name: "sharedPlanId", value: null });
      } 
    }
  }, [data]);

  const getAvatar = (user) => {
    const { name, profilePicture } = user;
    return (
      <Avatar src={profilePicture} className={styles.avatar}>
        {_.get(name, ["first", "0"])}
        {_.get(name, ["last", "0"])}
      </Avatar>
    );
  };

  const getStepContent = (step) => {
    const showAllowAlterFrequencyToggle = !_.isNil(form?.id) && form?.frequency > 0;

    switch (step) {
      case 0:
        return (
          <>
            <TextField
              autoFocus
              label="Name"
              name="title"
              fullWidth
              variant="outlined"
              margin="normal"
              value={form.title || ""}
              onChange={handleChange}
              helperText={formErrors.title}
              error={Boolean(formErrors.title)}
            />
            <SelectPlan
              plans={corpPlans}
              name="plan"
              handleChange={handleChange}
              value={form.plan}
              helperText={formErrors.plan}
              error={Boolean(formErrors.plan)}
              fiscalYear={fiscalYear}
              showAll
            />           
            <SelectDepartment
              plans={_.get(data, "plans")}
              name="sharedPlanId"
              handleChange={handleChange}
              value={form.sharedPlanId}
              helperText={formErrors.sharedPlanId}
              error={Boolean(formErrors.sharedPlanId)}
              selectType="sharedPlanId"
              showAll={false}
            />
            <Autocomplete
              fullWidth
              multiple
              disableCloseOnSelect
              filterSelectedOptions
              name="users"
              onChange={handleAddAttendee}
              value={form.users}
              style={{ marginTop: 16 }}
              options={_.get(data, "users", []).map((user) => user.id)}
              filterOptions={(userIds) =>
                userIds.filter((userId) => {
                  const user = usersById[userId];
                  return !(form.sharedPlanId) || _.some(user.plan, ["sharedPlanId", form.sharedPlanId]);
                })
              }
              getOptionLabel={(userId) => {
                const user = usersById[userId];
                return `${user.name.first} ${user.name.last}`;
              }}
              renderOption={(userId, { selected }) => {
                const user = usersById[userId];
                return (
                  <>
                    <Checkbox icon={getAvatar(user)} checkedIcon={getAvatar(user)} checked={selected} />
                    {user.name.first} {user.name.last}
                  </>
                );
              }}
              renderInput={(params) => <TextField {...params} label="Attendees" variant="outlined" />}
              renderTags={(userIds, getTagProps) =>
                userIds.map((userId, index) => {
                  const user = usersById[userId];
                  return (
                    <StyledChip
                      key={userId}
                      variant="outlined"
                      label={`${user.name.first} ${user.name.last}`}
                      {...getTagProps({ index })}
                      avatar={getAvatar(user)}
                    />
                  );
                })
              }
            />
          </>
        );
        case 1:
          const menuItems = form.sharedPlanId ? [{id:'hem',label:'HEM'}, {id:'issueHandling',label:'Issue Handling'}, {id:'custom',label:'Custom'}] : [{id:'customNoDept',label:'Custom (no department selected)'}];
          return (
            <>    
              <FormControl fullWidth variant="outlined" margin="normal">
                <InputLabel>Use Template</InputLabel>
                <Select id="type-select" label="Use Template" name="type" value={form.type} onChange={handleChange}>  
                  {menuItems.map((item) => (<MenuItem key={item.id} value={item.id}>{item.label}</MenuItem>))}
                  {/* <MenuItem value="quarterly">Quarterly</MenuItem>
                  <MenuItem value="annual">Annual</MenuItem>
                  <MenuItem value="foundation">Foundation Building</MenuItem>
                  <MenuItem value="core">Core Building</MenuItem>
                  <MenuItem value="leader">Leader Building</MenuItem> */}     
                </Select>
              </FormControl>
              <MeetingStepsList type={form.type} steps={form.steps} onChange={handleChangeManual} org={params.org} planId={form.plan ? form.plan : _.get(dialog, "addMeetingDialog.corpPlanId")} sharedPlanId={form.sharedPlanId} />
            </>
          );
          case 2:
            return (
              <>
                <DatePicker
                  autoOk
                  clearable
                  value={form.startDate}
                  onChange={handleChangeDate("startDate")}
                  fullWidth
                  inputVariant="outlined"
                  format="dd/MM/yyyy"
                  margin="normal"
                  label="Start Date"
                />
                <TimePicker
                  minutesStep={5}
                  fullWidth
                  variant="inline"
                  inputVariant="outlined"
                  label="Start Time"
                  margin="normal"
                  value={form.startTime || ""}
                  onChange={handleChangeDate("startTime")}
                />
                <SelectTimezone value={form.timezone} handleChange={handleChangeManual} additionalTimezones={[userTimezone]} />
                
               {(showAllowAlterFrequencyToggle) && (
                  <FormGroup>
                    <FormControlLabel control={
                      <Switch
                        checked={allowAlterFrequency}
                        onChange={handleToggleAllowAlterFrequency}
                        name={`filterAllowAlterFrequency`}
                        data-test='mt-switch-meeting-allow-alter-frequency'
                      />    
                    } label="Update scheduling for this & all following meetings" />
                  </FormGroup>
                )}                
                <FormControl fullWidth margin="normal">
                  <InputLabel variant="outlined">Frequency</InputLabel>
                  <Select variant="outlined" label="Frequency" name="frequency" value={form.frequency} onChange={handleChange} disabled={!allowAlterFrequency}>
                    <MenuItem value={0}>One Time</MenuItem>
                    <MenuItem value={1}>Weekly</MenuItem>
                    <MenuItem value={2}>Bi-weekly</MenuItem>
                    <MenuItem value={4}>Monthly</MenuItem>
                    <MenuItem value={13}>Quarterly</MenuItem>
                    <MenuItem value={52}>Annually</MenuItem>
                  </Select>
                </FormControl>                
              </>
            );              
      default:
        return;
    }
  };

  return (
    <Dialog
      open={dialog.addMeetingDialog.open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
      fullWidth
      fullScreen={fs}
    >
      <DialogTitle>
        <div className={styles.title}>
          {form.id ? "Edit" : "Create New"} Meeting
          <div>
            <IconButton onClick={handleClose} size="small">
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </div>
        </div>
      </DialogTitle>
      <DialogContent>
        <Stepper nonLinear activeStep={activeStep} className={styles.stepper}>
          {dialogSteps.map((label, index) => {
            return (
              <Step key={label}>
                <StepButton onClick={handleStep(index)} >
                 <StepLabel>{label}</StepLabel>
                </StepButton>
              </Step>
            );
          })}
        </Stepper>
        {getStepContent(activeStep)}
      </DialogContent>
      <DialogActions>
        <div style={{ marginRight: "auto", display: "flex", gap: 8 }}>
          <Button disabled={activeStep === 0} onClick={handleBack}>
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleNext()}
            disabled={createLoading || updateLoading || activeStep === dialogSteps.length - 1}
          >
            {"Next"}
          </Button>
        </div>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit()}
            disabled={createLoading || updateLoading}
        >
            {form.id ? "Save" : "Create"}
        </Button>        
      </DialogActions>
    </Dialog>
  );
};

export default AddMeetingDialog;

const CREATE_MEETING = gql`
  ${MEETING_FIELDS}
  mutation AddMeetingDialog_CreateMeeting(
    $organization: ID!
    $owner: ID!
    $title: String!
    $frequency: Int!
    $startTime: String!
    $timezone: String!
    $type: String!
    $steps: [InputStep!]
    $users: [ID!]
    $plan: ID
    $sharedPlanId: ID
  ) {
    createMeeting(
      organization: $organization
      owner: $owner
      title: $title
      frequency: $frequency
      startTime: $startTime
      timezone: $timezone
      type: $type
      steps: $steps
      users: $users
      plan: $plan
      sharedPlanId: $sharedPlanId
    ) {
      ...MeetingFields
    }
  }
`;

const UPDATE_MEETING = gql`
  ${MEETING_FIELDS}
  mutation AddMeetingDialog_UpdateMeeting(
    $id: ID!
    $title: String!
    $frequency: Int
    $startTime: String!
    $timezone: String!
    $startTimeRecurring: String
    $timezoneRecurring: String
    $type: String!
    $steps: [InputStep!]
    $users: [ID!]
    $plan: ID
    $sharedPlanId: ID
  ) {
    updateMeeting(
      id: $id
      title: $title
      frequency: $frequency
      startTime: $startTime
      timezone: $timezone
      startTimeRecurring: $startTimeRecurring
      timezoneRecurring: $timezoneRecurring
      type: $type
      steps: $steps
      users: $users
      plan: $plan
      sharedPlanId: $sharedPlanId
    ) {
      ...MeetingFields
    }
  }
`;

const GET_USERS_AND_DEPARTMENTS = gql`
  query AddMeetingDialog_GetUsersDepts($organization: ID!, $oneYearCorpPlan: ID) {
    users(organization: $organization) {
      name {
        first
        last
      }
      profilePicture
      id
      plan {
        id
        departmentName
        sharedPlanId
      }
    }

    plans(organization: $organization, oneYearCorpPlan: $oneYearCorpPlan, category: "1 year", closed: false) {
      id
      departmentName
      sharedPlanId
    }
  }
`;


const GET_CORP_ONE_YEAR_PLANS_ORG = gql`
  query AddMeetingDialog_GetCorpOneYearPlans($organization: ID!) {
    corporatePlans: plans(organization: $organization, departmentName: "Corporate", category: "1 year") {
      id
      year
      closed
      locked
      theme
      color
      shortName
    }
  }
`;


const getDefaultMeetingStartDate = () => {
  let currentDate = new Date();
  let minutes = currentDate.getMinutes();
  let newMinutes = minutes + 5 - (minutes % 5);
  currentDate.setMinutes(newMinutes);
  currentDate.setSeconds(0);
  currentDate.setMilliseconds(0);
  return currentDate;
};
