import { useState, useEffect, useRef } from "react";
import _ from "lodash";

const useTableFilters = ({ location, initialValue = {}, handleSetStepFilters = null, stepFilters = null, isLeading = false }) => {
  const [page, setPageInternal] = useState(0);
  const [rowsPerPage, setRowsPerPageInternal] = useState(initialValue.rowsPerPage || 10);
  const [sort, setSortInternal] = useState({ value: initialValue.sortValue, order: initialValue.sortOrder });
  const [searchTerm, setSearchTermInternal] = useState(localStorage.getItem(`${location}.searchTerm`) || "");
  const [debouncedSearchTerm, setDebounceSearchTerm] = useState(localStorage.getItem(`${location}.searchTerm`) || "");
  const [showCompleted, setShowCompletedInternal] = useState(initialValue.showCompleted);

  const debouncedSetSearchTermRef = useRef(
    _.debounce((newValue) => {
      setDebounceSearchTerm(newValue);
      if (newValue && newValue !== localStorage.getItem(`${location}.searchTerm`)) {
        localStorage.setItem(`${location}.searchTerm`, newValue);
      } else if (!newValue) {
        localStorage.removeItem(`${location}.searchTerm`);
      }
    }, 1000)
  );
  useEffect(() => {
    if (debouncedSetSearchTermRef.current) {
      debouncedSetSearchTermRef.current.cancel();
      debouncedSetSearchTermRef.current(searchTerm);
    }
  }, [searchTerm]);

  const handleChangePage = (e, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPage = (e) => {
    setRowsPerPage(e.target.value);
    setPageInternal(0);
    if (e.target.value) {
      localStorage.setItem(`${location}.rowsPerPage`, JSON.stringify(e.target.value));
    }
  };

  const setSort = (value) => {
    if (handleSetStepFilters && isLeading) {
        handleSetStepFilters({ ...stepFilters, sortValue: value.value, sortOrder: value.order });
    }
    
    // if we only want the meeting leader to change sort options this will need to stop the Internal 'Set' from being called in some way (perhaps a lock to leader toggle?)
    setSortInternal(value);
  };

  const setRowsPerPage = (value) => {
    if (handleSetStepFilters && isLeading) {
      handleSetStepFilters({ ...stepFilters, rowsPerPage: value, page: 0 });
    }

    // if we only want the meeting leader to change sort options this will need to stop the Internal 'Set' from being called in some way (perhaps a lock to leader toggle?)
    setRowsPerPageInternal(value);
  };

  const setSearchTerm = (value) => {
    if (handleSetStepFilters && isLeading) {
      handleSetStepFilters({ ...stepFilters, searchTerm: value });
    }
    setSearchTermInternal(value);
  };

  const setShowCompleted = (value) => {
    if (handleSetStepFilters && isLeading) {
      handleSetStepFilters({ ...stepFilters, showCompleted: value });
    }
    setShowCompletedInternal(value);
  }

  const setPage = (value) => {
    if (handleSetStepFilters && isLeading) {
      handleSetStepFilters({ ...stepFilters, page: value });
    }
    setPageInternal(value);
  };

  return {
    page,
    rowsPerPage,
    sort,
    searchTerm,
    debouncedSearchTerm,
    showCompleted,
    handleChangePage,
    handleRowsPerPage,
    setSort,
    setSearchTerm,
    setShowCompleted,
    setRowsPerPage,
    setPage,
  };
};

export default useTableFilters;
