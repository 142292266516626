import { differenceInSeconds, addSeconds, format } from "date-fns";
import React, { useEffect, useState } from "react";
import styles from "./Meetings.module.scss";
import _ from "lodash";
import { Tooltip } from "@material-ui/core";

const StepTimeIndicator = ({ stepStartTime, timeSpentInSeconds, stepDuration, handleSetStepTimer }) => {
  //const TimerTooltip = ({ stepTimerRef, stepName, isCurrentStep, children, stepDuration }) => {
  const [timer, setTimer] = useState(timeSpentInSeconds);

  useEffect(() => {
    let interval;
    if (!_.isNil(stepStartTime)) {
      interval = setInterval(() => {
        setTimer(differenceInSeconds(new Date(), new Date(stepStartTime)) + timeSpentInSeconds);
        handleSetStepTimer(differenceInSeconds(new Date(), new Date(stepStartTime)) + timeSpentInSeconds);
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [stepStartTime, timeSpentInSeconds]);

  const progress = stepDuration * 60 > timer ? (1 - (timer / (stepDuration * 60))) * 100 : 0;
  let className = styles.stepTimeIndicator;
  let classNameContainer = styles.stepTimeIndicatorContainer;
  if (progress <= 0) {
    className = ` ${styles.stepTimeIndicatorAlert}`;
    classNameContainer = ` ${styles.stepTimeIndicatorContainerComplete}`;
  }
  else if (progress <= 10) {
    className = ` ${styles.stepTimeIndicatorAlert}`;
    classNameContainer = ` ${styles.stepTimeIndicatorContainerAlert}`;
  } 
  else if (progress <= 20) {
    className = ` ${styles.stepTimeIndicatorWarning}`;
    classNameContainer = ` ${styles.stepTimeIndicatorContainerWarning}`;    
  }

  return (
    
    <div className={classNameContainer}>  
      <div className={className} style={{ transform: `translateX(-${100 - progress}%)` }} />
    </div>
  );
};



export default StepTimeIndicator;
