import React, { useState, useRef, useEffect, useMemo } from "react";
import styles from "../Meetings.module.scss";
import { TableRow, TableCell, TextField } from "@material-ui/core";
import { useInViewport as useInView } from "react-in-viewport";
import handleViewport from "react-in-viewport";
import _ from "lodash";

import UserAvatars from "../../../components/UserAvatars/UserAvatars";
import { NumberZeroToTenFormatCustom } from "../../../components/NumberFormat/NumberFormat"; 

const UserRating = ({ user, value, comment, canEdit, handleSubmit }) => {
  const [initialized, setInitialized] = useState(false);

  const { id, name, profilePicture } = user;


  const [currentRatingValue, setCurrentRatingValue] = useState(value);
  const [currentComment, setCurrentComment] = useState(comment);
  const [ignoreSubmit, setIgnoreSubmit] = useState(false);

  const debouncedSubmit = useMemo(
    () =>
      _.debounce((newRatingValue, newComment) => {
        handleSubmit({ userId: id, value: newRatingValue, comment: newComment });
      }, 1000),
    []
  );
  
  // Cleanup the debounced function on unmount
  useEffect(() => {
    return () => debouncedSubmit.cancel();
  }, [debouncedSubmit]);

  useEffect(() => {
    // Set the component as initialized after the first render
    setInitialized(true);
  }, []);

  useEffect(() => {
    setIgnoreSubmit(true); // Prevent debouncedSubmit from triggering
    setCurrentRatingValue(value);
  }, [value]);

  useEffect(() => {
    setIgnoreSubmit(true); // Prevent debouncedSubmit from triggering
    setCurrentComment(comment);
  }, [comment]);

  // Reset the ignoreSubmit flag after updating the state
  useEffect(() => {
    if (ignoreSubmit) {
      const timer = setTimeout(() => setIgnoreSubmit(false), 0);
      return () => clearTimeout(timer);
    }
  }, [ignoreSubmit]);



  const handleRatingChange = (e) => {
    if (initialized) {
      const { name, value: targetValue } = e.target;
      setCurrentRatingValue(targetValue);
      if (!ignoreSubmit) {
        debouncedSubmit(targetValue, currentComment);
      }
    }
  };

  const handleCommentChange = (e) => {
    const { name, value: targetValue } = e.target;
    setCurrentComment(targetValue);
    if (!ignoreSubmit) {
      debouncedSubmit(currentRatingValue, targetValue);
    }
  };

  return (
    <>
      <TableRow>
        <TableCell className={styles.firstCell}>
          <UserAvatars users={[{ id, name, profilePicture }]} />
        </TableCell>
        <TableCell>
          <div className={styles.flex}>
            {name.first} {name.last}
          </div>
        </TableCell>
        {canEdit ? (
            <React.Fragment key={`${id}-row`}>
            <TableCell className={styles.virtualCell}>
              <div className={styles.roundedToggle}>
                <VirtualizedField value={currentRatingValue} handleChange={handleRatingChange} name={`rt-${id}`} id={`rt-${id}`} saving={false} numeric={true}/> 
              </div>
            </TableCell>
            <TableCell className={styles.virtualCell}>
              <div className={styles.roundedToggle}>            
                <VirtualizedField value={currentComment} handleChange={handleCommentChange} name={`cm-${id}`} id={`cm-${id}`} saving={false} numeric={false}/>
              </div>
            </TableCell>              
            </React.Fragment>
          ) : (
            <>

              <TableCell>{value}</TableCell>
              <TableCell>{comment}</TableCell>
            </>
          )
        }
      </TableRow>
    </>
  );
};

export default handleViewport(UserRating);

function VirtualizedField({
    value,
    handleChange,
    name,
    id,
    // handleUpdate,
    saving,
    numeric,

  }) {
    const ref = useRef();
    const { inViewport } = useInView(ref, undefined, undefined, { onEnterViewPort: () => {}, onLeaveViewPort: () => {} });
  

    return (
      <div ref={ref}>
        {inViewport ? (
          <div>
              <TextField
                value={value}
                onChange={handleChange}
                name={name}
                className={styles.virtualValueAbove}
                id={id}
                // onBlur={handleUpdate}
                disabled={saving}
                autoComplete="off"
                InputProps={ numeric ? {
                  inputComponent: NumberZeroToTenFormatCustom,
                  disableUnderline: false,
                  inputProps: {
                    style: { paddingLeft: '10px' } // Add padding to the left of the input element
                  }
                } : {
                  inputProps: {
                    style: { paddingLeft: '10px' } // Add padding to the left of the input element
                  }                  
                }}
                
              />

          </div>
        ) : (
          <div />
        )}
      </div>
    );
  }

