import React, { useMemo, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import _ from "lodash";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Typography,
  Select,
  MenuItem,
  Switch,
  FormControl,
  InputLabel,
  Avatar,
} from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import { isDate } from "date-fns";

import { makeStyles } from "@material-ui/core/styles";
import SelectDepartment from "../../components/SelectDepartment/SelectDepartment";
import { NOTIFICATION_FREQUENCY } from "../../utils/const";
import { permissionDictionary } from "../../utils/authorization";
import { UserContext } from "../../context/userContext";
import Icon from "@mdi/react";
import { mdiAccount } from "@mdi/js";
import styles from "./Headlines.module.scss";
import { removeTypenameDeep } from "../../utils/misc";
import { HEADLINE_CATEGORIES } from "../../utils/const";


const EditDialog = ({
  open,
  handleClose,
  headline,
  plansData,
  form,
  resetForm,
  formErrors,
  handleChange,
  handleChangeDate,
  handleSubmit,
  handleCreateCancel,
}) => {
  const { user: currentUser } = useContext(UserContext);

  useEffect(() => {
    if (headline) {
      const { id, organization, category, value, summary, author, displayUntilDate, sharedPlanIds } = headline;
      let editForm = {
        id,
        organization,
        category,
        value,
        summary,
        author,
        displayUntilDate: isDate(displayUntilDate) || _.isNil(displayUntilDate) ? displayUntilDate : new Date(parseInt(displayUntilDate)),
        sharedPlanIds,
      };
      resetForm(editForm);
    }
  }, [headline]);

  return (
    <>
      <Dialog
        open={open}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        onExited={() => resetForm()}
      >
        <DialogTitle>{headline ? "Edit" : "Create"} Headline</DialogTitle>
        <DialogContent>
          <TextField
            label="Enter a Headline"
            name="value"
            fullWidth
            variant="outlined"
            margin="normal"
            value={form.value || ""}
            onChange={handleChange}
            helperText={formErrors.value}
            error={Boolean(formErrors.value)}
          />
          <TextField
            label="Description or Instructions (Optional)"
            name="summary"
            fullWidth
            variant="outlined"
            margin="normal"
            value={form.summary || ""}
            onChange={handleChange}
            helperText={formErrors.summary}
            error={Boolean(formErrors.summary)}
          />
          <Select
            name="category"
            value={form.category || ""}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            margin="normal"
            label="Category"
            error={Boolean(formErrors.category)}
          >
            {Object.values(HEADLINE_CATEGORIES).map((category) => (
              <MenuItem key={category.value} value={category.value}>
                {category.name}
              </MenuItem>
            ))}              
          </Select>
          <DatePicker
            autoOk
            clearable
            value={form.displayUntilDate}
            onChange={handleChangeDate("displayUntilDate")}
            fullWidth
            inputVariant="outlined"
            format="dd/MM/yyyy"
            margin="normal"
            label="Display Until Date"     
          />
          <SelectDepartment
            plans={ _.uniqBy(_.get(plansData, "plans"), 'sharedPlanId') }
            name="sharedPlanIds"
            handleChange={handleChange}
            value={form.sharedPlanIds}
            helperText={formErrors.sharedPlanIds}
            error={Boolean(formErrors.sharedPlanIds)}
            selectType="sharedPlanId"
            multiple
            showAll={false}
          />               
        </DialogContent>
        <DialogActions>
          <Button onClick={headline ? handleClose : () => handleCreateCancel(form)} variant="outlined">
            Cancel
          </Button>
          <Button color="primary" onClick={handleSubmit} variant="contained" data-test='cud-button-save'>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditDialog;
