import React, { useEffect, useContext, useState, useRef } from "react";
import styles from "./Notes.module.scss";
import useNotes from "./useNotes";
import _ from "lodash";
import { isAuthed } from "../../utils/authorization";
import Loading from "../Loading/Loading";
import DeleteConfirm from "./DeleteConfirm";

import { SnackbarContext } from "../../context/snackbarContext";

import {
  Button,
  Typography,
  List,
  ListItem,
  Avatar,
  ListItemText,
  Tooltip,
  Switch,
  Container,
  FormGroup,
  FormControlLabel,
} from "@material-ui/core";
import Icon from "@mdi/react";
import { mdiDownload, mdiFileDocumentOutline, mdiFileImage, mdiUnfoldMoreHorizontal } from "@mdi/js";

import { fullDate } from "../../utils/dates";
import { deserialize } from "../RichText/functions";
import RichText from "../RichText/RichText";

const NotesEmbedded = ({ user, notesData: data, notes, onCreateNote, referenceId, setRefetchId }) => {

  const { snack } = useContext(SnackbarContext);

  const { id, model, core, openInEditMode, openNotesDialog, user: userId, value: referenceObjectValue, additionalProps, planId } = data;
/*
  const {
    data: { id, model, core, openInEditMode, openNotesDialog, user: userId, value: referenceObjectValue, additionalProps, planId },
    notes,
    setRefetchId,
    onCreateNote,
  } = useContext(NotesContext);
*/
  const {
    data: notesData,
    editMode,
    getData,
    handleEditMode,
    handleChange,
    handleClose,
    handleExitDialog,
    handleCreate,
    handleUpdate,
    handleDelete,
    handleRemoveImage,
    handleCopyImageToClipboard,
  } = useNotes({
    user,
    snack,
    notes,
    model,
    setRefetchId,
    openInEditMode,
    onCreateNote,
    openNotesDialog,
  });

  const [autoSave, setAutoSave] = useState(true);
  const [doExecuteSave, setDoExecuteSave] = useState(false);
  const [textHasChanged, setTextHasChanged] = useState(false);
  const timerRef = useRef(null);

  useEffect(() => {
    getData(id, model, core, additionalProps);

  }, [id]);

  const prevNotesDataRef = useRef();

  useEffect(() => {
    if (!_.isEqual(prevNotesDataRef.current, notesData)) {
      prevNotesDataRef.current = notesData;
      if (notesData) {
        const currentUsersNotes = notesData.filter((note) => note.user.id === user.user.id);
        handleEditMode(true, currentUsersNotes.length ? currentUsersNotes[0] : null, model)();
      }
    }
  }, [notesData]);


  useEffect(() => {
    if (doExecuteSave) {
      if (editMode.open && textHasChanged) {
        if (_.isNil(editMode.id)) {
          handleCreate(id, model, core, additionalProps)();
        } else {
          handleUpdate({ id, model, core, additionalProps })();
        }    
      }
      setDoExecuteSave(false);
    }
    if (textHasChanged) setTextHasChanged(false);    
  }, [doExecuteSave]);

  const startAutoSaveTimer = () => {
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      setDoExecuteSave(true); // Put this logic outside the timer to ensure the current state is used (not the state when the timer was set)
      startAutoSaveTimer();
    }, 30000);
  };

  useEffect(() => {
    if (autoSave) {
      startAutoSaveTimer();
    }
    else {
      clearTimeout(timerRef.current);
    }

    return () => {
      clearTimeout(timerRef.current);
    };
  }, [autoSave, editMode.open, id, model, core, additionalProps]);




  const handleToggleAutoSave = (id, model, core, additionalProps) => async () => {
    setAutoSave((prev) => !prev);
  };


  const handleValueChange = (value) => {
    if (!_.isEqual(editMode.text, value)) {
      handleChange({ target: { name: "text", value } });
      setTextHasChanged(true);
    }
  };

  const numOfNotes = (notesData || []).length;
  return (
    <>
      <Container className={styles.title}>
        <div style={{ display:"flex", flexDirection:"column", alignItems:"left", justifyContent:"space-between", width:"100%" }}>
          <div style={{ display:"flex", flexDirection:"row", justifyContent:"center", width:"100%" }}>
            <Icon path={mdiUnfoldMoreHorizontal} size={0.75} color="rgba(0, 0, 0, 0.54)"></Icon>
          </div>
          <Typography variant="h5" style={{marginTop:"-10px", marginBottom:"5px"}}>{_.startCase(model)} Notes</Typography>
        </div>
      </Container>
      <div style={{ display:"flex", flexDirection:"column", alignItems:"left", justifyContent:"space-between", rowGap:"10px", width:"100%" }}>
        <Container className={styles.contentEmbedded}>
          <NotesContent
          notesData={notesData}
          editMode={editMode}
          handleChange={handleChange}
          core={core}
          model={model}
          user={user}
          id={id}
          handleDelete={handleDelete}
          additionalProps={additionalProps}
          handleEditMode={handleEditMode}
          handleRemoveImage={handleRemoveImage}
          handleCopyImage={handleCopyImageToClipboard}
          handleValueChange={handleValueChange}
          />
        </Container>
        <Container style={{ display:"flex", flexDirection:"row" }}>
        <Tooltip title="Enable Autosaving">
            <FormGroup>
              <FormControlLabel control={
                <Switch
                  checked={autoSave}
                  onChange={handleToggleAutoSave({id, model, core, additionalProps})}
                  name={`autoSave`}
                  data-test='mt-switch-autosave'
                />      
              } label="Enable Autosave" />
            </FormGroup>
          </Tooltip>           
          {editMode.open && _.isNil(editMode.id) && (
            <Button variant="contained" onClick={handleCreate(id, model, core, additionalProps)} color="primary" style={{ marginLeft: "auto" }}>
              Save
            </Button>
          )}
          {editMode.open && !_.isNil(editMode.id) && (
            <Button variant="contained" onClick={handleUpdate({ id, model, core, additionalProps })} color="primary" style={{ marginLeft: "auto" }}>
              Save
            </Button>
          )}
        </Container>
      </div>  
    </>
  );
};

export default NotesEmbedded;

const NotesContent = ({
  notesData,
  editMode,
  handleChange,
  core,
  model,
  user,
  id,
  handleDelete,
  handleEditMode,
  handleRemoveImage,
  handleCopyImage,
  additionalProps,
  handleValueChange,
}) => {
  return (
    <>
      {!notesData && <Loading />}
      {editMode.open ? (
        <RichText value={editMode.text} readOnly={false} allowInitialLoad={true} onChange={handleValueChange} autoFocus={false} />
      ) : (
        <>
          {_.isEmpty(notesData) && <Typography>No {_.lowerCase(core || model)} notes</Typography>}
          <List>
            {notesData &&
              notesData.map((note) => {
                const { id: noteId, user: noteUser, date, text, url, filename, type } = note;
                const isImg = type === "img";
                const path = isImg ? mdiFileImage : mdiFileDocumentOutline;

                return (
                  <ListItem key={date} dense>
                    <ListItemText>
                      <div className={styles.listItemText}>
                        <div className={styles.flex}>
                          <Avatar className={styles.avatar}>
                            <Icon path={path} color="#fff" size={1} />
                          </Avatar>
                          <div>
                            <Typography variant="subtitle2" style={{ display: "flex", alignItems: "center" }}>
                              {!_.isNil(noteUser) && `${_.get(noteUser, "name.first")} ${_.get(noteUser, "name.last")}`}
                              {url !== "undefined" && !_.isNil(url) && (
                                <a
                                  href={url}
                                  target="__blank"
                                  download
                                  className={styles.downloadLink}
                                  style={{ marginLeft: _.isNil(noteUser) ? 0 : 20 }}
                                >
                                  <Icon path={mdiDownload} color="#3f51b5" size={0.75} />
                                  {filename}
                                </a>
                              )}
                            </Typography>
                            <Typography variant="caption">{fullDate(date)}</Typography>
                          </div>
                        </div>
                        {isAuthed(user.user, "department facilitator") && (
                          <div>
                            <DeleteConfirm
                              id={noteId}
                              referenceId={id}
                              model={model}
                              core={core}
                              filename={filename}
                              handleDelete={handleDelete}
                              additionalProps={additionalProps}
                              handleEditMode={handleEditMode}
                              handleRemoveImage={handleRemoveImage}
                              handleCopyImage={isImg ? handleCopyImage : null}
                              note={note}
                            />
                          </div>
                        )}
                      </div>
                      <RichText value={deserialize(text)} readOnly={true} />
                      {isImg && <img src={url} alt="" className={styles.image} />}
                    </ListItemText>
                  </ListItem>
                );
              })}
          </List>
        </>
      )}
    </>
  );
};

