// Icons
import {
  mdiDatabase,
  mdiNumeric3Box,
  mdiAlphaMBox,
  mdiNumeric1Box,
  mdiChartBar,
  mdiCircleSlice1,
  mdiCircleSlice2,
  mdiCircleSlice8,
  mdiLayers,
  mdiAccountPlus,
  mdiCheckboxMarked,
  mdiAlertDecagram,
  mdiCardBulleted,
  mdiRhombus,
  mdiBug,
  mdiBell,
  mdiCogClockwise,
  mdiSitemap,
  mdiSelectGroup,
  mdiLightbulbQuestion,
  mdiBullhorn,
  mdiFolderStar,
  mdiFlagCheckered,
  mdiClover,
  mdiShopping,
  mdiTools,
  mdiOffer,
  mdiLightbulbOn,
  mdiMapMarkerPath,
  mdiSetLeft,
  mdiNumeric5Box,
  mdiGoogleClassroom,
  mdiCalendarText,
  mdiNotebookMultiple,
  mdiSubdirectoryArrowRight,
  mdiMinus,
  mdiEye
} from "@mdi/js";

// Pages
import {
  AnnualReview,
  Dashboard,
  OneYear,
  QuarterlyReview,
  QuarterRocks,
  Review,
  Reports,
  Scorecard,
  TheCore,
  TheVision,
  ThreeYear,
  Todos,
  Settings,
  Users,
  WeeklyReview,
} from "./views";

import { getListOfAuth } from "./utils/authorization";
import Bugs from "./views/Bugs/Bugs";
import SavedNotifications from "./views/SavedNotifications/SavedNotifications";
import SystemTasksTable from "./components/SystemTasksTable/SystemTasksTable";
import AccountabilityChartList from "./views/AccountabilityChartList/AccountabilityChartList";
import AccountabilityChart from "./components/AccountabilityChart/AccountabilityChart";
import CompanyInfo from "./views/CompanyInfo/CompanyInfo";
import Departments from "./views/Departments/Departments";
import Vision from "./views/Vision/Vision";
import FiveYearMission from "./views/FiveYearMission/FiveYearMission";
import Meetings from "./views/Meetings/Meetings";
import Meeting from "./views/Meetings/Meeting";
import SystemPrompts from "./views/SystemPrompts/SystemPrompts";
import Headlines from "./views/Headlines/Headlines";

export const routes = [
  {
    path: "information",
    name: "Information",
    icon: mdiCardBulleted,
    component: CompanyInfo,
    layout: getListOfAuth(123456),
    type: "my company",
  },
  {
    path: "departments",
    name: "Departments",
    icon: mdiSelectGroup,
    component: Departments,
    layout: getListOfAuth(123456),
    type: "my company",
  },
  {
    path: "accountability-chart",
    name: "R&R Chart",
    icon: mdiSitemap,
    component: AccountabilityChartList,
    layout: getListOfAuth(123456),
    type: "my company",
  },
  {
    path: "chart/:chartId",
    name: "Chart Page",
    component: AccountabilityChart,
  },
  {
    path: "users",
    name: "Manage Users",
    icon: mdiAccountPlus,
    component: Users,
    layout: getListOfAuth(1234),
    type: "my company",
  },
  {
    path: "purpose",
    name: "Core Purpose",
    icon: mdiFlagCheckered,
    component: Vision,
    layout: getListOfAuth(123456),
    type: "vision",
    props: { name: "purpose" },
  },
  {
    path: "value",
    name: "Core Values",
    icon: mdiClover,
    component: Vision,
    layout: getListOfAuth(123456),
    type: "vision",
    props: { name: "value" },
  },
  {
    path: "competency",
    name: "Core Competency",
    icon: mdiTools,
    component: Vision,
    layout: getListOfAuth(123456),
    type: "vision",
    props: { name: "competency" },
  },
  {
    path: "market",
    name: "Core Market",
    icon: mdiShopping,
    component: Vision,
    layout: getListOfAuth(123456),
    type: "vision",
    props: { name: "market" },
  },
  {
    path: "offering",
    name: "Core Offering",
    icon: mdiOffer,
    component: Vision,
    layout: getListOfAuth(123456),
    type: "vision",
    props: { name: "offering" },
  },
  {
    path: "valueProposition",
    name: "Core Value Proposition",
    icon: mdiLightbulbOn,
    component: Vision,
    layout: getListOfAuth(123456),
    type: "vision",
    props: { name: "valueProposition" },
  },
  {
    path: "process",
    name: "Core Journey",
    icon: mdiMapMarkerPath,
    component: Vision,
    layout: getListOfAuth(123456),
    type: "vision",
    props: { name: "process" },
  },
  {
    path: "differentiation",
    name: "Core Differentiators",
    icon: mdiSetLeft,
    component: Vision,
    layout: getListOfAuth(123456),
    type: "vision",
    props: { name: "differentiation" },
  },
  {
    path: "5-year",
    name: "5 Year Mission",
    icon: mdiNumeric5Box,
    component: FiveYearMission,
    layout: getListOfAuth(123456),
    type: "strategy",
  },
  {
    name: "3 Year Plan",
    icon: mdiNumeric3Box,
    layout: getListOfAuth(123456),
    type: "strategy",
    collapseType: "3-year",
  },
  {
    path: "3-year-objectives",
    name: "Objectives",
    icon: mdiMinus,
    component: ThreeYear,
    layout: getListOfAuth(123456),
    type: "3-year",
    props: { tab: "objectives" },
  },
  {
    path: "3-year-metrics",
    name: "Metrics",
    icon: mdiMinus,
    component: ThreeYear,
    layout: getListOfAuth(123456),
    type: "3-year",
    props: { tab: "metrics" },
  },
  {
    name: "1 Year Plan",
    icon: mdiNumeric1Box,
    layout: getListOfAuth(123456),
    type: "strategy",
    collapseType: "1-year",
  },
  {
    path: "1-year-objectives",
    name: "Objectives",
    icon: mdiMinus,
    component: OneYear,
    layout: getListOfAuth(123456),
    type: "1-year",
    props: { tab: "objectives" },
  },
  {
    path: "1-year-metrics",
    name: "Metrics",
    icon: mdiMinus,
    component: OneYear,
    layout: getListOfAuth(123456),
    type: "1-year",
    props: { tab: "metrics" },
  },
  {
    path: "1-year-summary",
    name: "Summary",
    icon: mdiMinus,
    component: OneYear,
    layout: getListOfAuth(123456),
    type: "1-year",
    props: { tab: "summary" },
  },
   {
     path: "review/weekly/:meeting_id",
     name: "Weekly Review Old",
     component: Review,   
   },
  {
    path: "meeting/:meeting_id",
    name: "Weekly Review",
    component: Meeting,
  },
  /*{
    path: "quarterly-monitor",
    name: "Quarterly Monitor",
    icon: mdiCalendarText,
    component: QuarterlyReview,
    layout: getListOfAuth(123456),
    type: "execution",
    //disabled: true,
  },
  {
    path: "annual-crafting",
    name: "Annual Crafting",
    icon: mdiNotebookMultiple,
    component: Scorecard,
    layout: getListOfAuth(123456),
    type: "execution",
    disabled: true,
  },*/
  {
    path: "scorecard",
    name: "Scorecard",
    icon: mdiCardBulleted,
    component: Scorecard,
    layout: getListOfAuth(123456),
    type: "execution",
  },
  {
    path: "quarter-rocks",
    name: "Rocks",
    icon: mdiRhombus,
    component: QuarterRocks,
    layout: getListOfAuth(123456),
    type: "execution",
  },
  {
    path: "todos",
    name: "To Dos",
    icon: mdiCheckboxMarked,
    component: Todos,
    layout: getListOfAuth(123456),
    type: "execution",
  },
  {
    path: "issues",
    name: "Issues",
    icon: mdiAlertDecagram,
    component: Todos,
    layout: getListOfAuth(123456),
    type: "execution",
    props: { issues: true },
  },
  {
    path: "meetings",
    name: "Meetings",
    icon: mdiGoogleClassroom,
    component: Meetings,
    layout: getListOfAuth(123456),
    type: "execution",
  },  
//   {
//     path: "review/weekly",
//     name: "Weekly HEM",
//     icon: mdiCircleSlice1,
//     component: WeeklyReview,
//     layout: getListOfAuth(123456),
//     type: "execution",  
//   },
   //{
   //  path: "the-core",
  //   name: "The Core",
  //   icon: mdiDatabase,
  //   component: TheCore,
  //   layout: getListOfAuth(123456),
  //   type: "",
  // },
  {
    path: "vision",
    name: "Vision",
    icon: mdiEye,
    component: TheVision,
    layout: getListOfAuth(123456),
    type: "thevision",
  },  
  {
    path: "reports",
    name: "Reports",
    icon: mdiChartBar,
    component: Reports,
    layout: getListOfAuth(12345),
    type: "reports",
  },
  {
    path: "bugs",
    name: "View Bugs",
    icon: mdiBug,
    component: Bugs,
    layout: getListOfAuth(1234),
    type: "system",
  },
  {
    path: "systemprompts",
    name: "Questions/Prompts",
    icon: mdiLightbulbQuestion,
    component: SystemPrompts,
    layout: getListOfAuth(1234),
    type: "system",
  },
  {
    path: "headlines",
    name: "Headlines",
    icon: mdiBullhorn,
    component: Headlines,
    layout: getListOfAuth(1234),
    type: "system",
  },    
  {
    path: "saved-notifications",
    name: "Notifications",
    icon: mdiBell,
    component: SavedNotifications,
    layout: getListOfAuth(1234),
    type: "system",
  },
  {
    path: "system-tasks",
    name: "System Tasks",
    icon: mdiCogClockwise,
    component: SystemTasksTable,
    layout: getListOfAuth(1234),
    type: "system",
  },
  {
    path: "settings",
    name: "Settings",
    component: Settings,
    layout: getListOfAuth(123456),
    type: "hidden",
  },
  // {
  //   path: "review/annual",
  //   name: "Annual Strategic Crafting",
  //   icon: mdiCircleSlice8,
  //   component: AnnualReview,
  //   layout: getListOfAuth(12),
  //   type: "primary",
  // },
  //{
  //   path: "review/quarterly",
  //   name: "Quarterly Monitor",
  //   icon: mdiCircleSlice2,
  //   component: QuarterlyReview,
  //   layout: getListOfAuth(12),
  //   type: "primary",
  //},
  {
    // Keep last otherwise other routes will never match
    path: "",
    name: "Dashboard",
    icon: mdiLayers,
    component: Dashboard,
    layout: getListOfAuth(123456),
    type: "dashboard",
  },
];
