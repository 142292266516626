import React from "react";
import styles from "./UserAvatars.module.scss";
import _ from "lodash";
import { Tooltip, Avatar, List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { AvatarGroup } from "@material-ui/lab";
import Icon from "@mdi/react";
import { mdiCrown } from "@mdi/js";

const UserAvatars = ({ users, small, hidden, maxUsersVisible, handleShowIcon = null, user: currentUser = null, currentLeaderId = null }) => {
  const userValues = (
    <List dense>
      {users &&
        users.map((user, idx) => {
          const isCurrentUser = !_.isNil(currentUser) && user.id === _.get(currentUser,'id',null);
          const isCurrentLeader = !_.isNil(currentLeaderId) && user.id === currentLeaderId;
          return (
            <ListItem key={idx}>
              <ListItemIcon>
                <Avatar key={user.id} src={user.profilePicture} alt={`${user.name.first} ${user.name.last}`} className={styles.avatar}>
                  {user.name.first[0]}
                  {user.name.last[0]}
                </Avatar>
              </ListItemIcon>
              <ListItemText>
                <span style={{ fontWeight: isCurrentUser ? 'bold' : 'normal' }}>
                  {user.name.first} {user.name.last}{" "}
                </span>
                {isCurrentLeader && <Icon path={mdiCrown} color="rgb(235, 238, 61)" size={0.65} />}
                {!isCurrentLeader && handleShowIcon && handleShowIcon(user) && (
                  <Icon path={mdiCrown} size={0.5} />
                )}                
              </ListItemText>
            </ListItem>
          );
        })}
    </List>
  );

  if (_.isEmpty(users) && !hidden)
    return (
      <Tooltip title="No user assigned">
        <AvatarGroup max={99} className={styles.center}>
          <Avatar className={small ? styles.avatarNoneSmall : styles.avatarNone}>?</Avatar>
        </AvatarGroup>
      </Tooltip>
    );

  return (
    <Tooltip title={userValues} interactive>
      <AvatarGroup max={99} className={styles.center}>
        {users &&
          users.map(({ id, name, profilePicture }, idx) => {
            if (!_.isNil(maxUsersVisible) && idx + 1 > maxUsersVisible) return null;
            return (
              <Avatar
                key={idx}
                src={profilePicture}
                alt={`${name.first} ${name.last}`}
                className={small ? styles.avatarSmall : styles.avatar}
              >
                {name.first[0]}
                {name.last[0]}
              </Avatar>
            );
          })}
        {users && !_.isNil(maxUsersVisible) && users.length > maxUsersVisible && (
          <Avatar src="" className={small ? styles.avatarRemainSmall : styles.avatarRemain}>
            +{users.length - maxUsersVisible}
          </Avatar>
        )}
      </AvatarGroup>
    </Tooltip>
  );
};

export default UserAvatars;
