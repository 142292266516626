import React, { useState, useEffect, useRef, useContext } from "react";
import styles from "./AddTodoDialog.module.scss";
import _ from "lodash";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import useForm from "../../hooks/useForm";
import { isAuthed } from "../../utils/authorization";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
  useMediaQuery,
  Typography,
} from "@material-ui/core";
import { Autocomplete, ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import CloseIcon from "@material-ui/icons/Close";
import Icon from "@mdi/react";
import {
  mdiLinkVariantOff,
  mdiRhombus,
  mdiRhombusSplit,
  mdiPoll,
  mdiBullseyeArrow,
  mdiAlertDecagram,
  mdiCheckboxMarked,
  mdiChartTimelineVariant,
} from "@mdi/js";
import SelectUsers from "../SelectUsers/SelectUsers";
import SelectDepartment from "../SelectDepartment/SelectDepartment";
import Loading from "../Loading/Loading";
import { Link } from "react-router-dom";
import PlanPill from "../PlanPill/PlanPill";
import { getPlansByReferenceModel } from "../../utils/misc";
import { TODO_FIELDS } from "../../utils/fragments";

import { MeetingContext } from "../../context/meetingContext";

const PRIORITY = ["high", "medium", "low"];

const initForm = {
  value: null,
  referenceId: null,
  referenceModel: null,
  priority: "medium",
  user: [],
  plan: null,
};

const initErrorForm = {
  value: ["required"],
  user: ["required"],
};

const AddTodoDialog = ({ dialog, setDialog, requestFetch, fetch, params, user, snack }) => {
  const isAdmin = isAuthed(user.user, "department facilitator");

  const { data: activeMeetingData } = useContext(MeetingContext);  
  const sharedPlanId = _.get(activeMeetingData,"meeting.sharedPlanId", null) || _.get(user, "departmentFilter.sharedPlanId");
  const departmentFilterSharedPlanId = sharedPlanId;

  const [createTodo, { loading }] = useMutation(CREATE_TODO);
  const [menuItems, setMenuItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [inputSearchTerm, setInputSearchTerm] = useState("");

  const debouncedSetSearchTermRef = useRef(_.debounce((newValue) => setSearchTerm(newValue), 700));
  useEffect(() => {
    if (debouncedSetSearchTermRef.current) {
      debouncedSetSearchTermRef.current.cancel();
      debouncedSetSearchTermRef.current(inputSearchTerm);
    }
  }, [inputSearchTerm]);

  const { form, formErrors, handleChange, handleChangeToggleButton, resetForm, validateForm } = useForm({
    initForm,
    initErrorForm,
  });

  const {
    data,
    refetch,
    loading: queryLoading,
  } = useQuery(GET_SC_ROCKS_OBJECTIVES_METRICS, {
    variables: { organization: params.org, sharedPlanId, searchTerm, corpPlan: _.get(dialog, "addTodoDialog.planId", null) },
  });

  const {
    data: userPlanData,
    refetch: userPlanDataRefetch,
    loading: userPlanDataLoading,
  } = useQuery(GET_USERS_PLANS, {
    variables: { organization: params.org, corpPlan: _.get(dialog, "addTodoDialog.planId", null) },
  });

  const [getReferencedTodo, { data: referencedTodoData, loading: referenceLoading }] = useLazyQuery(GET_REFERENCED_TODO_CREATE);

  const handleChangeReferenceId = (item) => {
    // change tied in reference id
    const newForm = _.cloneDeep(form);
    _.set(newForm, "referenceId", _.get(item, "id", null));

    // also change department based on tied in selected
    const plans = getPlansByReferenceModel(form.referenceModel, item);
    const firstPlan = _.first(plans);
    if (!_.isNil(firstPlan) && _.isNil(form.plan)) {
      const sharedPlanId = _.get(firstPlan, "sharedPlanId");
      const targetPlan = _.find(userPlanData.plans || [], (plan) => plan.sharedPlanId === sharedPlanId);

      if (targetPlan) {
        _.set(newForm, "plan", _.get(targetPlan, "id", null));
      }
    }
    resetForm(newForm);
  };

  const isTodoReference = ["Todo", "Issue"].includes(form.referenceModel);
  const {
    data: todosData,
    loading: todosLoading,
    fetchMore: fetchMoreTodos,
  } = useQuery(GET_MORE_TODOS, {
    variables: {
      organization: params.org,
      sharedPlanId,
      limit: 25,
      cursor: null,
      searchTerm,
      oneYearCorpPlan: _.get(dialog, "addTodoDialog.planId", null),
    },
  });

  const {
    data: issueData,
    loading: issuesLoading,
    fetchMore: fetchMoreIssues,
  } = useQuery(GET_MORE_ISSUES, {
    variables: {
      organization: params.org,
      sharedPlanId,
      limit: 25,
      cursor: null,
      searchTerm,
      oneYearCorpPlan: _.get(dialog, "addTodoDialog.planId", null),
    },
  });

  const fs = useMediaQuery("(max-width: 600px)");

  const handleSubmit = (close) => async () => {
    if (!validateForm()) return;

    const { value, referenceId, referenceModel, priority, plan } = form;

    const ok = await createTodo({
      variables: {
        organization: params.org,
        category: dialog.addTodoDialog.category,
        user: isAdmin ? form.user : user.user.id,
        author: user.user.id,
        priority,
        value,
        referenceId: referenceModel ? referenceId : null,
        referenceModel,
        plan,
      },
    });

    if (ok.data.createTodo) {
      if (dialog.addTodoDialog.referenceId && dialog.addTodoDialog.category === "issue") {
        snack(
          <>
            Issue Created! Click{" "}
            <Link style={{ color: "white", textDecoration: "none" }} to="issues">
              <strong>here</strong>
            </Link>{" "}
            to view.
          </>
        );
      } else {
        snack(`Created "${value}" ${dialog.addTodoDialog.category}`);
      }
      requestFetch();
      // If this dialog was opened in with another object as reference, do not remove the reference
      resetForm({ ...form, value: null });

      if (close) {
        handleClose();
      }
    }
  };

  const loadMoreItems = (event, form) => {
    const userScrolledToTheBottom = Math.abs(event.target.scrollHeight - event.target.clientHeight - event.target.scrollTop) < 1;
    if (isTodoReference && userScrolledToTheBottom) {
      //user is at the end of the list so load more items
      if (form.referenceModel === "Todo" && !_.isNil(_.get(todosData, "results.nextCursor"))) {
        fetchMoreTodos({
          variables: { organization: params.org, sharedPlanId, limit: 25, cursor: todosData.results.nextCursor, searchTerm },
        });
      }

      if (form.referenceModel === "Issue" && !_.isNil(_.get(issueData, "results.nextCursor"))) {
        fetchMoreIssues({
          variables: { organization: params.org, sharedPlanId, limit: 25, cursor: issueData.results.nextCursor, searchTerm },
        });
      }
    }
  };

  const handleClose = () => {
    resetForm();
    setMenuItems([]);
    setSearchTerm("");
    setInputSearchTerm("");  
    setDialog({ ...dialog, addTodoDialog: { open: false, id: null, model: null, category: null } });
  };

  useEffect(() => {
    if (dialog.addTodoDialog.open) {
      refetch();
    }
  }, [fetch, dialog.addTodoDialog.open]);

  useEffect(() => {
    if (dialog.addTodoDialog.open) {
      const newForm = { ...initForm };

      let todoDialog = dialog.addTodoDialog;

      if (todoDialog.value) {
        newForm.value = todoDialog.value;
      }

      if (todoDialog.user) {
        newForm.user = todoDialog.user;
      }
      if (todoDialog.referenceId) {
        newForm.referenceId = todoDialog.referenceId;
        newForm.referenceModel = todoDialog.referenceModel;
      }

      if (todoDialog.deptPlanId) {
        newForm.plan = todoDialog.deptPlanId;
      }
      else if (todoDialog.sharedPlanId && !userPlanDataLoading && dialog.addTodoDialog.open) {
        const currentPlan = _.find(userPlanData.plans, (plan) => plan.sharedPlanId === todoDialog.sharedPlanId);
        newForm.plan = _.get(currentPlan, "id");
      }
      else if (departmentFilterSharedPlanId && !userPlanDataLoading && dialog.addTodoDialog.open) {
        const currentPlan = _.find(userPlanData.plans, (plan) => plan.sharedPlanId === departmentFilterSharedPlanId);
        newForm.plan = _.get(currentPlan, "id");
      }
      if (!userPlanDataLoading && referencedTodoData && dialog.addTodoDialog.open) {
        const ref = _.get(referencedTodoData, "todo");
        if (ref) {
          // set department based on referenceId
          const sharedPlanId = _.get(ref, "plan.sharedPlanId");
          const targetPlan = _.find(_.get(userPlanData, "plans", []), (plan) => plan.sharedPlanId === sharedPlanId);

          if (targetPlan) {
            newForm.plan = _.get(targetPlan, "id");
          }
        }
      }
      resetForm(newForm);
    }
  }, [dialog.addTodoDialog.open, departmentFilterSharedPlanId, userPlanData, referencedTodoData]);

  useEffect(() => {
    if (isTodoReference) {
      getReferencedTodo({
        variables: {
          id: dialog.addTodoDialog.referenceId,
        },
      });
    }
    else {
      getReferencedTodo({
        variables: {
          clear: true,
        },
      });  
    }
  }, [isTodoReference]);

  useEffect(() => {
    switch (form.referenceModel) {
      case "SuccessCriteria":
        setMenuItems(_.get(data, "successCriterias", []));
        break;
      case "Rock":
        setMenuItems(_.get(data, "rocks", []));
        break;

      case "Objective":
        setMenuItems(_.get(data, "objectives", []));
        break;

      case "Metric":
        const metrics = _.get(data, "metrics", []);
        const uniqueMetrics = [];
        metrics.forEach((metric) => {
          if ( (metric.status !== "complete") && (!_.find(uniqueMetrics, (m) => m.value === metric.value))) {
            uniqueMetrics.push(metric);
          }
        });
        setMenuItems(uniqueMetrics);
        break;

      case "WeeklyTarget":
        setMenuItems(_.get(data, "weeklyTargets", []));
        break;

      case "Issue":
        setMenuItems(_.get(issueData, "results.todos", []));
        break;

      case "Todo":
        setMenuItems(_.get(todosData, "results.todos", []));
        break;

      default:
        setMenuItems([]);
        break;
    }
  }, [form.referenceModel, issueData, todosData, data]);

  const combinedMenuItems =
    !_.isEmpty(dialog.addTodoDialog.referenceId) &&
    isTodoReference &&
    form.referenceModel === dialog.addTodoDialog.referenceModel &&
    menuItems &&
    menuItems.every((item) => item.id !== dialog.addTodoDialog.referenceId) &&
    !_.isNil(referencedTodoData?.todo)
      ? [referencedTodoData.todo, ...menuItems]
      : menuItems;

  const selectedItem = combinedMenuItems.find((item) => item.id === form.referenceId);

  return (
    <Dialog
      open={dialog.addTodoDialog.open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
      fullWidth
      fullScreen={fs}
    >
      <DialogTitle>
        <div className={styles.title}>
          Create New {dialog.addTodoDialog.category === "todo" ? "to do" : "issue"}
          <div>
            <IconButton onClick={handleClose} size="small">
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </div>
        </div>
      </DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          label={`${dialog.addTodoDialog.category === "todo" ? "To Do" : "Issue"} Details`}
          name="value"
          fullWidth
          variant="outlined"
          margin="normal"
          multiline
          value={form.value || ""}
          onChange={handleChange}
          helperText={formErrors.value}
          error={Boolean(formErrors.value)}
        />

        <FormControl fullWidth variant="outlined" margin="normal">
          <InputLabel>Priority</InputLabel>
          <Select value={form.priority} onChange={handleChange} name="priority" label={"Priority"}>
            {PRIORITY.map((value) => {
              return (
                <MenuItem key={value} value={value}>
                  {_.startCase(value)}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        <div className={styles.modelButtons}>
          <ToggleButtonGroup
            exclusive
            value={form.referenceModel}
            onChange={(e, value) => {
              handleChangeToggleButton("referenceModel")(e, value);
              setInputSearchTerm("");
            }}
          >
            <ToggleButton value={null}>
              <Tooltip title="No tie in">
                <Icon path={mdiLinkVariantOff} size={1.25} color="rgba(0, 0, 0, 0.54)" />
              </Tooltip>
            </ToggleButton>
            <ToggleButton value="SuccessCriteria">
              <Tooltip title="Success criteria tie in">
                <Icon path={mdiRhombusSplit} size={1.25} color="rgba(0, 0, 0, 0.54)" />
              </Tooltip>
            </ToggleButton>
            <ToggleButton value="Rock">
              <Tooltip title="Rock tie in">
                <Icon path={mdiRhombus} size={1.25} color="rgba(0, 0, 0, 0.54)" />
              </Tooltip>
            </ToggleButton>
            <ToggleButton value="Objective">
              <Tooltip title="Objective tie in">
                <Icon path={mdiChartTimelineVariant} size={1.25} color="rgba(0, 0, 0, 0.54)" />
              </Tooltip>
            </ToggleButton>
            <ToggleButton value="Metric">
              <Tooltip title="Metric tie in">
                <Icon path={mdiPoll} size={1.25} color="rgba(0, 0, 0, 0.54)" />
              </Tooltip>
            </ToggleButton>
            <ToggleButton value="WeeklyTarget">
              <Tooltip title="KPI tie in">
                <Icon path={mdiBullseyeArrow} size={1.25} color="rgba(0, 0, 0, 0.54)" />
              </Tooltip>
            </ToggleButton>
            <ToggleButton value="Issue">
              <Tooltip title="Issue tie in">
                <Icon path={mdiAlertDecagram} size={1.25} color="rgba(0, 0, 0, 0.54)" />
              </Tooltip>
            </ToggleButton>
            <ToggleButton value="Todo">
              <Tooltip title="Todo tie in">
                <Icon path={mdiCheckboxMarked} size={1.25} color="rgba(0, 0, 0, 0.54)" />
              </Tooltip>
            </ToggleButton>
          </ToggleButtonGroup>
        </div>

        {form.referenceModel && (
          <Autocomplete
            options={combinedMenuItems}
            value={selectedItem || inputSearchTerm}
            getOptionLabel={(option) => (typeof option === "string" ? option || "" : option.value || "")}
            renderOption={(option, state) => {
              const { id, value } = option;
              const plans = getPlansByReferenceModel(form.referenceModel, option);
              const uniqPlans = _.uniqBy(plans, (plan) => plan.sharedPlanId);
              return (
                <div value={id} className={styles.tieInItem}>
                  {uniqPlans.map((plan, idx) => (
                    <PlanPill plan={plan} key={idx} />
                  ))}
                  {_.isEmpty(uniqPlans) && <PlanPill plan={null} />}
                  <Typography className={styles.tieInItemText}>{value}</Typography>
                </div>
              );
            }}
            ListboxProps={{
              onScroll: (e) => loadMoreItems(e, form),
            }}
            fullWidth
            onChange={(e, newValue) => handleChangeReferenceId(newValue)}
            onInputChange={(e, newInputValue) => setInputSearchTerm(newInputValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                style={{ marginTop: "16px" }}
                variant="outlined"
                label={`Tied In ${_.startCase(form.referenceModel)}`}
              />
            )}
          />
        )}

        {/* {form.referenceModel && (
          <FormControl fullWidth variant="outlined" margin="normal">
            <InputLabel>Tied In {_.startCase(form.referenceModel)}</InputLabel>
            <Select
              value={form.referenceId}
              onChange={handleChange}
              name="referenceId"
              label={`Tied In ${_.startCase(form.referenceModel)}`}
              MenuProps={{
                PaperProps: {
                  onScroll: (e) => loadMoreItems(e, form),
                },
              }}
            >
              {!_.isEmpty(dialog.addTodoDialog.referenceId) &&
                isTodoReference &&
                form.referenceModel === dialog.addTodoDialog.referenceModel &&
                menuItems &&
                menuItems.every((item) => item.id !== dialog.addTodoDialog.referenceId) &&
                !_.isNil(referencedTodoData?.todo) && (
                  <MenuItem value={referencedTodoData.todo.id} className={styles.tieInItem}>
                    {<PlanPill plan={referencedTodoData.todo.plan} />}
                    {_.isNil(referencedTodoData.todo.plan) && <PlanPill plan={null} />}
                    <Typography className={styles.tieInItemText}>{referencedTodoData.todo.value}</Typography>
                  </MenuItem>
                )}
              {menuItems &&
                menuItems
                  .map((item) => {
                    let plans = getPlansByReferenceModel(form.referenceModel, item);
                    return { ...item, plans };
                  })
                  .filter(({ plans }) => {
                    return _.isNil(sharedPlanId)
                      ? true
                      : !_.isEmpty(plans)
                      ? plans.map((plan) => plan.sharedPlanId).includes(sharedPlanId)
                      : false;
                  })
                  .sort((a, b) => (isTodoReference ? 0 : compareDeptNames(a.plans, b.plans)))
                  .map(({ id, value, plans }) => {
                    const uniqPlans = _.uniqBy(plans, (plan) => plan.sharedPlanId);
                    return (
                      <MenuItem key={id} value={id} className={styles.tieInItem}>
                        {uniqPlans.map((plan, idx) => (
                          <PlanPill plan={plan} key={idx} />
                        ))}
                        {_.isEmpty(uniqPlans) && <PlanPill plan={null} />}
                        <Typography className={styles.tieInItemText}>{value}</Typography>
                      </MenuItem>
                    );
                  })}
            </Select>
          </FormControl>
        )} */}
        {userPlanData && (
          <SelectDepartment
            plans={_.get(userPlanData, "plans")}
            name="plan"
            handleChange={handleChange}
            value={form.plan}
            helperText={formErrors.plan}
            error={Boolean(formErrors.plan)}
            showAll
            disabled={!_.isNil(departmentFilterSharedPlanId)}
          />
        )}

        {userPlanData && isAdmin && (
          <SelectUsers
            name="user"
            users={_.get(userPlanData, "users")}
            handleChange={handleChange}
            values={form.user}
            helperText={`Who is accountable for this ${dialog.addTodoDialog.category === "todo" ? "to do" : "issue"}?`}
            plan={form.plan}
            allPlans={_.get(userPlanData, "plans")}
            error={Boolean(formErrors.user)}
            // disabled={_.isNil(form.plan)}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit(false)} color="primary" variant="outlined" disabled={loading} className={styles.button}>
          {loading ? <Loading size={24} color="#fff" /> : "Create & Add Another"}
        </Button>
        <Button onClick={handleSubmit(true)} color="primary" variant="contained" disabled={loading} className={styles.button}>
          {loading ? <Loading size={24} color="#fff" /> : "Create"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddTodoDialog;

const GET_USERS_PLANS = gql`
  query AddTodoDialog_GetUsersPlans($organization: ID!, $corpPlan: ID) {
    users(organization: $organization) {
      name {
        first
        last
      }
      profilePicture
      id
      plan {
        id
        departmentName
        sharedPlanId
      }
    }
    plans(organization: $organization, category: "1 year", oneYearCorpPlan: $corpPlan) {
      id
      departmentName
      sharedPlanId
      year
    }
  }
`;

const GET_SC_ROCKS_OBJECTIVES_METRICS = gql`
  query AddTodoDialog_GetScRocksObjectivesMetrics($organization: ID!, $sharedPlanId: ID, $searchTerm: String, $corpPlan: ID) {
    successCriterias(organization: $organization, searchTerm: $searchTerm, oneYearCorpPlan: $corpPlan) {
      id
      value
      rock {
        id
        plan {
          id
          departmentName
          color
          shortName
          sharedPlanId
        }
      }
    }

    rocks(organization: $organization, sharedPlanId: $sharedPlanId, searchTerm: $searchTerm, oneYearCorpPlan: $corpPlan) {
      id
      value
      index
      plan {
        id
        shortName
        color
        departmentName
        sharedPlanId
      }
    }

    objectives(
      organization: $organization
      sharedPlanId: $sharedPlanId
      searchTerm: $searchTerm
      corpPlan: $corpPlan
      includeThreeYear: true
    ) {
      id
      value
      plan {
        id
        year
        shortName
        color
        departmentName
        sharedPlanId
      }
    }

    metrics(
      organization: $organization
      sharedPlanId: $sharedPlanId
      searchTerm: $searchTerm
      corpPlan: $corpPlan
      includeThreeYear: true
    ) {
      id
      value
      status
      plan {
        id
        year
        shortName
        color
        departmentName
        sharedPlanId
      }
    }

    weeklyTargets(organization: $organization, sharedPlanId: $sharedPlanId, searchTerm: $searchTerm, oneYearCorpPlan: $corpPlan) {
      id: _id
      value
      plans {
        year
        shortName
        color
        departmentName
        sharedPlanId
      }
    }
  }
`;

const GET_MORE_TODOS = gql`
  query AddTodoDialog_GetMoreTodos(
    $organization: ID!
    $sharedPlanId: ID
    $cursor: String
    $limit: Int
    $searchTerm: String
    $oneYearCorpPlan: ID
  ) {
    results: moreTodos(
      organization: $organization
      category: "todo"
      cursor: $cursor
      limit: $limit
      searchTerm: $searchTerm
      sharedPlanId: $sharedPlanId
      oneYearCorpPlan: $oneYearCorpPlan
    ) {
      todos {
        id: _id
        value
        plan {
          id
          shortName
          color
          departmentName
          sharedPlanId
        }
      }
      nextCursor
    }
  }
`;

const GET_MORE_ISSUES = gql`
  query AddTodoDialog_GetMoreIssues(
    $organization: ID!
    $sharedPlanId: ID
    $cursor: String
    $limit: Int
    $searchTerm: String
    $oneYearCorpPlan: ID
  ) {
    results: moreTodos(
      organization: $organization
      category: "issue"
      cursor: $cursor
      limit: $limit
      searchTerm: $searchTerm
      sharedPlanId: $sharedPlanId
      oneYearCorpPlan: $oneYearCorpPlan
    ) {
      todos {
        id: _id
        value
        plan {
          id
          shortName
          color
          departmentName
          sharedPlanId
        }
      }
      nextCursor
    }
  }
`;

const CREATE_TODO = gql`
  mutation AddTodoDialog_CreateTodo(
    $organization: ID!
    $value: String!
    $category: String
    $user: ID!
    $author: ID!
    $referenceId: ID
    $referenceModel: String
    $priority: String
    $plan: ID
  ) {
    createTodo(
      organization: $organization
      value: $value
      category: $category
      user: $user
      author: $author
      referenceId: $referenceId
      referenceModel: $referenceModel
      priority: $priority
      plan: $plan
    )
  }
`;

const GET_REFERENCED_TODO_CREATE = gql`
  query AddTodoDialog_GetRefTodoCreate($id: ID) {
    todo(id: $id) {
      id: _id
      value
      plan {
        id
        shortName
        color
        departmentName
        sharedPlanId
      }
    }
  }
`;
