import React, { useContext, useState } from "react";
import _ from "lodash";

import { Paper, Button, Table, TableHead, TableBody, TableRow, TableCell, DialogTitle, CircularProgress } from "@material-ui/core";

import { Dialog, DialogContent, DialogContentText, DialogActions } from "@material-ui/core";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import { SnackbarContext } from "../../context/snackbarContext";

const systemTasks = [
  {
    name: "Send Weekly Reminder Emails",
    key: "sendWeeklyReminders",
    description: "Sends out progress summary for each active user",
    date: "At 00:00 on Sunday",
  },
  {
    name: "Send All To Dos Reminder Emails",
    key: "sendTodosReminders",
    description: "Schedule varies, based on User Seeting (Weekdays, Monday's, etc.)",
    date: "Varies",
  },  
];

function SystemTasksTable() {
  const { snack } = useContext(SnackbarContext);
  const [infoDialogOpen, setInfoDialogOpen] = useState(false);
  const [infoDialogTask, setInfoDialogTask] = useState({});

  const [sendWeeklyRemindersMutation, { loading: sendWeeklyRemindersMutationLoading }] = useMutation(SEND_WEEKLY_REMINDER_EMAILS);
  const [sendTodosRemindersMutation, { loading: sendTodosRemindersMutationLoading }] = useMutation(SEND_TODOS_REMINDER_EMAILS);

  const onViewMoreInfo = (task) => {
    setInfoDialogOpen(true);
    setInfoDialogTask(task);
  };

  const checkLoadingState = (task) => {
    let loading = false;
    switch (task) {
      case "sendWeeklyReminders":
        loading = sendWeeklyRemindersMutationLoading;
        break;
      case "sendTodosReminders":
          loading = sendTodosRemindersMutationLoading;
          break;        
      default:
        break;
    }
    return loading;
  };

  const onTriggerSystemTask = async () => {
    try {
      switch (infoDialogTask.key) {
        case "sendWeeklyReminders":
          const response = await sendWeeklyRemindersMutation();
          const ok = _.get(response, "data.manualSendWeeklyEmail", false);
          if (ok) {
            snack(`System task: ${infoDialogTask.name} is completed sucessfully`);
          } else {
            snack(`System task: ${infoDialogTask.name} failed due to an error`, "error");
          }
          setInfoDialogOpen(false);
          setInfoDialogTask(false);
          break;
        case "sendTodosReminders":
          const response2 = await sendTodosRemindersMutation();
          const ok2 = _.get(response2, "data.manualSendTodosEmail", false);
          if (ok2) {
            snack(`System task: ${infoDialogTask.name} is completed sucessfully`);
          } else {
            snack(`System task: ${infoDialogTask.name} failed due to an error`, "error");
          }
          setInfoDialogOpen(false);
          setInfoDialogTask(false);
          break;
        default:
          break;
      }
    } catch (e) {
      snack("something went wrong, please try again later", "error");
    }
  };

  return (
    <>
      <Paper style={{ width: "100%", overflow: "auto" }}>
        <Table padding="dense">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Manually Trigger</TableCell>
            </TableRow>
          </TableHead>
          <TableBody style={{ whiteSpace: "nowrap" }}>
            {systemTasks.map((task, index) => (
              <TableRow key={index}>
                <TableCell>{task.name}</TableCell>
                <TableCell>{task.date}</TableCell>
                <TableCell>
                  <TaskStatus status={checkLoadingState(task.key)} />
                </TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    disabled={checkLoadingState(task.key)}
                    onClick={() => onViewMoreInfo(task)}
                  >
                    {checkLoadingState(task.key) ? (
                      <>
                        <CircularProgress size={18} thickness={4.8} />
                      </>
                    ) : (
                      "Run Now"
                    )}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>

      <Dialog open={infoDialogOpen} onClose={() => setInfoDialogOpen(false)}>
        <DialogTitle>{_.get(infoDialogTask, "name", "Task")}</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ marginBottom: "32px" }}>{_.get(infoDialogTask, "description", "Task")}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button disabled={checkLoadingState(infoDialogTask.key)} onClick={onTriggerSystemTask}>
            {checkLoadingState(infoDialogTask.key) ? (
              <>
                <CircularProgress size={18} thickness={4.8} />
              </>
            ) : (
              "Run Now"
            )}
          </Button>
          <Button onClick={() => setInfoDialogOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default SystemTasksTable;

const SEND_WEEKLY_REMINDER_EMAILS = gql`
  mutation {
    manualSendWeeklyEmail
  }
`;

const SEND_TODOS_REMINDER_EMAILS = gql`
  mutation {
    manualSendTodosEmail
  }
`;


function TaskStatus(task) {
  if (task.status) return "Running...";
  return "Operational";
}
