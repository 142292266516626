//export const NOTIFICATION_FREQUENCY = ["daily","weekdays","monday","mondayAndWednesday","weekly", "monthly", "quarterly", "yearly"];
export const NOTIFICATION_FREQUENCY = ["daily","weekdays","monday","mondayAndWednesday"];
export const isProd = process.env.REACT_APP_ENV === "PROD";

// **IMPORTANT: the keys need to be the camelCase version of their respective "value" fields
// The duration values are strings because InputStep in meetingSchema has duration as String to work with MUI textfields
// (gets converted to Number when saved in DB)

export const HEADLINE_CATEGORIES = {
  general: {
    name: "General",
    value: "general",
    default: false,
    orderKey: 4,
  },
  customer: {
    name: "Customer",
    value: "customer",
    default: true,
    orderKey: 1,
  },
  staff: {
    name: "Staff",
    value: "staff",
    default: false,
    orderKey: 2,
  },
  company: {
    name: "Company",
    value: "company",
    default: false,
    orderKey: 3,
  },      
};

export const SYSTEMPROMPT_CATEGORIES = {
  icebreaker: {
    name: "Icebreaker",
    value: "icebreaker",
    default: true,
  },
  question: {
    name: "Question",
    value: "question",
    default: false,
  }, 
  quote: {
    name: "Quote",
    value: "quote",
    default: false,
  },
  fact: {
    name: "Fact",
    value: "fact",
    default: false,
  },
  general: {
    name: "General",
    value: "general",
    default: false,
  },

};

export const SYSTEMPROMPT_TYPES = {
  system: {
    name: "System",
    value: "system",
    default: true,
  },
  organizational: {
    name: "Organizational",
    value: "organizational",
    default: false,
  }, 
};

export const DEFAULT_MEETING_ACTIONS = {
  checkIn: {
    name: "Check-in",
    value: "check-in",
    duration: "5",
    steps: [],
    content: [],
  },
  headlines: {
    name: "Headlines",
    value: "headlines",
    duration: "5",
    content: [],
  },
  scorecard: {
    name: "Scorecard",
    value: "scorecard",
    duration: "5",
    content: [],
  },
  rocks: {
    name: "Rocks",
    value: "rocks",
    duration: "5",
    content: [],
  },
  textBlock: {
    name: "Text Block",
    value: "text-block",
    duration: "5",
    content: [],
  },
  toDos: {
    name: "To-Dos",
    value: "to-dos",
    duration: "5",
    content: [],
  },
  issues: {
    name: "Issues",
    value: "issues",
    duration: "60",
    content: [],
  },
  conclude: {
    name: "Conclude",
    value: "conclude",
    duration: "5",
    content: [],
  }
  /*,
  prompt: {
    name: "Question/Prompt",
    value: "prompt",
    duration: "5",
    content: [],
  }*/
};

export const REPEATABLE_MEETING_ACTIONS = ["textBlock"];
