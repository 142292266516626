import React, { useCallback, useMemo, useEffect, useState } from "react";
import isHotkey from "is-hotkey";
import { Editable, withReact, useSlate, Slate } from "slate-react";
import { withHistory } from "slate-history";
import { Editor, Transforms, createEditor, Element as SlateElement } from "slate";

import _ from "lodash";
import pipe from "lodash/fp/pipe";
import { insertLink, deserialize } from "./functions";

import { RichTextButton, RichTextIcon, RichTextMenu } from "./RichTextElements";
import { Link, Paragraph } from "./OtherElements";
import styles from "./RichText.module.scss";

const HOTKEYS = {
  "mod+b": "bold",
  "mod+i": "italic",
  "mod+u": "underline",
  "mod+`": "code",
};

const LIST_TYPES = ["numbered-list", "bulleted-list"];
const TEXT_ALIGN_TYPES = ["left", "center", "right", "justify"];

const withLinks = (editor) => {
  const { isInline } = editor;
  editor.isInline = (element) => (element.type === "link" ? true : isInline(element));
  return editor;
};

const RichText = ({ readOnly, value, onChange, allowInitialLoad = false, autoFocus = true }) => {
  const renderElement = useCallback((props) => <Element {...props} />, []);
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);

  const createEditorWithPlugins = pipe(withReact, withHistory, withLinks);
  const editor = useMemo(() => createEditorWithPlugins(createEditor()), []);

  const [isInitialLoad, setIsInitialLoad] = useState(allowInitialLoad);

  // ALTERNATIVE WAY OF INITIALIZING EDITOR
  // const editorRef = useRef();
  // if (!editorRef.current) editorRef.current = createEditorWithPlugins(createEditor());
  // const editor = editorRef.current;

  const isLinkActive = isBlockActive(editor, "link", "type");

  const handleInsertLink = () => {
    if (!isLinkActive) {
      let url;
      while (true) {
        url = prompt('Please enter a valid URL (must start with "http://" or "https://"):');
        if (!url || /^(https?:\/\/)/g.test(url)) {
          break;
        } else {
          alert("Invalid URL, please try again");
        }
      }

      insertLink(editor, url);
    }
  };

  // The Slate component's "value" prop x behaves like an initial state hence does not change when the "value" prop y of RichText changes
  // therefore in order to see any changes done to "value" prop y directly (without invoking onChange), the following needs to be done:
  useEffect(() => {
    if (value && (readOnly || isInitialLoad)) {
      // Delete all entries leaving 1 empty node
      Transforms.delete(editor, {
        at: {
          anchor: Editor.start(editor, []),
          focus: Editor.end(editor, []),
        },
      });

      // Removes empty node
      Transforms.removeNodes(editor, {
        at: [0],
      });

      // Insert array of children nodes
      Transforms.insertNodes(editor, value);

      // Mark as initialized
      const emptyValue = deserialize('');
      if (isInitialLoad && !_.isEqual(emptyValue,value)) {
        setIsInitialLoad(false);
        console.log("RichText initialized with value:", value);
      }

    }
  }, [value, readOnly]);

  return (
    <div
      className={styles.wrapper}
      style={{ border: !readOnly ? "1px solid #7e57c2" : "none", backgroundColor: readOnly ? "inherit" : "white", borderRadius: "4px" }}
    >
      <Slate editor={editor} value={value} onChange={onChange}>
        {!readOnly && (
          <RichTextMenu>
            <MarkButton format="bold" icon="format_bold" />
            <MarkButton format="italic" icon="format_italic" />
            <MarkButton format="underline" icon="format_underlined" />
            <MarkButton format="code" icon="code" />
            <BlockButton format="heading-one" icon="looks_one" />
            <BlockButton format="heading-two" icon="looks_two" />
            <BlockButton format="block-quote" icon="format_quote" />
            <BlockButton format="numbered-list" icon="format_list_numbered" />
            <BlockButton format="bulleted-list" icon="format_list_bulleted" />
            <BlockButton format="left" icon="format_align_left" />
            <BlockButton format="center" icon="format_align_center" />
            <BlockButton format="right" icon="format_align_right" />
            <BlockButton format="justify" icon="format_align_justify" />
            <OtherButton active={isLinkActive} icon="link" handleClick={handleInsertLink} />
          </RichTextMenu>
        )}
        <Editable
          className={!readOnly ? styles.edit : styles.read}
          readOnly={readOnly}
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          placeholder="Enter text…"
          spellCheck
          autoFocus={autoFocus}
          onKeyDown={(event) => {
            for (const hotkey in HOTKEYS) {
              if (isHotkey(hotkey, event)) {
                event.preventDefault();
                const mark = HOTKEYS[hotkey];
                toggleMark(editor, mark);
              }
            }
          }}
        />
      </Slate>
    </div>
  );
};

const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(editor, format, TEXT_ALIGN_TYPES.includes(format) ? "align" : "type");
  const isList = LIST_TYPES.includes(format);
  Transforms.unwrapNodes(editor, {
    match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && LIST_TYPES.includes(n.type) && !TEXT_ALIGN_TYPES.includes(format),
    split: true,
  });
  let newProperties;
  if (TEXT_ALIGN_TYPES.includes(format)) {
    newProperties = {
      align: isActive ? undefined : format,
    };
  } else {
    newProperties = {
      type: isActive ? "paragraph" : isList ? "list-item" : format,
    };
  }
  Transforms.setNodes(editor, newProperties);
  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format);
  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

const isBlockActive = (editor, format, blockType = "type") => {
  const { selection } = editor;
  if (!selection) return false;
  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n[blockType] === format,
    })
  );
  return !!match;
};

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

const Element = (props) => {
  const { attributes, children, element } = props;

  const style = { textAlign: element.align };
  switch (element.type) {
    case "block-quote":
      return (
        <blockquote style={style} {...attributes}>
          {children}
        </blockquote>
      );
    case "bulleted-list":
      return (
        <ul style={style} {...attributes}>
          {children}
        </ul>
      );
    case "heading-one":
      return (
        <h1 style={style} {...attributes}>
          {children}
        </h1>
      );
    case "heading-two":
      return (
        <h2 style={style} {...attributes}>
          {children}
        </h2>
      );
    case "list-item":
      return (
        <li style={style} {...attributes}>
          {children}
        </li>
      );
    case "numbered-list":
      return (
        <ol style={style} {...attributes}>
          {children}
        </ol>
      );
    case "link":
      return <Link {...props} />;
    default:
      return <Paragraph style={style} {...props} />;
    // default:
    //   return (
    //     <p style={style} {...attributes}>
    //       {children}
    //     </p>
    //   );
  }
};

const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    children = <code>{children}</code>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  return <span {...attributes}>{children}</span>;
};

const BlockButton = ({ format, icon }) => {
  const editor = useSlate();
  return (
    <RichTextButton
      active={isBlockActive(editor, format, TEXT_ALIGN_TYPES.includes(format) ? "align" : "type")}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
    >
      <RichTextIcon>{icon}</RichTextIcon>
    </RichTextButton>
  );
};

const MarkButton = ({ format, icon }) => {
  const editor = useSlate();
  return (
    <RichTextButton
      active={isMarkActive(editor, format)}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
    >
      <RichTextIcon fontSize="small">{icon}</RichTextIcon>
    </RichTextButton>
  );
};

const OtherButton = ({ active, icon, handleClick }) => {
  return (
    <RichTextButton
      active={active}
      onMouseDown={(event) => {
        event.preventDefault();
        handleClick();
      }}
    >
      <RichTextIcon fontSize="small">{icon}</RichTextIcon>
    </RichTextButton>
  );
};

export default RichText;
