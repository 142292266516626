import React from "react";
import { NumericFormat } from "react-number-format";

const NumberFormatCustom = (props) => {
  const { inputRef, onChange, prefix, suffix, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      valueIsNumericString
      prefix={prefix}
      suffix={suffix}
    />
  );
};

export const NumberZeroToTenFormatCustom = (props) => {
  const { inputRef, onChange, prefix, suffix, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={inputRef}
      decimalScale={2}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      valueIsNumericString
      prefix={prefix}
      suffix={suffix}
      isAllowed={(values) => {
        // Prevent user from even typing out-of-range values
        const { floatValue } = values;
        return floatValue === undefined || (floatValue >= 0 && floatValue <= 10);
      }}      
    />
  );
};
export default NumberFormatCustom;
