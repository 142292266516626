import React, { useState, useEffect, useContext } from "react";
import styles from "./Notes.module.scss";
import Icon from "@mdi/react";
import { mdiNote } from "@mdi/js";
import { IconButton } from "@material-ui/core";
import { blue } from "@material-ui/core/colors";
import { NotesContext } from "../../context/notesContext";
import { getNestedNotes } from "../../utils/graphql";
import _ from "lodash";

const NotesButton = ({
  id,
  size = 0.85,
  core,
  model,
  color = "rgba(0, 0, 0, 0.54)",
  tabs = ["notes"],
  value,
  user,
  doc,
  canEditTodo = false,
  additionalProps,
  planId,
  disabled,
  addWarning,
}) => {
  const { notes, refetchId, setRefetchId, setTabs, setDisableEditTodo } = useContext(NotesContext);
  const [numOfNotes, setNumOfNotes] = useState(0);

  const handleClick = (e, enabledTabs) => {
    if (addWarning) {
      let pass = window.confirm("There are unsaved changes and will be lost upon editing notes. Continue?");
      if (!pass) return;
    }
    e.stopPropagation();
    setTabs(enabledTabs);
    setDisableEditTodo(!canEditTodo);
    handleNotes();
  };

  const handleNotes = () => {
    if (core) {
      notes(id, model, core, false);
    } else if (model) {
      notes(id, model, undefined, false, true, value, user, additionalProps, planId);
    }
  };

  const getNum = () => {
    const num = _.get(doc, "notes.length", 0) + _.get(doc, "todos.length", 0);
    setNumOfNotes(num);
  };

  const getNestedNum = async () => {
    const res = await getNestedNotes(id, model, core);

    if (res.data[model]) setNumOfNotes(res.data[model].notes[core].length);
  };

  useEffect(() => {
    // if (id && core) {
    //   getNestedNum();
    // } else if (id && model) {
    getNum();
    // }
  }, [doc]);

  // useEffect(() => {
  //   if (refetchId && model && refetchId === id) {
  //     getNum();
  //   } else if (refetchId && refetchId === core) {
  //     // getNestedNum();
  //     getNum();
  //   }
  //   setRefetchId();
  // }, [refetchId]);

  return (
    <div className={styles.buttonWrapper}>
      <IconButton size="small" onClick={(e) => handleClick(e, tabs)} disabled={disabled} className={disabled ? styles.disabled : undefined}>
        <Icon path={mdiNote} size={size} color={numOfNotes > 0 ? blue[500] : color} />
      </IconButton>
      <span className={styles.buttonNumber}>{numOfNotes}</span>
    </div>
  );
};

export default NotesButton;
