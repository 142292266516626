import React from "react";
import styles from "../Meetings.module.scss";
import { Button, Container, IconButton, Grid, Typography, Box } from "@material-ui/core";
import Icon from "@mdi/react";
import MeetingStepContent from "../../../components/MeetingStepContent/MeetingStepContent";
import useMobileMenu from "../../../hooks/useMobileMenu";


import { mdiPlus } from "@mdi/js";

const MeetingCheckInScreen = ({ step, canEdit, handleAddPromptStepContent }) => {
  const { isMobile } = useMobileMenu();

  return (

    <Container maxWidth={false}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <div className={styles.flexSpaceBetween}>
            <div>
              <Grid item xs={12}>
                <Typography variant="h5">
                  <span className={styles.stepLabel}>{step?.name ? step.name : 'Check-in'}</span>
                </Typography>
              </Grid>
            </div>

            {isMobile ? (
              <IconButton onClick={handleAddPromptStepContent} className={styles.menuButtonColor} color="primary" disabled={!canEdit}>
                <Icon path={mdiPlus} size={0.75} color="#fff" />
              </IconButton>
            ) : (
              <Button
                startIcon={<Icon path={mdiPlus} size={1} color="#fff" />}
                className={styles.menuButtonColor}
                onClick={handleAddPromptStepContent}
                variant="contained"
                color="primary"
                disabled={!canEdit}
              >
                Update Ice-breakers
              </Button>
            )}
          </div>
        </Grid>
        <Grid item xs={12}>
          {step?.content && (step.content.length > 0 || step.content.label) ? (
            <MeetingStepContent step={step} />
          ) : (
            <>
              <Box mb={2}>

                <Typography variant="h6" gutterBottom>
                  Welcome to the meeting
                </Typography>

                <Box ml={2}>
                  <Typography variant="body1">
                    What is one piece of personal and business good news you can share?
                  </Typography>
                </Box>
              </Box>  
              { canEdit && (
                <Box style={{marginTop: '50px'}} mb={2}>
                  <Grid container item xs={12} justifyContent="center">
                    <Typography style={{fontSize: 'smaller'}} paragraph>
                      <b>Facilitator Note:</b> A default ice-breaker is displayed.<br />
                      Click the button below to add your own ice-breakers.
                    </Typography>
                  </Grid>

                  <Grid container item xs={12} justifyContent="center">
                    <Button variant="outlined" color="primary" size="small" onClick={handleAddPromptStepContent}>
                      Update Ice-breakers
                    </Button>  
                   </Grid>                      
               </Box>
              )}          

            </>
          )}  
        </Grid>
   
      </Grid>
    </Container>

  );
};

export default MeetingCheckInScreen;
