import React from "react";

import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";

const SelectQuarter = ({ name, value, handleChange, helperText }) => {
  return (
    <FormControl fullWidth variant="outlined" margin="none">
      <InputLabel>Quarter</InputLabel>
      <Select name={name} value={value} onChange={handleChange} label="Quarter">
        <MenuItem value={1}>Quarter 1</MenuItem>
        <MenuItem value={2}>Quarter 2</MenuItem>
        <MenuItem value={3}>Quarter 3</MenuItem>
        <MenuItem value={4}>Quarter 4</MenuItem>
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};

export default SelectQuarter;
