import _ from "lodash";
import React, { createContext, useState } from "react";

export const NotesContext = createContext();

export const NotesContextProvider = ({ children }) => {
  const [data, setData] = useState({ id: null, model: null, core: null, openInEditMode: false, openNotesDialog: false, value: null, user: null, additionalProps: {}, planId: null });
  const [refetchId, setRefetchId] = useState(); // no longer used
  const [tabs, setTabs] = useState(["notes"]);
  const [disableEditTodo, setDisableEditTodo] = useState(true);

  // additionalProps can only include variables to the query
  const notes = (id, model, core = null, openInEditMode = false, openNotesDialog = true, value = null, user = null, additionalProps = {}, planId = null) => {
    setData({ id, model, core, openInEditMode, openNotesDialog, value, user, additionalProps, planId });
  };

  // for handling sub array notes
  const onCreateNote = () => {
    setData({ ...data, additionalProps: { ...data.additionalProps, new: false } });
  };

  return (
    <NotesContext.Provider
      value={{ data, notes, refetchId, setRefetchId, tabs, setTabs, disableEditTodo, setDisableEditTodo, onCreateNote }}
    >
      {children}
    </NotesContext.Provider>
  );
};
