import { IconButton, Menu, useMediaQuery, useTheme } from "@material-ui/core";
import { mdiFilterVariant } from "@mdi/js";
import Icon from "@mdi/react";
import React, { useState } from "react";

const useMobileMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleKeyDown = (event) => {
    if (event.key === 's' && event.target.tagName === 'INPUT') {
      event.stopPropagation();
    }
  };

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const renderMobileMenu = (children) => {
    if (isMobile) {
      return (
        <>
          <IconButton onClick={handleOpenMenu}>
            <Icon path={mdiFilterVariant} size={1} />
          </IconButton>
          <Menu open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={handleCloseMenu}>
            <div style={{ padding: "16px", display: "flex", flexDirection: "column" }} onKeyDown={handleKeyDown}>{children}</div>
          </Menu>
        </>
      );
    } else {
      return children;
    }
  };

  return {
    isMobile,
    renderMobileMenu,
  };
};

export default useMobileMenu;
