import { gql, useQuery } from "@apollo/client";
import React, { useEffect, useContext } from "react";
import { TODO_FIELDS } from "../../../utils/fragments";
import Loading from "../../../components/Loading/Loading";
import useCorpPlan from "../../../hooks/useCorpPlan";
import _ from "lodash";
import TodosComponent from "../../../components/Todos/Todos";
import useTableFilters from "../../../hooks/useTableFilters";
import { FetchContext } from "../../../context/fetchContext";

const TodoStep = ({ step, userIds, org, canEdit, category, corpPlanId, sharedPlanId, filterOnSelected, handleSetStepFilters, stepFilters, isLeading }) => {
  const { fetch, requestFetch } = useContext(FetchContext);

  const location = category === "issue" ? "issuesPage" : "todosPage";

  const {
    page,
    rowsPerPage,
    sort,
    searchTerm,
    debouncedSearchTerm,
    showCompleted,
    handleChangePage,
    handleRowsPerPage,
    setSort,
    setSearchTerm,
    setShowCompleted,
    setRowsPerPage,
    setPage,    
  } = useTableFilters({
    location,
    initialValue: {
      rowsPerPage: JSON.parse(localStorage.getItem(`${location}.rowsPerPage`)) || 10,
      showCompleted: JSON.parse(localStorage.getItem(`${location}.showCompleted`)) || false,
      sortValue: JSON.parse(localStorage.getItem(`${location}.sortValue`)) || "priorityValue",
      sortOrder: JSON.parse(localStorage.getItem(`${location}.sortOrder`)) || "asc",
    },
    handleSetStepFilters,
    stepFilters,
    isLeading
  });
  
  useEffect(() => {
    if (isLeading && _.isNil(stepFilters)) {
        handleSetStepFilters(
          {
            page: 0,
            rowsPerPage: JSON.parse(localStorage.getItem(`${location}.rowsPerPage`)) || 10,
            showCompleted: JSON.parse(localStorage.getItem(`${location}.showCompleted`)) || false,
            sortValue: JSON.parse(localStorage.getItem(`${location}.sortValue`)) || "priorityValue",
            sortOrder: JSON.parse(localStorage.getItem(`${location}.sortOrder`)) || "asc",
            searchTerm: '',
          }
        );
    }
    else {
      if (!_.isNil(stepFilters)) {
        if (sort.value !== stepFilters.sortValue || sort.order !== stepFilters.sortOrder) {
          setSort({ value: stepFilters.sortValue, order: stepFilters.sortOrder });
        }
        if (searchTerm !== stepFilters.searchTerm) {
          setSearchTerm(stepFilters.searchTerm);
        }
        if (showCompleted !== stepFilters.showCompleted) {
          setShowCompleted(stepFilters.showCompleted);
        }
        if (rowsPerPage !== stepFilters.rowsPerPage) {
          setRowsPerPage(stepFilters.rowsPerPage);
        }
        if (page !== stepFilters.page) {
          setPage(stepFilters.page);
        }
      }
    }
  }, [stepFilters]);

  const doneValue = showCompleted === true ? null : false;

  const variables = { 
    users: userIds, 
    organization: org.id, 
    category, 
    sharedPlanId: sharedPlanId, 
    oneYearCorpPlan: corpPlanId,
    page,
    rowsPerPage,
    searchTerm: debouncedSearchTerm,
    sortBy: sort.value,
    sortDir: sort.order,
    done: doneValue,  
};


  const { subscribeToMore, data, refetch, loading } = useQuery(TODO_STEP_QUERY, { variables , fetchPolicy: "cache-and-network",});
  
  useEffect(() => {
    refetch();
  }, [fetch]);

  useEffect(
    () => {
      if (loading) {
        return;
      }

      const unsubscribe = subscribeToMore({
        document: TODO_STEP_SUBSCRIPTION,
        variables: { users: userIds, category, corpPlanId, sharedPlanId },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev;
          const todo = subscriptionData.data.todoPayload.todoMutated;
          const action = subscriptionData.data.todoPayload.action;

          // Initialize prev.todos if it's null, undefined, or empty
          const currentTodos = prev?.todos || [];

          let newTodos;
          let exists;
          switch (action) {
            case "update":
              exists = currentTodos.some((t) => t.id === todo.id);
              newTodos = exists ? currentTodos.map((t) => (t.id === todo.id ? todo : t)) : [...currentTodos, todo];
              requestFetch(); // done to ensure that hide complete filter is applied in case update is made to the status         
              break;
            case "delete":
              newTodos = currentTodos.filter((t) => t.id !== todo.id);
              break;
            case "create":
              exists = currentTodos.some((t) => t.id === todo.id);
              newTodos = exists ? currentTodos : [...currentTodos, todo];                    
              break;
            default:
              break;
          }

          return Object.assign({}, prev, {
            todos: newTodos,
          });
        },
      });
      return () => {
        unsubscribe();
      };
    },
    [loading]
  );

  if (loading) return <Loading />;

  const content = _.get(step, ["content",0,"referenceIds"], []).map((refId) => refId.id);
  const todos = filterOnSelected ? _.get(data,"todos",[]).filter((todo) => content.includes(todo.id)) : _.get(data,"todos",[]);
  const todosCount = _.get(data,"todosCount",{});

  return (
    <div>
      {/*
      <TodosComponent todos={todos} issues={category === "issue"} showAccountable planId={corpForSelectedYear.id} canEdit={canEdit} sort={sort} rowsPerPage={rowsPerPage}/>
      */}
      <TodosComponent
            todos={todos}
            issues={category === "issue"}
            showAccountable
            handleChangePage={handleChangePage}
            handleRowsPerPage={handleRowsPerPage}
            page={page}
            rowsPerPage={rowsPerPage}
            sort={sort}
            searchTerm={searchTerm}
            setSort={setSort}
            setSearchTerm={setSearchTerm}
            setShowCompleted={setShowCompleted}
            showCompleted={showCompleted}
            location={location}
            total={_.get(data,"todosCount.total",0)}
            totalCompleted={_.get(data,"todosCount.totalCompleted",0)}
            planId={corpPlanId}
            canEdit={canEdit}
          />  
    </div>
  );
};

export default TodoStep;


const TODO_STEP_QUERY = gql`
  ${TODO_FIELDS}
  query TodoStepQuery(
    $organization: ID! 
    $users: [ID!] 
    $category: String
    $sharedPlanId: ID
    $oneYearCorpPlan: ID
    $page: Int
    $rowsPerPage: Int
    $searchTerm: String
    $sortBy: String
    $sortDir: String
    $done: Boolean    
  ) {
    todos(
      organization: $organization
      users: $users
      category: $category
      sharedPlanId: $sharedPlanId
      oneYearCorpPlan: $oneYearCorpPlan
      page: $page
      rowsPerPage: $rowsPerPage
      searchTerm: $searchTerm
      sortBy: $sortBy
      sortDir: $sortDir
      done: $done 
    ) {
      ...TodoFields
    }

    todosCount(
      organization: $organization
      users: $users 
      category: $category
      sharedPlanId: $sharedPlanId
      oneYearCorpPlan: $oneYearCorpPlan
      searchTerm: $searchTerm      
    ) {
      total
      totalCompleted
    }    
  }
`;

const TODO_STEP_SUBSCRIPTION = gql`
  ${TODO_FIELDS}
  subscription OnTodoMutation($users: [ID!], $category: String, $corpPlanId: ID!, $sharedPlanId: ID!) {
    todoPayload(users: $users, category: $category, corpPlanId: $corpPlanId, sharedPlanId: $sharedPlanId) {
      todoMutated {
        ...TodoFields
      }
      action
    }
  }
`;
