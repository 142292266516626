import { useContext } from "react";

import _ from "lodash";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";

import { MEETING_FIELDS } from "../utils/fragments";
import { SnackbarContext } from "../context/snackbarContext";

const useAddToMeeting = () => {

  const [addItemToMeeting, { loading: loadingItemToMeeting }] = useMutation(ADD_ITEM_TO_MEETING);
  const [removeItemFromMeeting, { loading: loadingRemoveItemFromMeeting }] = useMutation(REMOVE_ITEM_FROM_MEETING);
  const [updateDoMeetingSteps, { loading: updateLoading }] = useMutation(UPDATE_MEETING_STEPS);

  const { snack } = useContext(SnackbarContext);

  const getItemStepIndices = (meeting, referenceModel) => {
    let stepIndex = null;
    let contentIndex = null;

    let contentType = null;
    let stepValue = null;

    switch (referenceModel) {
      case 'SystemPrompt':
        contentType = 'prompt';
        stepValue = 'check-in';
        break;      
      case 'Rock':
        contentType = 'rockReview';
        stepValue = 'rocks';
        break;
      case 'Headline':
        contentType = 'headlineReview';
        stepValue = 'headlines';
        break;
      case 'Issue':
        contentType = 'issueReview';
        stepValue = 'issues';
        break;
      case 'Todo':
        contentType = 'todoReview';
        stepValue = 'to-dos';
        break;
      case 'WeeklyTarget':
        contentType = 'weeklyTargetReview';
        stepValue = 'scorecard';
        break;
      default:
        contentType = 'textBlock';
        stepValue = 'text-block';
        break;
    }

    const currentItemSteps = _.get(meeting, "steps", []).filter((step) =>
      _.get(step, "content", []).some((content) => content.type === contentType) || step.value === stepValue
    );

    if (currentItemSteps.length) {
      const firstItemStep = currentItemSteps[0];

      const rawStepIndex = _.findIndex(meeting.steps, (step) => step === firstItemStep);
      stepIndex = rawStepIndex === -1 ? null : rawStepIndex;

      if (!_.isNil(stepIndex)) {
          const rawContentIndex = _.findIndex(firstItemStep.content, (content) => content.type === contentType);
          contentIndex = rawContentIndex === -1 ? null : rawContentIndex;
      }
    } 
    
    return {stepIndex, stepValue, contentIndex, contentType};
  }

  const addToMeeting = async (meeting, referenceId, referenceModel) =>  {
    if (meeting && referenceId && referenceModel) {
      const {stepIndex, contentIndex, contentType } = getItemStepIndices(meeting, referenceModel);
      
      if ( meeting.id && stepIndex !== null) {
        const { data: dataUpdatedMeeting } = await addItemToMeeting({
          variables: { id: meeting.id, referenceId: referenceId, stepIndex: stepIndex, contentIndex: contentIndex, type: contentType},
        });

        if (_.get(dataUpdatedMeeting,'addItemToMeeting',null)) {
          return true;
        } 
      } 
    }

    return false;
  };  

  const removeFromMeeting = async (meeting, referenceId) =>  {
    if (meeting && referenceId) {
      if ( meeting.id ) {
        const { data: dataUpdatedMeeting } = await removeItemFromMeeting({
          variables: { id: meeting.id, referenceId: referenceId},
        });

        if (_.get(dataUpdatedMeeting,'removeItemFromMeeting',null)) {
          return true;
        } 
      } 
    }

    return false;
  };

  const updateMeetingSteps = async (meeting, steps) => {
    if (meeting && steps) {
      const { data: dataUpdatedMeeting } = await updateDoMeetingSteps({
        variables: { id: meeting.id, steps: steps},
      });

      if (_.get(dataUpdatedMeeting,'updateMeeting',null)) {
        return true;
      } 
    }

    return false;
  };

  const handleAddToCurrentMeeting = (meeting, referenceId, referenceModel) => async () => {
    if (await addToMeeting(meeting, referenceId, referenceModel)) {
      snack("Added successfully to the meeting");
    }
    else {
      snack("Unable to add to the meeting", "error");
    }
  };

  const handleRemoveFromCurrentMeeting = (meeting, referenceId) => async () => {
    if (await removeFromMeeting(meeting, referenceId)) {
      snack("Removed successfully from the meeting");
    }
    else {
      snack("Unable to remove from the meeting", "error");
    }
  };  

  return {handleAddToCurrentMeeting, handleRemoveFromCurrentMeeting, addToMeeting, removeFromMeeting, getItemStepIndices, updateMeetingSteps };
}

export default useAddToMeeting;


const ADD_ITEM_TO_MEETING = gql`
${MEETING_FIELDS}
  mutation AddToMeetingHook_addItemToMeeting($id: ID!, $referenceId: ID!, $stepIndex: Int!, $contentIndex: Int, $type: String!) {
    addItemToMeeting(id: $id, referenceId: $referenceId, stepIndex: $stepIndex, contentIndex: $contentIndex, type: $type) {
      ...MeetingFields
    }
  }
`;

const REMOVE_ITEM_FROM_MEETING = gql`
${MEETING_FIELDS}
  mutation AddToMeetingHook_removeItemFromMeeting($id: ID!, $referenceId: ID!) {
    removeItemFromMeeting(id: $id, referenceId: $referenceId) {
      ...MeetingFields
    }
  }
`;

const UPDATE_MEETING_STEPS = gql`
  ${MEETING_FIELDS}
  mutation AddToMeetingHook_UpdateMeetingSteps($id: ID!, $steps: [InputStep!]) {
    updateMeeting(id: $id, steps: $steps) {
      ...MeetingFields
    }
  }
`;
