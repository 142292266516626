import React, { useEffect, useState } from "react";
import _ from "lodash";
import useForm from "../../hooks/useForm";
import styles from "./MeetingStepContent.module.scss";
import SelectSystemPrompt from "../SelectSystemPrompt/SelectSystemPrompt";
import ContentItemDialog from "./ContentItemDialog";

import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import { Icon as MdiIcon } from "@mdi/react";

import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Icon,
  Divider,
  IconButton,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  Tooltip,
  DialogTitle,
  Typography,
} from "@material-ui/core";

import {
  mdiRhombus,
  mdiRhombusSplit,
  mdiPoll,
  mdiText,
  mdiDrag,
  mdiBullhorn,
  mdiLightbulbQuestion,
  mdiBullseyeArrow,
  mdiAlertDecagram,
  mdiCheckboxMarked,
  mdiChartTimelineVariant,
} from "@mdi/js";

function MeetingStepContentList({ stepContent = [], lockContentTypes = [], stepDescription, onChange, error, handleAddIssueDialog, handleToBeDeleted, org, planId, sharedPlanId }) {
  const [formOpen, setFormOpen] = useState(false);
  const [indexToEdit, setIndexToEdit] = useState(null);
  const [stepContentList, setStepContentList] = useState(stepContent);

  const handleRemove = (i) => {
    let newMSs = _.cloneDeep(stepContentList);
    const [removedMS] = newMSs.splice(i, 1);
    if (handleToBeDeleted && _.get(removedMS, "id")) {
      handleToBeDeleted(removedMS);
    }

    setStepContentList(newMSs);
    onChange({ name: "content", value: newMSs });
  };

  const handleAddEdit = (values) => {
    let newMSs = _.cloneDeep(stepContentList);
    if (_.isNil(indexToEdit)) {
      newMSs.push(values);
    } else {
      let msToUpdate = newMSs[indexToEdit];
      _.set(newMSs, [indexToEdit], { ...msToUpdate, ...values });
    }
    setStepContentList(newMSs);
    onChange({ name: "content", value: newMSs });
    setFormOpen(false);
  };

  const handleEditOpen = (index) => {
    setFormOpen(true);
    setIndexToEdit(index);
  };

  const handleCreateOpen = (index) => {
    setFormOpen(true);
    setIndexToEdit(null);
  };

  const handleDragEnd = ({ draggableId, destination, source }) => {
    if (_.isEqual(source, destination)) return;

    let newMSs = _.cloneDeep(stepContentList);

    const [srcItem] = newMSs.splice(source.index, 1);
    newMSs.splice(destination.index, 0, srcItem);

    setStepContentList(newMSs);
    onChange({ name: "content", value: newMSs });
  };


  const getTextPreview = (text, maxLength = 20) => {
    let preview = '';

    if (!text) {
      return preview;
    } 
    
    const nodes = JSON.parse(text);
  
    const traverseNodes = (nodes) => {
      for (const node of nodes) {
        if (node.text) {
          preview += node.text;
          preview += ' ';
          if (preview.length >= maxLength) {
            preview = preview.slice(0, maxLength);
            return;
          }
        } else if (node.children) {
          traverseNodes(node.children);
        }
      }
    };
  
    traverseNodes(nodes);
    return preview;
  };

  const getContentLabel = (item) => {
    let contentLabel = "";
    let itemDescription = "Items";
    const items = _.get(item, 'referenceIds', []);
    const itemsCount = items.length;
    let itemIcon = mdiText;

    

    if (item.type === "prompt") {
      itemIcon = mdiLightbulbQuestion;
      contentLabel = `${_.get(item, ['referenceIds', 0, 'value'], "Prompt/Question")}${itemsCount > 1 ? `(and ${itemsCount-1} more Prompt${itemsCount-1 === 1 ? '' : 's'})` : ''}`;
    }
    else if (item.type === "textBlock") {
      itemIcon = mdiText;   
      const textPreview = getTextPreview(item.text, 20);

      if (textPreview.length > 0) {
        contentLabel = "Preview: " + _.trim(textPreview) + "...";
      }
      else {
        contentLabel = "No Text has been added yet.";
      }   
    }
    else {
      switch (item.type) {
        case "issueReview":
          itemDescription = `Issue${itemsCount === 1 ? '' : 's'}`;
          itemIcon = mdiAlertDecagram;
          break;
        case "todoReview":
          itemDescription = `To Do${itemsCount === 1 ? '' : 's'}`;
          itemIcon = mdiCheckboxMarked;
          break;
        case "rockReview":
          itemDescription = `Rock${itemsCount === 1 ? '' : 's'}`;
          itemIcon = mdiRhombus;
          break;
        case `weeklyTargetReview`:
          itemDescription = `KPI${itemsCount === 1 ? '' : 's'}`;
          itemIcon = mdiBullseyeArrow;
          break;
        case "successCriteriaReview":
          if (itemsCount === 1) {
            itemDescription = "Success Criterion";
          }
          else {
            itemDescription = "Success Criteria";
          }
          itemIcon = mdiRhombusSplit;
          break;
        case "objectiveReview":
          itemDescription = `Objective${itemsCount === 1 ? '' : 's'}`;
          itemIcon = mdiChartTimelineVariant;
          break;
        case "metricReview":
          itemDescription = `Metric${itemsCount === 1 ? '' : 's'}`;
          itemIcon = mdiPoll;
          break;
        case "headlineReview":
          itemDescription = `Headline${itemsCount === 1 ? '' : 's'}`;
          itemIcon = mdiBullhorn;
          break;
        default:
          itemDescription = `Item${itemsCount === 1 ? '' : 's'}`;
          itemIcon = mdiText;
          break;
      }

      contentLabel = `${itemsCount} ${itemDescription} Selected`;
    }

    return contentLabel;
  }

  return (
    <FormControl fullWidth variant="outlined" margin="normal" error={error} className={styles.msContainer}>
      <div className={styles.inputLabel}>{`${stepDescription}`} Step Content</div>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="MSF" type="stepContentForm">
          {(provided, snapshot) => (
            <List {...provided.droppableProps} ref={provided.innerRef}>
              {_.isEmpty(stepContentList) ? (
                <ListItem className={styles.msListItem}>
                  <ListItemText>No step content set at the moment</ListItemText>
                </ListItem>
              ) : (
                stepContentList.map((contentItem, index) => {
                  const itemLabel = getContentLabel(contentItem);
                  return (
                    <Draggable key={`MSKey_${index}`} draggableId={`MSF_${index}`} index={index}>
                      {(provided, snapshot) => (
                        <div ref={provided.innerRef} {...provided.draggableProps} style={{ ...provided.draggableProps.style }}>
                          <ListItem className={styles.msListItem}>
                            <ListItemIcon className={styles.listIcon}>
                              <div {...provided.dragHandleProps}>
                                <MdiIcon path={mdiDrag} size={1} />
                              </div>
                            </ListItemIcon>
                            <ListItemText style={{ maxWidth: "80%" }}>
                              <div>
                                {itemLabel}
                              </div>
                              {/* <div className={before(targetDate, startOfToday()) ? styles.targetDatePast : styles.targetDate}>
                                  {targetDate && `(${formatAs(targetDate, "MMM dd, yyyy")})`}
                                </div>*/}
                            </ListItemText>
                            <ListItemSecondaryAction>
                              <IconButton onClick={() => handleEditOpen(index)}>
                                <Icon>edit</Icon>
                              </IconButton>
                              <IconButton onClick={() => handleRemove(index)}>
                                <Icon>delete</Icon>
                              </IconButton>
                            </ListItemSecondaryAction>
                          </ListItem>
                          <Divider />
                        </div>
                      )}
                    </Draggable>
                  );
                })
              )}
              {provided.placeholder}
              { stepContentList.length === 0 && 
                <Button variant="outlined" color="primary" style={{ width: "100%", marginTop: 8 }} onClick={handleCreateOpen}>
                  <Icon>add_circle</Icon> Add
                </Button>
              }
            </List>
          )}
        </Droppable>
      </DragDropContext>
      {formOpen && (
        <ContentItemDialog
          lockContentTypes={lockContentTypes}
          stepDescription={stepDescription} 
          contentItem={_.get(stepContent, `[${indexToEdit}]`, null)}
          open={formOpen}
          onClose={() => setFormOpen(false)}
          onSubmit={handleAddEdit}
          org={org}
          planId={planId}
          sharedPlanId={sharedPlanId}
          multiple
        />
      )}
    </FormControl>
  );
}


export default MeetingStepContentList;
