import React, { useEffect, useRef } from "react";
import DOMPurify from 'dompurify';

import styles from "../Meetings.module.scss";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";

const MeetingEndScreen = ({ org, minutes }) => {
  const history = useHistory();
  const handleClick = () => {
    history.push(`/${org.id}/meetings`);
  };

  const iframeRef = useRef(null);

  const sanitizedMinutes = DOMPurify.sanitize(minutes, {
    ALLOWED_TAGS: ['style'],
  });

  useEffect(() => {
    /*if (iframeRef.current) {
      const doc = iframeRef.current.contentDocument || iframeRef.current.contentWindow.document;
      doc.open();
      doc.write(minutes);
      doc.close();
    }*/

      const adjustIframeHeight = () => {
        if (iframeRef.current) {
          const iframe = iframeRef.current;
          const doc = iframe.contentDocument || iframe.contentWindow.document;
  
          if (doc) {
            const setHeight = doc.body.scrollHeight + 500;
            iframe.style.height = `${setHeight}px`;
          }
        }
      };
  
      if (iframeRef.current) {
        const iframe = iframeRef.current;
        const doc = iframe.contentDocument || iframe.contentWindow.document;
  
        if (doc) {
          doc.open();
          doc.write(minutes);
          doc.close();
  
          // Adjust iframe height after content is loaded
          adjustIframeHeight();
  
          // Optional: Add resize observer for dynamic content updates
          const observer = new MutationObserver(adjustIframeHeight);
          observer.observe(doc.body, { childList: true, subtree: true });
  
          return () => {
            observer.disconnect();
          };
        }
      }

  }, [minutes]);

  return (
    <>
      <div className={styles.notificationBanner}>
        <p style={{ fontSize: 24, fontWeight: "bold" }}>The meeting has ended.</p>
        <p>
          <Button variant="outlined" color="primary" onClick={handleClick}>
            Return to Select Meeting Page
          </Button>
        </p>
      </div>
      {minutes ? ( 
        <div style={{ display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
          <iframe ref={iframeRef} title="Meeting Minutes" style={{ flexGrow: 1, border: 'none', overflow: 'hidden' }} />
        </div>  
      ) : (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 400 }}>
          <p style={{ fontSize: 24 }}>No minutes are available for this meeting.</p>
        </div>
      )}  
    </>
  );
};

export default MeetingEndScreen;
