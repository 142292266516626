import React from "react";
import _ from "lodash";
import { FormControl, FormHelperText, InputLabel, Select, MenuItem } from "@material-ui/core";

const SelectDepartment = ({ plans = [], name, value, handleChange, helperText, error, showAll, multiple, disabled = false, selectType="id" }) => {
  return (
    <FormControl fullWidth variant="outlined" margin="normal" error={error} data-test='formcontrol-select-departments'>
      <InputLabel>Department</InputLabel>
      <Select
        name={name}
        value={value || ""}
        multiple={multiple}
        onChange={handleChange}
        label="Department"
        error={error}
        disabled={disabled}
        data-test='select-departments'
      >
        {showAll && <MenuItem value={null} data-test='select-item-departments'>No Department</MenuItem>}
        {!_.isEmpty(plans) &&
          plans.map(({ id, departmentName, sharedPlanId }) => (
            <MenuItem key={selectType === 'id' ? id : sharedPlanId} value={selectType === 'id' ? id : sharedPlanId} data-test='select-item-departments'>
              {departmentName}
            </MenuItem>
          ))}
      </Select>
      <FormHelperText error={error}>{helperText}</FormHelperText>
    </FormControl>
  );
};

export default SelectDepartment;
