import React from "react";
import _ from "lodash";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Container } from "@material-ui/core";
import styles from "./Meetings.module.scss";

export default function ConfirmFinishMeetingDialog({
  canEndMeeting = false,
  confirmOpen,
  meetingAlreadyEnded,
  handleConfirmOpen,
  handleFinishAndLeaveMeeting,
  handleConfirmLeaveChoice,
}) {

  return (
    <Dialog
      open={confirmOpen}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleConfirmOpen(false)();
        }
      }}
    >
      {<DialogTitle>Leaving the Meeting?</DialogTitle> }
      <DialogContent>
            <Container className={styles.exitButtonsContainer}>
                {canEndMeeting && !meetingAlreadyEnded &&
                <>
                    <Button variant="outlined" onClick={handleFinishAndLeaveMeeting} style={{ color: 'white', backgroundColor: 'red' }}>End Meeting For All</Button>
                </>
                }
                <Button variant="outlined" onClick={handleConfirmLeaveChoice(true)} color="primary" autoFocus>
                    Exit Meeting
                </Button>            
            </Container>
        </DialogContent>
      <DialogActions>
        <Button onClick={handleConfirmLeaveChoice(false)}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}
