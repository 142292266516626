import React from "react";
import styles from "../Meetings.module.scss";
import _ from "lodash";
import { Button, IconButton, Grid, Container, Typography, Box } from "@material-ui/core";
import { deserialize } from "../../../components/RichText/functions";
import RichText from "../../../components/RichText/RichText";

import Icon from "@mdi/react";
import { mdiPlus } from "@mdi/js";
import useMobileMenu from "../../../hooks/useMobileMenu";

const TextBlockStep = ({ step, canEdit, handleAddTextBlockStepContent }) => {
  
  const { isMobile, renderMobileMenu } = useMobileMenu();
  
  if (!step || !Array.isArray(step.content)) {
    return null;
  }

  return (
    <Container maxWidth={false}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <div className={styles.flexSpaceBetween}>
            <div>

                <Grid item xs={12}>
                  <Typography variant="h5">
                    <span className={styles.stepLabel}>{step?.name ? step.name : ''}</span>
                  </Typography>
                </Grid>

            </div>

            {isMobile ? (
              <IconButton onClick={handleAddTextBlockStepContent} className={styles.menuButtonColor} color="primary" disabled={!canEdit}>
                <Icon path={mdiPlus} size={0.75} color="#fff" />
              </IconButton>
            ) : (
              <Button
                startIcon={<Icon path={mdiPlus} size={1} color="#fff" />}
                className={styles.menuButtonColor}
                onClick={handleAddTextBlockStepContent}
                variant="contained"
                color="primary"
                disabled={!canEdit}
              >
                Update Text
              </Button>
            )}
          </div>
        </Grid>
        <Grid item xs={12}>
            {step.content.map((content, index) => (
              <Box key={index} mb={2}>
                {content.label && (
                  <Typography variant="h6" gutterBottom>
                    {content.label}
                  </Typography>
                )}
                {(content.type === 'textBlock' && content.text) && <RichText value={deserialize(content.text)} readOnly={true} /> }
              </Box>
            ))}

            
        </Grid>
      </Grid>
    </Container>
  );
};

export default TextBlockStep;