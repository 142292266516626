import { Typography } from "@material-ui/core";
import { differenceInSeconds } from "date-fns";
import React, { useEffect, useState, useRef } from "react";
import styles from "./Meetings.module.scss";
import _ from "lodash";

const MeetingTimer = ({ currentSessionSubscription, stepTimerRef, displayType = "countUpMeeting", meetingDuration, stepDuration }) => {
  const [timer, setTimer] = useState(0);

  let interval = useRef(null); 

  useEffect(() => {
    if (displayType === "countUpMeeting") {
      if (!_.isNil(_.get(currentSessionSubscription, "meetingSub.session.sessionStartTime"))) {
        interval.current = setInterval(
          () =>
            setTimer(differenceInSeconds(new Date(), new Date(currentSessionSubscription.meetingSub.session.sessionStartTime) || new Date())),
          1000
        );
      }
    }
    if (displayType === "countDownMeeting") {
      if (!_.isNil(_.get(currentSessionSubscription, "meetingSub.session.sessionStartTime"))) {
        interval.current = setInterval(
          () => {
            const elapsedSeconds = differenceInSeconds(new Date(), new Date(currentSessionSubscription.meetingSub.session.sessionStartTime) || new Date());
            const remainingSeconds = meetingDuration * 60 - elapsedSeconds;
            setTimer(remainingSeconds);
          },
          1000
        );
      }
    }    
    else { //countDownStep
      interval.current = setInterval(
        () => {
          setTimer(stepDuration * 60 - stepTimerRef.current);
        },
        1000
      );     
    }
 
    return () => clearInterval(interval.current);
  }, [currentSessionSubscription, displayType, stepTimerRef, stepDuration, meetingDuration]);

  return (
    <Typography variant="body1" align="center">
      {formatAsTime(timer)}
    </Typography>
  );
};

export default MeetingTimer;

const formatAsTime = (seconds) => {
  let time, status;
  if (seconds <= 0) {
    time = Math.abs(seconds);
    status = "error";
  } else {
    time = seconds;
    // if (seconds <= 60) {
    //   status = "warning";
    // }
  }
  const hours = Math.floor(time / 60 / 60);
  const mins = Math.floor(time / 60) - hours * 60;
  const secs = time % 60;

  if (status) {
    return (
      <span className={status === "error" ? styles.delete : undefined} style={hours > 0 ? { fontSize: '0.9em' } : {}} >
        {seconds < 0 && ("-")}
        {hours > 0 && hours.toString() + ":"}
        {mins.toString().padStart(2, "0")}  
        {":" + secs.toString().padStart(2, "0")}
      </span>
    );
  }

  return (
  <span className={styles.timer} style={hours > 0 ? { fontSize: '0.9em' } : {}} >  
    {hours > 0 && hours.toString() + ":"}
    {mins.toString().padStart(2, "0")}
    {":" + secs.toString().padStart(2, "0")}
  </span>
  );
};
