import React from "react";
import styles from "./SystemPrompts.module.scss";
import { TableRow, TableCell, MenuItem, Tooltip } from "@material-ui/core";
import UserAvatars from "../../components/UserAvatars/UserAvatars";
import Menu from "../../components/Menu/Menu";

const SystemPrompt = ({ systemprompt, snack, handleEdit, handleDelete }) => {
  const { id, value, summary, category, organization, author, alias, type } = systemprompt;

  return (
    <>
      <TableRow>
        <TableCell className={styles.firstCell}>
          <div className={styles.flex}>
            {category}
          </div>
        </TableCell>         
        <TableCell>
          <div className={styles.flex}>
            {value}
            <Menu>
              <MenuItem onClick={handleEdit}>Edit</MenuItem>
              <MenuItem onClick={handleDelete} className={styles.delete}>
                Delete
              </MenuItem>
            </Menu>
          </div>
        </TableCell>

        <TableCell>
          <div className={styles.flex}>
            {organization.name}
          </div>
        </TableCell> 
        <TableCell>
          <div className={styles.flex}>
            {alias}
          </div>
        </TableCell> 
        <TableCell>
          <div className={styles.flex}>
            {type}
          </div>
        </TableCell>                      
        <TableCell>
          <div className={styles.flex}>
            {summary}
          </div>
        </TableCell>  
        <TableCell>
          <UserAvatars users={[{ ...author }]} />
        </TableCell>                
      </TableRow>
    </>
  );
};

export default SystemPrompt;

