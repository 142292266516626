import React, { useState, useContext, useMemo } from "react";
import styles from "./Todos.module.scss";
import _, { camelCase, upperFirst } from "lodash";
import { DialogContext } from "../../context/dialogContext";
import { UserContext } from "../../context/userContext";
import { isAuthed } from "../../utils/authorization";
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  Button,
  TextField,
  TablePagination,
  IconButton,
} from "@material-ui/core";
import Icon from "@mdi/react";
import { mdiPlus, mdiCheckboxMarked, mdiAlertDecagram } from "@mdi/js";
import CardTitle from "../CardTitle/CardTitle";
import Todo from "./Todo";
import { formatAs } from "../../utils/dates";
import SelectYear from "../SelectYear/SelectYear";
import VirtualTable from "../VirtualizedTable/VirtualizedTable";
import EditDialog from "./EditDialog";
import { useParams } from "react-router-dom";
import { FetchContext } from "../../context/fetchContext";
import { SnackbarContext } from "../../context/snackbarContext";
import useMobileMenu from "../../hooks/useMobileMenu";
import ConfirmDeletionDialog from "../ConfirmDeletionDialog/ConfirmDeletionDialog";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { TODO_FIELDS } from "../../utils/fragments";

// const PRIORITY = {
//   high: 1,
//   medium: 2,
//   low: 3,
// };

const Todos = ({
  todos,
  issues,
  filterUsers,
  showAccountable,
  handleCheckbox,
  checked,
  onChangeShowComplete,
  hideHeader = false,
  hideNoteBtn = false,
  page,
  rowsPerPage,
  handleChangePage,
  handleRowsPerPage,
  showCompleted,
  searchTerm,
  sort,
  setSort,
  setSearchTerm,
  setShowCompleted,
  location,
  total,
  totalCompleted,
  planId,
  isNotesDialog = false,
}) => {
  const category = issues ? "issue" : "todo";
  const { dialog, setDialog } = useContext(DialogContext);
  const { user } = useContext(UserContext);
  const { requestFetch } = useContext(FetchContext);
  const { snack } = useContext(SnackbarContext);
  // const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const isAdmin = isAuthed(user.user, "department facilitator");

  const params = useParams();
  const [editDialog, setEditDialog] = useState(false);
  const [selectedTodo, setSelectedTodo] = useState({});

  const [deleteTodo, { loading: deleteLoading }] = useMutation(DELETE_TODO, {
    // update(cache, { data: { deleteTodo } }) {
    //   try {
    //     const todoDeleted = deleteTodo.todo;
    //     const deletedTodoCacheId = cache.identify(todoDeleted);
    //     // propagate to all todos query
    //     cache.modify({
    //       fields: {
    //         todos: (existingTodos) =>
    //           existingTodos.filter((todo) => {
    //             const cacheId = cache.identify(todo);
    //             return cacheId !== deletedTodoCacheId;
    //           }),
    //       },
    //     });
    //   } catch (e) {
    //     console.log(e);
    //   }
    // },
  });
  const [updateTodo] = useMutation(UPDATE_TODO);

  const [confirmDelete, setConfirmDelete] = useState(false);

  const handleConfirmDelete = (open, todo) => () => {
    setConfirmDelete(open);
    setSelectedTodo(todo);
  };

  const handleDeleteTodo = (todo) => async () => {
    const { id, value } = todo;
    const res = await deleteTodo({ variables: { id } });
    let delMutationData = `delete${toPascalCase("todo")}`;

    if (res.data[delMutationData]) {
      snack(`Deleted "${value}"`);
      handleConfirmDelete(false)();
      requestFetch();
      return true;
    }
    return false;
  };

  function toPascalCase(str) {
    return upperFirst(camelCase(str));
  }

  const handleUpdateStatus = (todo) => async () => {
    const { id, done, value } = todo;
    const ok = await updateTodo({ variables: { id, done: !done } });

    if (ok.data.updateTodo) {
      snack(`Marked "${value}" as ${!done ? "Complete" : "Incomplete"}`);
      requestFetch();
    }
  };

  const handleUpdatePriority = (priority, todo) => async () => {
    const { id, value } = todo;
    const ok = await updateTodo({ variables: { id, priority } });

    if (ok.data.updateTodo) {
      snack(`Marked "${value}" as ${priority} priority`);
      // requestFetch();
    }
  };

  const { isMobile, renderMobileMenu } = useMobileMenu();

  const handleEditDialog =
    (open, todo = {}) =>
    () => {
      setEditDialog(open);
      setSelectedTodo(todo);
    };

  // const sortedTodos = useMemo(() => {
  //   const { value, order } = sort;
  //   let arr = [],
  //     filteredTodos = todos.filter(filterTodos);

  //   if (value === "priority") {
  //     arr = _.sortBy(filteredTodos, (todo) => PRIORITY[todo.priority]);
  //   } else if (value === "status") {
  //     arr = _.sortBy(filteredTodos, (todo) => todo.done);
  //   } else if (value === "date") {
  //     arr = _.sortBy(filteredTodos, (todo) => new Date(parseInt(todo.createdAt))).reverse();
  //   } else if (value === "accountable") {
  //     arr = _.sortBy(filteredTodos, (todo) => _.get(todo, "user.name.first"));
  //   }

  //   if (order === "asc") {
  //     arr = arr.reverse();
  //   }

  //   return arr;
  // }, [sort, todos, searchTerm]);

  // function filterTodos(value) {
  //   // if it the case that there is no entered search-term
  //   if (searchTerm === "") {
  //     return value;
  //   }

  //   // if the execution goes past this point, this means either the user entered a search-term
  //   let userName = `${_.get(value, "user.name.first", "")} ${_.get(value, "user.name.last", "")}`;

  //   let hasSearchTermMatch =
  //     _.get(value, "value", "").toLowerCase().includes(searchTerm.toLowerCase()) ||
  //     _.get(value, "priority", "").toLowerCase().includes(searchTerm.toLowerCase()) ||
  //     userName.toLowerCase().includes(searchTerm.toLowerCase()) ||
  //     (value.done ? "complete" : "incomplete").startsWith(searchTerm.toLowerCase()) ||
  //     (value.createdAt && formatAs(value.createdAt, "MMM d").toLowerCase().includes(searchTerm.toLowerCase()));

  //   if (hasSearchTermMatch) {
  //     return value;
  //   }
  // }

  const handleAddDialog =
    (cat = category, referenceId = null, referenceModel = null, value = null, user = null) =>
    () => {
      setDialog({ ...dialog, addTodoDialog: { open: true, category: cat, referenceId, referenceModel, value, user, planId } });
    };

  const handleShowComplete = () => {
    if (!_.isNil(onChangeShowComplete)) {
      onChangeShowComplete(!showCompleted);
    }

    localStorage.setItem(`${location}.showCompleted`, JSON.stringify(!showCompleted));
    setShowCompleted(!showCompleted);
  };

  const handleSort = (value) => () => {
    const order = sort.value === value && sort.order === "desc" ? "asc" : "desc";
    if (value) {
      localStorage.setItem(`${location}.sortValue`, JSON.stringify(value));
    }
    localStorage.setItem(`${location}.sortOrder`, JSON.stringify(order));
    setSort({ value, order });
  };

  const handleToggleCategory = (item) => async () => {
    const { value, id, category } = item;
    const newCategory = category === "todo" ? "issue" : "todo";
    const ok = await updateTodo({ variables: { id, category: newCategory } });

    if (ok.data.updateTodo) {
      snack(`Changed "${value}" to be ${newCategory}`);
      requestFetch();
    }
  };

  const handleAddToMeetingDialog = (referenceId = null, referenceModel = null, plan = null, user = null, deptPlanId = null) => () => {
    setDialog({
      ...dialog,
      addToMeetingDialog: {
        open: true,
        referenceId,
        referenceModel,
        sharedPlanId: _.get(plan,"sharedPlanId",null),
        user,
        planId,
        deptPlanId,
      },
    });
  };

  return (
    <Card className={styles.card}>
      {!hideHeader && (
        <>
          <CardTitle vertical color={issues ? "red" : "purple"}>
            <Icon path={issues ? mdiAlertDecagram : mdiCheckboxMarked} size={1} color="#fff" className={styles.icon} />
            <Typography variant="h5" className={styles.title}>
              {category === "todo" ? "to dos" : "issues"}
            </Typography>
          </CardTitle>

          <CardActions className={styles.cardActions}>
            {renderMobileMenu(
              <>
                <TextField
                  className={styles.searchField}
                  label="Search"
                  type="search"
                  variant="outlined"
                  size="small"
                  value={searchTerm || ""}
                  onChange={(event) => {
                    setSearchTerm(event.target.value);
                  }}
                />
                {/* <SelectYear handleChange={(e) => setSelectedYear(e.target.value)} selectedYear={selectedYear} margin={"dense"} /> */}
                <Button variant="outlined" onClick={handleShowComplete} size="large" className={styles.menuButton}>
                  {showCompleted ? "Hide" : "Show"} Completed ({totalCompleted}/{total})
                </Button>
              </>
            )}
            {isMobile ? (
              <IconButton
                onClick={handleAddDialog()}
                disabled={!isAuthed(user.user, "department facilitator")}
                className={issues ? styles.menuButtonIssues : styles.menuButtonTodos}
              >
                <Icon path={mdiPlus} size={0.75} color="#fff" />
              </IconButton>
            ) : (
              <Button
                startIcon={<Icon path={mdiPlus} size={1} color="#fff" />}
                className={issues ? styles.menuButtonIssues : styles.menuButtonTodos}
                onClick={handleAddDialog()}
                variant="contained"
                color="primary"
                disabled={!isAuthed(user.user, "department facilitator")}
              >
                New {category === "todo" ? "to do" : "issue"}
              </Button>
            )}
          </CardActions>
        </>
      )}
      <CardContent className={styles.cardContent}>
        {!_.isEmpty(todos) ? (
          <div>
            <Table className={styles.table}>
              <colgroup>
                {showAccountable && <col style={{ width: "10%" }} />}
                <col style={{ width: showAccountable ? "35%" : "40%" }} />
                <col style={{ width: "10%" }} />
                <col style={{ width: "15%" }} />
                <col style={{ width: "15%" }} />
                <col style={{ width: "15%" }} />
              </colgroup>
              <TableHead>
                <TableRow>
                  {(showAccountable || filterUsers) && (
                    <TableCell
                      align="center"
                      sortDirection={sort.value === "accountable" ? sort.order : false}
                      className={isNotesDialog ? undefined : styles.firstCell}
                    >
                      <TableSortLabel
                        onClick={handleSort("user")}
                        active={sort.value === "user"}
                        direction={sort.value === "user" ? sort.order : "asc"}
                      >
                        Accountable
                      </TableSortLabel>
                    </TableCell>
                  )}
                  <TableCell className={showAccountable || filterUsers ? undefined : styles.firstCell}>&nbsp;</TableCell>
                  <TableCell align="center">Tie In</TableCell>
                  <TableCell align="center" sortDirection={sort.value === "priorityValue" ? sort.order : false}>
                    <TableSortLabel
                      onClick={handleSort("priorityValue")}
                      active={sort.value === "priorityValue"}
                      direction={sort.value === "priorityValue" ? sort.order : "asc"}
                    >
                      Priority
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center" sortDirection={sort.value === "status" ? sort.order : false}>
                    <TableSortLabel
                      onClick={handleSort("done")}
                      active={sort.value === "done"}
                      direction={sort.value === "done" ? sort.order : "asc"}
                    >
                      Status
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center" sortDirection={sort.value === "date" ? sort.order : false}>
                    <TableSortLabel
                      onClick={handleSort("createdAt")}
                      active={sort.value === "createdAt"}
                      direction={sort.value === "createdAt" ? sort.order : "asc"}
                    >
                      Date Created
                    </TableSortLabel>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {todos &&
                  todos.map((todo, index) => (
                    <Todo
                      key={index}
                      todo={todo}
                      category={category}
                      filterUsers={filterUsers}
                      showAccountable={showAccountable}
                      handleAddDialog={handleAddDialog}
                      handleCheckbox={handleCheckbox}
                      checked={checked}
                      canEdit={isAuthed(user.user, "department facilitator")}
                      hideNoteBtn={hideNoteBtn}
                      handleEditDialog={handleEditDialog}
                      planId={planId}
                      handleConfirmOpen={handleConfirmDelete}
                      handleUpdatePriority={handleUpdatePriority}
                      handleUpdateStatus={handleUpdateStatus}
                      handleToggleCategory={handleToggleCategory}
                      handleAddToMeetingDialog={handleAddToMeetingDialog}
                      isNotesDialog={isNotesDialog}
                    />
                  ))}
              </TableBody>
            </Table>
          </div>
        ) : (
          <Typography variant="body1" align="center">
            Nothing to show <br />
            {isAuthed(user.user, "department facilitator") && (
              <Button color="primary" onClick={handleAddDialog()}>
                Add {category === "todo" ? "to do" : "issue"}
              </Button>
            )}
          </Typography>
        )}
        {!_.isEmpty(todos) && (
          <TablePagination
            component={"div"}
            count={showCompleted ? total : total - totalCompleted}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleRowsPerPage}
            rowsPerPageOptions={[10, 25, 50, 100, 200]}
            style={{ marginTop: "auto" }}
          />
        )}
      </CardContent>
      <EditDialog
        open={editDialog}
        handleClose={handleEditDialog(false)}
        todo={selectedTodo}
        category={category}
        org={params.org}
        user={user}
        snack={snack}
        requestFetch={requestFetch}
        planId={planId}
      />
      <ConfirmDeletionDialog
        itemType={category}
        value={_.get(selectedTodo, "value")}
        confirmOpen={confirmDelete}
        handleConfirmOpen={handleConfirmDelete}
        handleDeletion={handleDeleteTodo(selectedTodo)}
        deleteLoading={deleteLoading}
      />
    </Card>
  );
};

export default Todos;

const UPDATE_TODO = gql`
  ${TODO_FIELDS}
  mutation ($id: ID!, $done: Boolean, $priority: String, $category: String) {
    updateTodo(id: $id, done: $done, priority: $priority, category: $category) {
      todo {
        ...TodoFields
      }
    }
  }
`;

const DELETE_TODO = gql`
  mutation ($id: ID!) {
    deleteTodo(id: $id) {
      todo {
        id: _id
      }
      successCriteria {
        id
        todos {
          id: _id
        }
      }
      rock {
        id
        todos {
          id: _id
        }
      }
      metric {
        id
        todos {
          id: _id
        }
      }
      objective {
        id
        todos {
          id: _id
        }
      }
    }
  }
`;

// const TodoRow = React.memo((props) => {
//   const { data, index } = props;
//   const { todos, ...otherData } = data || {};
//   return <Todo key={index} todo={todos[index]} {...otherData} />;
// });
