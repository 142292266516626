import React, { useState, useContext, useEffect } from "react";
import _ from "lodash";
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";

import { DialogContext } from "../../context/dialogContext";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { mdiDelete, mdiCalendarRemoveOutline, mdiDotsHorizontal, mdiPencilBox, mdiBullhorn, mdiAccountGroup } from "@mdi/js";
import Icon from "@mdi/react";
import { format, isToday } from "date-fns";
import { useHistory } from "react-router-dom";
import PlanPill from "../../components/PlanPill/PlanPill";
import styles from "./Meetings.module.scss";


import { TableRow, TableCell, Tooltip } from "@material-ui/core";
import UserAvatars from "../../components/UserAvatars/UserAvatars";
import Menu from "../../components/Menu/Menu";


const getDateString = (date) => {
  const dateObj = new Date(parseInt(date));
  const dateIsToday = isToday(dateObj);
  const formattedDate = format(dateObj, "MMM dd, yyyy");
  const formattedTime = format(dateObj, "h:mm a");

  return `${dateIsToday ? "Today" : formattedDate} @ ${formattedTime}`;
};

const MeetingRow = ({ meeting, org, handleDelete, isAdmin, currentCorpPlan, snack }) => {

  const { id, status, title, plan, startTime, actualStartTime, sharedPlanId, users } = meeting;

  const { dialog, setDialog } = useContext(DialogContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [departmentPlan, setDepartmentPlan] = useState(null);

  const open = Boolean(anchorEl);
  const history = useHistory();

  const {
    data: planData,
    refetch: planDataRefetch,
    loading: planDataLoading,
  } = useQuery(GET_PLANS, {
    variables: { organization: org.id },
  });

  const handleJoinMeeting = (e) => {
    e.stopPropagation();
    history.push(`/${org.id}/meeting/${id}`);
  };

  const handleEditMeeting = (e) => {
    e.stopPropagation();
    setDialog({
      ...dialog,
      addMeetingDialog: { open: true, corpPlanId: _.get(currentCorpPlan, "id"), fiscalYear: org.fiscalYear, meeting },
    });
  };

  const handleAddHeadline = (e) => {
      e.stopPropagation();
      setDialog({
      ...dialog,
      addHeadlineDialog: { open: true, meeting: meeting},
      });
  };
  const handleAddPromptStepContent= (e) => {
      e.stopPropagation();
      setDialog({
      ...dialog,
      addStepContentDialog: { open: true, contentType: 'prompt', corpPlanId: _.get(currentCorpPlan, "id"), fiscalYear: org.fiscalYear, meeting },
      });
  };

  const statusColor = status === "finished" ? "#009b72" : status === "scheduled" ? "#2589bd" : "#ff7900";

  //const dateString = status === "finished" ?  !_.isNil(actualStartTime) ? getDateString(actualStartTime) :  getDateString(startTime) : getDateString(startTime);

  const dateString = getDateString(startTime);


  const hasHeadlineStep = _.get(meeting, "steps", []).some((step) =>
      _.get(step, "content", []).some((content) => content.type === 'headline') || step.value === 'headlines'
  );
  const hasCheckInStep = _.get(meeting, "steps", []).some((step) =>
      _.get(step, "content", []).some((content) => content.type === 'prompt') || step.value === 'check-in'
  );

  const isRecurring = meeting.frequency !== 0;

  return (
    <>
      <TableRow>
        <TableCell className={styles.firstCell} align="center" >
          <Typography style={{ color: statusColor, fontWeight: "bold" }}>
            {_.startCase(status)}
          </Typography>
        </TableCell>
        <TableCell align="center" >
            {dateString}
        </TableCell>                           
        <TableCell>
          <div className={styles.flex}>
            {title}
              {isAdmin && (
                <Menu>
                  {status !== 'finished' && (
                    <div>
                      <MenuItem key={4} onClick={handleEditMeeting}>
                        <ListItemIcon>
                          <Icon path={mdiPencilBox} size={1} />
                        </ListItemIcon>
                        <ListItemText>Edit</ListItemText>
                      </MenuItem>
                      {hasHeadlineStep && (
                        <MenuItem key={5} onClick={handleAddHeadline}>
                          <ListItemIcon>
                            <Icon path={mdiBullhorn} size={1} />
                          </ListItemIcon>
                          <ListItemText>Add Headline to Meeting</ListItemText>
                        </MenuItem>
                      )}
                      {hasCheckInStep && (
                        <MenuItem key={6} onClick={handleAddPromptStepContent}>
                          <ListItemIcon>
                            <Icon path={mdiAccountGroup} size={1} />
                          </ListItemIcon>
                          <ListItemText>Add Ice-breakers to Meeting</ListItemText>
                        </MenuItem>
                      )}
                    </div>
                )}
                <MenuItem onClick={handleDelete(meeting, true)} key={2}>
                  <ListItemIcon>
                    <Icon path={mdiCalendarRemoveOutline} size={1} />
                  </ListItemIcon>
                  <ListItemText>Delete this Meeting</ListItemText>
                </MenuItem>                
                {(isRecurring && status !== 'finished') && (
                  <MenuItem onClick={handleDelete(meeting, false)} key={3}>
                    <ListItemIcon>
                      <Icon path={mdiDelete} size={1} />
                    </ListItemIcon>
                    <ListItemText>Delete this & all following Meetings</ListItemText>
                  </MenuItem>          
                )}
              </Menu>
             )}
          </div>
        </TableCell>
        <TableCell align="center" >
          {planData?.plans &&
            planData.plans.find(plan => plan.sharedPlanId === sharedPlanId)?.departmentName
          }
        </TableCell>        
        <TableCell align="center" >
          <Tooltip
            title={
              (_.isNil(users) || users.length === 0)
                ? "No one invited"
                : users.map(({ name }, idx) => `${name?.first} ${name?.last}${idx === users.length - 1 ? "" : ", "}`).join('')                
            }
          >
            <span>
             {(_.isNil(users) || users.length === 0) ? "No one invited" : `${users.length} User${users.length === 1 ? "" : "s"}`}
            </span>
          </Tooltip>
        </TableCell>
        <TableCell align="center" >
         {status === "finished" ? (
            <Button className={styles.cellButton} variant={`outlined`} onClick={handleJoinMeeting}>
              View Minutes
            </Button>            
          ) : (
            <Button className={styles.cellButtonColor} color="primary" variant={`contained`} onClick={handleJoinMeeting}>
              Join
            </Button>            
          )}
        </TableCell>                
      </TableRow>
    </>
  );
};

export default MeetingRow;

const GET_PLANS = gql`
  query MeetingRow_GetPlans($organization: ID!) {
    plans(organization: $organization, category: "1 year") {
      id
      departmentName
      sharedPlanId
      year
    }
  }
`;