import React from "react";
import styles from "../Meetings.module.scss";
import { Button, Container, Grid, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import MeetingStepContent from "../../../components/MeetingStepContent/MeetingStepContent";
import AttendeesStatus from "../../../components/AttendeesStatus/AttendeesStatus";

const LobbyStep = ({ org, step, invited, attendees, handleResetSession, canStartMeeting }) => {

  return (
    <Container maxWidth={false}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <div className={styles.flexSpaceBetween}>
            <div>

                <Grid item xs={12}>
                  <Typography variant="h5">
                    <span className={styles.stepLabel}>Your meeting will start soon</span>
                  </Typography>
                </Grid>

            </div>
          </div>
        </Grid>
        <Grid item xs={12}>
            <AttendeesStatus invited={invited} attendees={attendees}/>     
        </Grid>
        {canStartMeeting && <Grid container item xs={12} justifyContent="center">
            <Button variant="outlined" color="primary" onClick={handleResetSession}>
              Start the Meeting
            </Button>  
          </Grid>        
        }
      </Grid>
    </Container>
  );
};

export default LobbyStep;
