import React, { useState, useEffect, useContext, useMemo } from "react";
import { useMutation, useQuery } from "@apollo/client";
import gql from "graphql-tag";
import styles from "./Metrics.module.scss";
import _, { camelCase, upperFirst } from "lodash";
import { isAuthed } from "../../utils/authorization";
import { fiscalYearStart, year, setYear, subToDate, addToDate, getYear } from "../../utils/dates";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import {
  Card,
  CardContent,
  CardActions,
  Button,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  MenuItem,
  TextField,
  useMediaQuery,
  Backdrop,
  IconButton,
} from "@material-ui/core";
import Icon from "@mdi/react";
import { mdiPlus, mdiPoll } from "@mdi/js";

import { DialogContext } from "../../context/dialogContext";
import { FetchContext } from "../../context/fetchContext";
import { UserContext } from "../../context/userContext";
import { SnackbarContext } from "../../context/snackbarContext";
// import { LoadingContext } from "../../context/loadingContext";

import Metric from "./Metric";
import CardTitle from "../CardTitle/CardTitle";
import Menu from "../Menu/Menu";
import { convertToMetricsReferenceMap, getQuarters } from "../../utils/misc";
import Loading from "../Loading/Loading";
import useMobileMenu from "../../hooks/useMobileMenu";
import EditDialog from "./EditDialog";
import ConfirmDeletionDialog from "../ConfirmDeletionDialog/ConfirmDeletionDialog";
import { useParams } from "react-router-dom";
import { METRIC_FIELDS } from "../../utils/fragments";

const QUARTERS = ["Q1", "Q2", "Q3", "Q4"];

const Metrics = ({
  metrics,
  fiscalYear,
  plansOrder,
  category,
  closedYear,
  locked,
  planId,
  yearOne = new Date(),
  corpForSelectedYear,
  currentQuarter = 0,
  variables,
}) => {
  const YEARS = { Y1: year(yearOne), Y2: year(yearOne, 1), Y3: year(yearOne, 2) };
  const { dialog, setDialog } = useContext(DialogContext);
  const { requestFetch } = useContext(FetchContext);
  const { user } = useContext(UserContext);
  const { snack } = useContext(SnackbarContext);
  const params = useParams();

  // const { loading, getLoading } = useContext(LoadingContext);
  const categoryStr = _.camelCase(category);

  const fs = useMediaQuery("(max-width: 600px)");
  const [searchTerm, setSearchTerm] = useState("");
  const [processedMtrcs, setProcessedMtrcs] = useState([]);
  const [afterDnd, setAfterDnd] = useState(false);

  const [showCompleted, setShowCompleted] = useState(() => {
    const sessionStorageKey = JSON.parse(sessionStorage.getItem(`${categoryStr}Mets.showCompleted`));
    return _.isNil(sessionStorageKey) ? true : sessionStorageKey;
  });
  

  const [minCorpPlanDisplayFiscalYear, setMinCorpPlanDisplayFiscalYear] = useState(fiscalYear);

  const { data: corpPlans } = useQuery(GET_CORP_PLANS, {
    variables: { organization: params.org },
  });

  useEffect(() => {
    if (corpPlans) {
      //const maxDisplayYear = parseInt(getYear(fiscalYear));

      _.get(corpPlans, "plans", []).forEach(({ year }) => {
        if ( (year < minCorpPlanDisplayFiscalYear) && (year >= subToDate(fiscalYear,{years: 1})) ) {
          setMinCorpPlanDisplayFiscalYear(year);
        }
      });
    }
  }, [corpPlans]);

  
  const { isMobile, renderMobileMenu } = useMobileMenu();
  const [deleteMetric, { loading: deleteLoading }] = useMutation(DELETE_METRIC, {
    update(cache, { data: { deleteMetric } }) {
      try {
        const metricTobeDeleted = deleteMetric.metric;
        const deletedMetricCacheId = cache.identify(metricTobeDeleted);
        // propagate to all metrics query
        cache.modify({
          fields: {
            metrics: (existingMetric) =>
              existingMetric.filter((metric) => {
                const cacheId = cache.identify(metric);
                return cacheId !== deletedMetricCacheId;
              }),
          },
        });
      } catch (e) {
        console.log(e);
      }
    },
  });
  const [updateMetricNumber] = useMutation(UPDATE_METRIC_NUMBER);
  const [updateMetric] = useMutation(UPDATE_METRIC);
  const [confirmOpen, setConfirmOpen] = useState(false);

  const handleConfirmOpen = (open, metric) => () => {
    setConfirmOpen(open);
    setSelectedMetric(metric);
  };

  function toPascalCase(str) {
    return upperFirst(camelCase(str));
  }

  const handleDeleteMetric = (metric) => async () => {
    const { id, value } = metric;
    const res = await deleteMetric({ variables: { id } });
    let delMutationData = `delete${toPascalCase("metric")}`;

    if (res.data[delMutationData]) {
      snack(`Deleted "${value}" metric`);
      handleConfirmOpen(false)();
      return true;
    }
    return false;
  };

  const handleShowComplete = () => {
    sessionStorage.setItem(`${categoryStr}Mets.showCompleted`, JSON.stringify(!showCompleted));
    setShowCompleted(!showCompleted);
  };

  const [editDialog, setEditDialog] = useState(false);
  const [selectedMetric, setSelectedMetric] = useState({});

  const handleEditDialog =
    (open, metric = {}) =>
    () => {
      setEditDialog(open);
      setSelectedMetric(metric);
    };

  const handleUpdateStatus = (newStatus, metric) => async () => {
    const { id, value } = metric;
    const ok = await updateMetric({
      variables: {
        id,
        status: newStatus,
        value: null,
        users: null,
        measurables: null,
      },
    });

    if (ok) {
      snack(`Marked "${value}" as ${newStatus}`);
    }
  };

  const referenceMap = useMemo(() => convertToMetricsReferenceMap(metrics), [metrics]);
  const filteredMetrics = useMemo(() => {
    return metrics.filter(filterMetrics);
  }, [metrics, searchTerm, showCompleted]);

  useEffect(() => {
    if (filteredMetrics) {
      const filteredMtrcsClone = _.cloneDeep(filteredMetrics);
      const mtrcsBySpid = _.groupBy(filteredMtrcsClone, "plan.sharedPlanId");

      const groupedSortedMtrcs = plansOrder
        .filter((spid) => Object.keys(mtrcsBySpid).includes(spid))
        .reduce((sortedGroupedMtrcs, spid) => {
          sortedGroupedMtrcs.push(_.sortBy(mtrcsBySpid[spid], ["number"]));
          return sortedGroupedMtrcs;
        }, []);

      setProcessedMtrcs(groupedSortedMtrcs);
    }
  }, [filteredMetrics]);

  function filterMetrics(value) {

    // Check if showComplete is false and status is 'complete', if so filter it out
    if (!showCompleted && _.get(value, "status", "").toLowerCase() === "complete") {
      return null; // Filter out the item by returning null or undefined
    }

    // if it the case that there is no entered search-term
    if (searchTerm === "") {
      return value;
    }

    // if the execution goes past this point, this means either the user entered a search-term
    let userNames = value.users ? value.users.map((user) => `${_.get(user, "name.first", "")} ${_.get(user, "name.last", "")}`) : [];
    let firstPass =
      _.get(value, "value", "").toLowerCase().includes(searchTerm.toLowerCase()) ||
      _.get(value, "status", "").toLowerCase().includes(searchTerm.toLowerCase()) ||
      userNames.find((user) => user.toLowerCase().includes(searchTerm.toLowerCase()));

    if (firstPass) {
      return value;
    }

    let measurableUnit = _.get(value, "unit", "");
    measurableUnit = measurableUnit === null ? "" : measurableUnit;

    // the following creates a flattened 1D array of no-duplicate measurable values with their corresponding units
    let measurables = value.measurables
      ? value.measurables.flat().reduce((prev, curr) => {
          if (!isNaN(curr.value) && curr.value !== "") {
            let measurable =
              measurableUnit === "$"
                ? measurableUnit + _.round(Number(curr.value), 2).toLocaleString()
                : _.round(Number(curr.value), 2).toLocaleString() + measurableUnit;

            // makes it so duplicates are not added/included
            if (prev.indexOf(measurable) === -1) {
              prev.push(measurable);
            }
          }
          return prev;
        }, [])
      : [];
    let secondPass = measurables.find((m) => m.toLowerCase().includes(searchTerm.toLowerCase()));
    if (secondPass) {
      return true;
    }
  }

  const handleOpenDialog = ({ definedPlan, definedUsers, definedUnit, definedValue, definedCategory, definedVariables } = {}) => {
    const ctgry = definedCategory || (category === "3 year" ? "year" : "quarter");

    setDialog({
      ...dialog,
      addMetricDialog: {
        open: true,
        category: ctgry,
        planId: definedPlan || planId,
        ...(!_.isNil(definedUsers) && { users: definedUsers }),
        ...(!_.isNil(definedUnit) && { unit: definedUnit }),
        ...(!_.isNil(definedValue) && { value: definedValue }),
        variables: definedVariables || variables,
        yearOne,
      },
    });
  };

  const handleAddIssueDialog =
    (referenceId = null, referenceModel = null, value = null, user = null, deptPlanId = null, sharedPlanId = null) =>
    () => {
      setDialog({
        ...dialog,
        addTodoDialog: {
          open: true,
          category: "issue",
          referenceId,
          referenceModel,
          value,
          user,
          planId: category === "3 year" ? _.get(corpForSelectedYear,"id", null) : planId,
          deptPlanId,
          sharedPlanId,
        },
      });
    };

  const getButtons = () => {
    if (category === "3 year" && fs) {
      const curr = `${year(fiscalYearStart(fiscalYear))} - ${year(fiscalYear, 2)}`;
      return (
        <Menu button={curr}>
          <MenuItem disabled>Other years will go here</MenuItem>
        </Menu>
      );
    }
  };

  // Only used for 3 year metrics
  const measurableHeaders = useMemo(() => {
    const createdHeaders = [];
    if (minCorpPlanDisplayFiscalYear !== yearOne){
      createdHeaders.push(minCorpPlanDisplayFiscalYear);
    }
    
    createdHeaders.push(yearOne);
    createdHeaders.push(addToDate(yearOne, { years:1 }));
    createdHeaders.push(addToDate(yearOne, { years:2 }));


    return createdHeaders;
  }, [metrics, minCorpPlanDisplayFiscalYear]);

  // useEffect(() => {
  //   // flag that prevents the metric components from showing the skeleton for a split second after drag n' drop occurs
  //   if (afterDnd && getLoading() === false) {
  //     setAfterDnd(false);
  //   }
  // }, [loading]);

  const handleBeforeDragStart = ({ draggableId }) => {
    // locks the table cells' widths when a drag is happening via an inline style
    let tableCells = document.querySelectorAll(`#metricsTable td`);
    for (let i = 0; i < tableCells.length; i++) {
      tableCells[i].style.width = `${tableCells[i].offsetWidth}px`;
    }
  };

  const handleDragEnd = async ({ draggableId, destination, source }) => {
    // removes the inline style added to lock the table cells' widths upon dragging
    let tableCells = document.querySelectorAll(`#metricsTable td`);
    for (let i = 0; i < tableCells.length; i++) {
      tableCells[i].removeAttribute("style");
    }

    if (!destination || _.isEqual(source, destination)) return;

    setAfterDnd(true);

    const [metricId, mtrcGroupIdx] = draggableId.split("_");
    let copy = _.cloneDeep(processedMtrcs);

    const mtrcGroup = _.get(copy, [mtrcGroupIdx], []);
    const mappedMtrcNumber = _.get(mtrcGroup, [destination.index, "number"], 1);

    const [removed] = mtrcGroup.splice(source.index, 1);
    mtrcGroup.splice(destination.index, 0, removed);

    setProcessedMtrcs(copy);

    const ok = await updateMetricNumber({ variables: { id: metricId, number: mappedMtrcNumber } });
    if (ok.data.updateMetric) {
      snack(`Moved metric to number ${mappedMtrcNumber}`);
    }
  };

  const isAllDepartments = _.isNil(user.departmentFilter.id);
  return (
    <>
      <Card className={styles.card}>
        <CardTitle color="green" vertical>
          <Icon path={mdiPoll} size={1} color="#fff" className={styles.icon} />
          <Typography variant="h5" className={styles.title}>
            {category === "3 year" ? "3 Year" : category} Metrics
          </Typography>
        </CardTitle>
        <CardActions className={styles.cardActions}>
          {/* {isAllDepartments && (
            <span className={styles.dndInfo}>{"(Drag and drop only available when filtering by specific department)"}</span>
          )} */}
          {renderMobileMenu(
            <TextField
              className={styles.searchField}
              label="Search"
              type="search"
              variant="outlined"
              size="small"
              onChange={(event) => {
                setSearchTerm(event.target.value);
              }}
              data-test='metrics-textfield-search'
            />
          )}
          {/* <div>{getButtons()}</div>*/}
          {category === "3 year" && (
              <Button variant="outlined" onClick={handleShowComplete} size="large" className={styles.menuButton} data-test='metrics-button-toggle-completed'>
                        {showCompleted ? "Hide" : "Show"} Completed
              </Button>
            )
          }
          {isMobile ? (
            <IconButton
              onClick={handleOpenDialog}
              className={styles.iconLeft}
              color="primary"
              disabled={!isAuthed(user.user, "department facilitator") || closedYear}
              data-test='metrics-button-add'
            >
              <Icon path={mdiPlus} size={0.75} color="#fff" />
            </IconButton>
          ) : (
            <Button
              startIcon={<Icon path={mdiPlus} size={1} color="#fff" />}
              onClick={handleOpenDialog}
              className={styles.iconLeft}
              variant="contained"
              color="primary"
              disabled={!isAuthed(user.user, "department facilitator")}
              data-test='metrics-button-add'
            >
              New Metric
            </Button>
          )}
        </CardActions>
        <CardContent className={styles.cardContent}>
          <DragDropContext onDragEnd={handleDragEnd} onBeforeDragStart={handleBeforeDragStart}>
            {!_.isEmpty(metrics) ? (
              <Table className={styles.table} id="metricsTable">
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell align="center">Accountable</TableCell>
                    <TableCell className={styles.value} />
                    {category === "3 year" && (
                      <>
                        <TableCell />
                        {measurableHeaders &&
                          measurableHeaders.map((fiscalYr, idx) => {
                            const startYear = year(fiscalYearStart(fiscalYr));
                            const endYear = year(fiscalYr);
                            let yearRange = endYear;
                            if (startYear !== endYear) {
                              yearRange = `${startYear} - ${endYear}`;
                            }
     
                            const isCurrent = year(fiscalYear) === year(fiscalYr);
                            const isPast = year(fiscalYear) > year (fiscalYr);
                            return (
                              <TableCell align="center" key={idx} className={isCurrent ? styles.current : isPast ? styles.past : undefined}>
                                {yearRange}
                              </TableCell>
                            );
                          })
                        }                         
                      </>
                    )}
                    {category === "1 year" && (
                      <>
                        {QUARTERS.map((quarter, i) => {
                          const index = parseInt(quarter[1]);
                          let isPast = index < currentQuarter;
                          let isCurrent = index === currentQuarter;

                          return (
                            <TableCell key={i} align="center" className={isCurrent ? styles.current : isPast ? styles.past : undefined}>
                              {quarter} {isPast && "(Completed)"}
                              <br />
                              <span>{getQuarters(fiscalYear, i + 1)}</span>
                              <br />
                              Projected | Actual
                            </TableCell>
                          );
                        })}

                        <TableCell align="center">
                          Year
                          <br />
                          Projected | Actual
                        </TableCell>
                      </>
                    )}
                    <TableCell align="center">Status</TableCell>
                  </TableRow>
                </TableHead>
                {processedMtrcs.map((mtrcsArr, outerIdx) => {
                  const spid = _.get(mtrcsArr, ["0", "plan", "sharedPlanId"]);
                  return (
                    <Droppable droppableId={`METRIC_${outerIdx}`} type={`metric_${spid}`} key={outerIdx}>
                      {(provided, snapshot) => (
                        <TableBody {...provided.droppableProps} ref={provided.innerRef}>
                          {mtrcsArr.map((metric, innerIdx) => {
                            return (
                              <Draggable
                                key={metric.id}
                                draggableId={`${metric.id}_${outerIdx}`}
                                index={innerIdx}
                                isDragDisabled={!isAuthed(user.user, "department facilitator") || locked}
                              >
                                {(provided, snapshot) => {
                                  return (
                                    <Metric
                                      innerRef={provided.innerRef}
                                      provided={provided}
                                      key={metric.id}
                                      metric={metric}
                                      category={category}
                                      currentQuarter={currentQuarter}
                                      canEdit={isAuthed(user.user, "department facilitator") && !locked}
                                      handleAddIssueDialog={handleAddIssueDialog}
                                      afterDnd={afterDnd}
                                      referenceMap={referenceMap}
                                      corpForSelectedYear={corpForSelectedYear}
                                      handleEditDialog={handleEditDialog}
                                      handleUpdateStatus={handleUpdateStatus}
                                      handleConfirmOpen={handleConfirmOpen}
                                      yearOne={yearOne}
                                      fiscalYear={fiscalYear}
                                      minCorpPlanDisplayFiscalYear={minCorpPlanDisplayFiscalYear}
                                    />
                                  );
                                }}
                              </Draggable>
                            );
                          })}
                          {provided.placeholder}
                        </TableBody>
                      )}
                    </Droppable>
                  );
                })}
                {/* <Backdrop open={getLoading()} className={styles.backdrop}>
                <div className={styles.loadingContainer}>
                  <Loading />
                </div>
              </Backdrop> */}
              </Table>
            ) : (
              <Typography variant="body1" align="center">
                Nothing to show <br />
                {isAuthed(user.user, "department facilitator") && (
                  <Button
                    color="primary"
                    onClick={handleOpenDialog}
                    style={{ fontStyle: "italic" }}
                    disabled={!isAuthed(user.user, "department facilitator") || closedYear}
                  >
                    Add a metric
                  </Button>
                )}
              </Typography>
            )}
          </DragDropContext>
        </CardContent>
      </Card>
      {!_.isEmpty(selectedMetric) && editDialog && (
        <EditDialog
          open={editDialog}
          handleClose={handleEditDialog(false)}
          metric={selectedMetric}
          category={category}
          snack={snack}
          corpForSelectedYear={corpForSelectedYear}
          planId={planId}
          metrics={metrics}
          handleOpenAddMetricDialog={handleOpenDialog}
          yearOne={yearOne}
          plansOrder={plansOrder}
        />
      )}
      {!_.isEmpty(selectedMetric) && confirmOpen && (
        <ConfirmDeletionDialog
          itemType={"metric"}
          value={selectedMetric.value}
          confirmOpen={confirmOpen}
          handleConfirmOpen={handleConfirmOpen}
          handleDeletion={handleDeleteMetric(selectedMetric)}
          deleteLoading={deleteLoading}
        />
      )}
    </>
  );
};

export default Metrics;

const GET_CORP_PLANS = gql`
  query Metrics_GetCorpPlans($organization: ID!) {
    plans(organization: $organization, departmentName: "Corporate", category: "1 year") {
      id
      year
      closed
    }
  }
`;

const UPDATE_METRIC_NUMBER = gql`
  mutation ($id: ID!, $number: Int) {
    updateMetric(id: $id, number: $number) {
      metric {
        id
        number
      }
      metrics {
        id
        number
      }
    }
  }
`;

const DELETE_METRIC = gql`
  mutation ($id: ID!) {
    deleteMetric(id: $id) {
      metric {
        id
      }
      plan {
        id
        metrics
      }
      objectives {
        id
        metrics
      }
      metrics {
        id
        number
        weeklyTarget
        threeYearMetric
        measurables {
          value
          notes {
            id
          }
          reference {
            id
            calculateTotal
            measurables {
              value
            }
          }
          distribution
        }
      }
      weeklyTargets {
        id: _id
        oneYearMetric
      }
    }
  }
`;

const UPDATE_METRIC = gql`
  ${METRIC_FIELDS}
  mutation ($id: ID!, $value: String, $measurables: [[MetricMeasurableInput]], $users: [ID!], $status: String) {
    updateMetric(id: $id, value: $value, measurables: $measurables, users: $users, status: $status) {
      metric {
        ...MetricFields
      }
    }
  }
`;
