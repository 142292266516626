import React, { useState, useContext, useEffect, useMemo, useCallback, useRef } from "react";
import { useParams, useHistory } from "react-router-dom";
import useMeeting from "./useMeeting";
import useCorpPlan from "../../hooks/useCorpPlan";
import { DialogContext } from "../../context/dialogContext";
import { Container, Divider, Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText, MenuItem, Tooltip, Switch, Dialog, DialogContent, DialogTitle, DialogActions, Button } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import _ from "lodash";
import styles from "./Meetings.module.scss";
import useNotes from "../../components/Notes/useNotes";
import { isAuthed } from "../../utils/authorization";
import { getUser } from "../../utils/graphql";

import { Allotment, setSashSize  } from "allotment";
import "allotment/dist/style.css";

import NotesEmbedded from "../../components/Notes/NotesEmbedded";
import ScorecardStep from "./Steps/ScorecardStep";
import RockStep from "./Steps/RockStep";
import TodoStep from "./Steps/TodoStep";
import ConfirmFinishMeetingDialog from "./ConfirmFinishMeetingDialog";
import HeadlineStep from "./Steps/HeadlineStep";

import UserAvatars from "../../components/UserAvatars/UserAvatars";
import Icon from "@mdi/react";
import {
  mdiAccountGroup,
  mdiAccountMultiple,
  mdiAlertDecagram,
  mdiBullseyeArrow,
  mdiCheckCircle,
  mdiCheckboxMarked,
  mdiCrown,
  mdiHumanHandsup,
  mdiPencilBox,
  mdiRestart,
  mdiPhoneHangup,
  mdiRhombus,
  mdiText,
  mdiBullhorn,
} from "@mdi/js";
import { green, grey, purple } from "@material-ui/core/colors";
import Menu from "../../components/Menu/Menu";
import MeetingTimer from "./MeetingTimer";
import clsx from "clsx";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import MeetingEndScreen from "./Steps/MeetingEndScreen";
import MeetingCheckInScreen from "./Steps/MeetingCheckInScreen";
import MeetingConcludeScreen from "./Steps/MeetingConcludeScreen";
import LobbyStep from "./Steps/LobbyStep";
import TextBlockStep from "./Steps/TextBlockStep";

import { SnackbarContext } from "../../context/snackbarContext";

import StepTimeIndicator from "./StepTimeIndicator";
const drawerWidth = 220;


const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "-30px",
    height: "calc(100vh - 90px)",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(7) + 1,
    },
  },
  toolbar: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    padding: theme.spacing(0, 1),
    marginTop: "60px",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  listItemIcon: {
    minWidth: "40px",
  },
  drawerBottom: {
    height: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerBottomOpen: {
    height: drawerWidth,
    transition: theme.transitions.create("height", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerBottomClose: {
    transition: theme.transitions.create("height", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    height: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      height: theme.spacing(7) + 1,
    },
  },  
}));

const Meeting = ({ user, org }) => {
  const classes = useStyles();
  const theme = useTheme();
  const params = useParams();
  const history = useHistory();

  const [open, setOpen] = useState(false);
  const [restartMeetingDialogOpen, setRestartMeetingDialogOpen] = useState(false);

  const { corpForSelectedYear } = useCorpPlan({ orgId: params.org, fiscalYear: org.fiscalYear });
  const { dialog, setDialog } = useContext(DialogContext);

  const handleToggleDrawerOpen = () => {
    setOpen(!open);
  };

  const stepTimerRef = useRef(0); 
  const isLeavingRef = useRef(false);
  const meetingAlreadyEndedRef = useRef(false);

  const handleLeaveMeeting = () => {
    isLeavingRef.current=true;
    handleExitMeeting();
    handleClose();
    history.push(`/${org.id}/meetings`);
    isLeavingRef.current=false;    
  };

  const {
    loading,
    inMeeting,
    canStartMeeting,
    userLoading,
    subloading,
    isAdmin,
    isTempAdmin,
    data,
    currentStepIndex,
    completedStepIndex,
    currentSessionSubscription,
    confirmOpen,
    filterOnSelected,
    stepFilters,
    currentLeader,
    corpPlanIdForMeeting,
    canAdminMeeting,
    handleChangeStep,
    handleResetSession,
    handleRestartMeeting,
    handleRateMeeting,
    handleFinishMeeting,
    handleExitMeeting,
    handlePassAdminControl,
    handleRevokeAdminControl,
    handleConfirmOpen,
    handleToggleFilterOnSelected,
    handleSetStepFilters,
    handleAddAdditionaUserInfoToSession,
    handleJoinMeeting,
    handleTabClosing,
  } = useMeeting(user, _.get(corpForSelectedYear,"id",null), handleLeaveMeeting);

 
  const steps = useMemo(() => _.get(data, 'meeting.steps', []), [data]);
  const currentStep = useMemo(() => _.get(steps, `[${currentStepIndex}]`, null), [steps, currentStepIndex]);

  //Do not use NotesContext directly, as a dialog based note can be opened while an embedded note is displayed.
  //For that reason, we need to replicated some noteContext logic here.
  const [notesData, setNotesData] = useState({ id: null, model: null, value: null, user: null, additionalProps: {}, planId: null });
  const [refetchId, setRefetchId] = useState(); // no longer used
  
  // additionalProps can only include variables to the query
  const notes = (id, model, core = null, openInEditMode = false, openNotesDialog = true, value = null, user = null, additionalProps = {}, planId = null) => {
    setNotesData({ id, model, core, openInEditMode, openNotesDialog, value, user, additionalProps, planId });
  };

  // for handling sub array notes
  const onCreateNote = () => {
    setNotesData({ ...notesData, additionalProps: { ...notesData.additionalProps, new: false } });
  };

  const { id, model, core, openInEditMode, openNotesDialog, value: referenceObjectValue, additionalProps, planId } = notesData;

 
  const { snack } = useContext(SnackbarContext);
/*
  const {
    data: { id, model, core, openInEditMode, openNotesDialog, user: userId, value: referenceObjectValue, additionalProps, planId },
    notes,
    setRefetchId,
    onCreateNote,
  } = useContext(NotesContext);
*/
  const {
     handleClose,
  } = useNotes({
    user,
    snack,
    notes,
    model,
    setRefetchId,
    openInEditMode,
    onCreateNote,
    openNotesDialog,
  });

  const handleNotes = useCallback(
    (id, model = "meeting", core = "minutes", openInEditMode = false) => {
      // By default, openInEditMode is true, and openNotesDialog is locked to false
      notes(id, model, core, openInEditMode, false);
    },
    [] // Add dependencies here if `notes` relies on any external values
  );
  
  useEffect(() => {
    const id = _.get(data, "meeting.id");
    if (id) {
      handleNotes(id, "meeting", "minutes");
    }
  }, [data, handleNotes]);



  const handleFinishAndLeaveMeeting = () => {
    handleFinishMeeting();
  };
  
  const handleConfirmLeaveChoice = (confirmed) => () => {
    handleConfirmOpen(false)();
    if (confirmed) {
      handleLeaveMeeting();
    }
  };

  useEffect(() => {
    // lose internet connection
    window.addEventListener("offline", handleOffline);
    // regain internet connection
    window.addEventListener("online", handleOnline);
    return () => {
      window.removeEventListener("offline", handleOffline);
      window.removeEventListener("online", handleOnline);
    };
  });
  const handleOffline = () => {
    console.log("handleOffline triggered");
    handleExitMeeting();
  };
  const handleOnline = () => {
    console.log("handleOnline triggered");
    handleJoinMeeting();
  }; 

  const handleConfirmLeaveMeeting = () => {
    handleConfirmOpen(true)();
  };


  useEffect(() => {
    const handleBeforeUnload = (event) => {
      console.log("handleBeforeUnload triggered");
      handleTabClosing();
    };

    const handlePopState = (event) => {
      console.log("handlePopState2 triggered");
      handleConfirmLeaveMeeting();
    };

    const unblock = history.block((location, action) => {
      console.log("history:entry triggered");
      if (action === 'POP' || action === 'PUSH') {
        if (isLeavingRef.current) {
          return true;
        }
        console.log("history:POP-PUSH triggered");    
        if (meetingAlreadyEndedRef.current) {
          console.log("history:POP-PUSH - Meeting has ended leave meeting");
          handleLeaveMeeting();
        }
        else {      
          console.log("history:POP-PUSH - confirm leave meeting");  
         handleConfirmLeaveMeeting();
         return false;
        }
      }        
      return true;
    });

    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('popstate', handlePopState);
      unblock();
      handleTabClosing();
    };
  }, [history]);
  
  
  if (loading || userLoading || subloading) return null;
  if (!data) return null;

  const { owner, users, endTime, plan, sharedPlanId } = data.meeting;
  
  const corpPlanId = _.get(corpPlanIdForMeeting,'id',corpForSelectedYear.id);
  const currentUser = _.get(user,'user',null)
  const currentUserId = _.get(user,'user.id','no-user-id')

  let allInvitedUsers = [...users];

  if (!allInvitedUsers.some((u) => u.id === owner.id)) {
    allInvitedUsers = [...allInvitedUsers, owner];
  }

  //this is the final set of invited users
  const userById = _.keyBy(allInvitedUsers, "id");
  const userIds = Object.keys(userById);
   
  //okay, now additional users, such as ventrek admins could join the meeting, we'll add them to the invited list here
  if (isAuthed(currentUser, "ventrek facilitator") && !allInvitedUsers.some((u) => u.id === currentUser.id)) {  
    allInvitedUsers = [...allInvitedUsers, currentUser];
    handleAddAdditionaUserInfoToSession(currentUserId);
  }
  const userWithAdminById = _.keyBy(allInvitedUsers, "id");

  const attendees = currentSessionSubscription?.meetingSub?.session?.users?.map((userId) => {
      if (!_.isNil(userWithAdminById[userId])) {
        return (userWithAdminById[userId]);
      }
      else {
        const matchingUser = currentSessionSubscription?.meetingSub?.additionalUsersInfo?.find((u) => u.id === userId);
        return matchingUser;
      }
    }).filter(u => (u)) || [];


  const stepTime = _.get(currentSessionSubscription, "meetingSub.session.stepTime");
  const isLeading = currentLeader === currentUserId;
  const canEdit = isAdmin || isTempAdmin;
  const canNavigate = isLeading;
  const sessionEndTime = _.get(currentSessionSubscription, "meetingSub.session.sessionEndTime");
  meetingAlreadyEndedRef.current = !_.isNil(sessionEndTime) || !_.isNil(endTime);
  const meetingAlreadyEnded = meetingAlreadyEndedRef.current;


  const handleAddPromptStepContent = (e) => {
    e.stopPropagation();
    setDialog({
      ...dialog,
      addStepContentDialog: { open: true, contentType: 'prompt', corpPlanId, fiscalYear: org.fiscalYear, meeting: data.meeting },
    });
  };

  const handleAddTextBlockStepContent= (e) => {
    e.stopPropagation();
    setDialog({
      ...dialog,
      addStepContentDialog: { open: true, contentType: 'textBlock', corpPlanId, fiscalYear: org.fiscalYear, meeting: data.meeting },
    });
  };

  
  
  const stepsComponents = {
    "lobby": <LobbyStep org={org} handleResetSession= {handleResetSession} step={currentStep} invited={allInvitedUsers} attendees={attendees} canStartMeeting={canStartMeeting}/>,
    "check-in": <MeetingCheckInScreen step={currentStep} canEdit={canEdit} handleAddPromptStepContent={handleAddPromptStepContent}/>,
    "headlines": <HeadlineStep step={currentStep} canEdit={canEdit} meeting={_.get(data, "meeting",null)} corpPlanId={corpPlanId}/>,
    "text-block": <TextBlockStep step={currentStep} canEdit={canEdit} handleAddTextBlockStepContent={handleAddTextBlockStepContent}/>,    
    "scorecard": <ScorecardStep userIds={userIds} step={currentStep} org={org} canEdit={canEdit} corpPlanId={corpPlanId} sharedPlanId={sharedPlanId} filterOnSelected={filterOnSelected} handleSetStepFilters={handleSetStepFilters} stepFilters={stepFilters} isLeading={isLeading}/>,
    "rocks": <RockStep userIds={userIds} org={org} step={currentStep} canEdit={canEdit} corpPlanId={corpPlanId} sharedPlanId={sharedPlanId} fiscalYear={org.fiscalYear} filterOnSelected={filterOnSelected} handleSetStepFilters={handleSetStepFilters} stepFilters={stepFilters} isLeading={isLeading}/>,
    "to-dos": <TodoStep userIds={userIds} org={org} step={currentStep} category={"todo"} canEdit={canEdit} corpPlanId={corpPlanId} sharedPlanId={sharedPlanId} filterOnSelected={filterOnSelected} handleSetStepFilters={handleSetStepFilters} stepFilters={stepFilters} isLeading={isLeading}/>,
    "issues": <TodoStep userIds={userIds} org={org} step={currentStep} category={"issue"} canEdit={canEdit} corpPlanId={corpPlanId} sharedPlanId={sharedPlanId} filterOnSelected={filterOnSelected} handleSetStepFilters={handleSetStepFilters} stepFilters={stepFilters} isLeading={isLeading}/>,
    "conclude": <MeetingConcludeScreen user={currentUser} step={currentStep} canEdit={false} handleRateMeeting={handleRateMeeting} attendees={attendees} meeting={_.get(data, "meeting",null)}/>,
    "end-screen": <MeetingEndScreen org={org} canEdit={canEdit} minutes={_.get(data, "meeting.minutes",null)}/>,
  };

  const handleEditMeeting = (e) => {
    e.stopPropagation();
    setDialog({
      ...dialog,
      addMeetingDialog: { open: true, corpPlanId, fiscalYear: org.fiscalYear, meeting: _.get(data, "meeting") },
    });
  };

  const handleConfirmRestartMeeting = (e) => {

    setRestartMeetingDialogOpen(true);
  };

  const canEndMeeting = isAdmin || (isTempAdmin && !attendees.some((attendee) => attendee.id === _.get(data, "meeting.owner.id")));

  const canBeTempAdmin = (attendees || []).filter((a) => {
    return currentUserId !== a.id;
  });

  const handleSetStepTimer = (timeSpentInSeconds) => {
    stepTimerRef.current = timeSpentInSeconds;
  };

  //this is really a mess with the stepTimer updates triggered within StepTimeIndicator
  //need to clean this up. 
  const stepDuration = Number(_.get(steps, `[${currentStepIndex}].duration`, 0));
  const meetingDuration = (steps || []).reduce((acc, step) => acc + Number(step.duration), 0);
  setSashSize(20);
  return (
    <>
    <div className={classes.root}>
      <div className={styles.stepContainer}>
        <div className={styles.meetingTopBar}>
          <div className={styles.userContainer}>
            <Icon path={mdiAccountMultiple} size={1} color={grey[800]} style={{ marginRight: "4px" }} />
            {!_.isEmpty(_.get(currentSessionSubscription, "meetingSub.session.users", [])) && (
              <UserAvatars small users={attendees} hidden maxUsersVisible={5} user={currentUser} currentLeaderId={currentLeader} handleShowIcon={(u) => {return (canAdminMeeting(u, _.get(data, "meeting.owner")))}}/>
            )}
          </div>
          <div className={styles.toolsContainer}>
            {(isLeading && !meetingAlreadyEnded) && (
              <>
                <Tooltip title="Toggle Address Items View">
                  <Switch
                    checked={filterOnSelected}
                    onChange={handleToggleFilterOnSelected}
                    name={`filterOnSelected`}
                    data-test='mt-switch-filter-on-selected'
                  />
                </Tooltip>

                <Tooltip title="Restart Meeting">
                  <IconButton 
                    onClick={handleConfirmRestartMeeting} 
                    data-test='MT-button-restart-meeting'
                  >
                    <Icon path={mdiRestart} size={1}  />
                  </IconButton>     
                </Tooltip>                
              </>
              )}
            
            {(isAdmin && !meetingAlreadyEnded) && (
              <>
                <Tooltip title="Edit Meeting">
                  <IconButton 
                    onClick={handleEditMeeting} 
                    data-test='MT-button-edit-meeting'
                  >
                    <Icon path={mdiPencilBox} size={1}  />
                  </IconButton>     
                </Tooltip>     

                <Menu icon="crown" tooltipText="Update Meeting Control" color={isLeading ? 'rgba(180, 183, 46, 1)' : 'rgba(0, 0, 0, 0.54)'}>
                  {(isAdmin && !isLeading) && (
                    <MenuItem onClick={handleRevokeAdminControl}>
                      <span style={{ fontWeight: !isLeading ? 'bold' : 'normal' }}>Become Leader</span>
                    </MenuItem>
                  )}
                  {isLeading && ( 
                    <MenuItem key={`theLeader`} onClick={() => {}}>
                      {"You are Leading"}
                      <Icon path={mdiCrown} size={0.75} color="rgba(180, 183, 46, 1)" />                       
                    </MenuItem>                                
                  )}
                  {!_.isEmpty(canBeTempAdmin) ? (
                    canBeTempAdmin
                      .map((user) => (
                        <MenuItem key={user.id} onClick={() => handlePassAdminControl(user.id)}>                         
                          {user.name.first} {user.name.last}{" "}
                          {currentUserId === user.id && <span>(You)</span>}
                          {currentLeader === user.id && (
                            <Icon path={mdiCrown} size={0.75} color="rgba(180, 183, 46, 1)" />
                          )}                           
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem>No users</MenuItem>
                    )}
                  </Menu>
              </>
            )}
          </div>
        </div>
        <div className={styles.meetingContainer}>
          <Allotment vertical style={{
        '--separator-border': 'rgba(255, 0, 0, 0.7)', // Purple with transparency
        '--focus-border': '#00ff00', // Bright blue for focus
        '--sash-size': '36px', // 8px sash
      }}>
        <Allotment.Pane>
          <div className={styles.stepContainer} style={{paddingBottom: '10px'}}>
            <div className={styles.stepContentFlex}>
              {!_.isNil(currentStep) && stepsComponents[currentStep.value] && !meetingAlreadyEnded && (
                <Container className={styles.container} maxWidth={false}>
                  {inMeeting ? (
                    stepsComponents[currentStep.value]
                  ) : (
                    stepsComponents["lobby"]
                  )}
                </Container>
              )}
              {meetingAlreadyEnded && (
                <Container className={styles.container} maxWidth={false}>
                  {stepsComponents["end-screen"]}
                  </Container>
                )}        
            </div>
          </div>
        </Allotment.Pane>
        {(!meetingAlreadyEnded && inMeeting && (isAdmin || isTempAdmin)) &&
          <Allotment.Pane preferredSize={40} minSize={40}>
            <div className={styles.stepContainer}>
              <div className={styles.stepContentFlex}>
                <Container className={styles.meetingBottomBar} maxWidth={false}>
                  <NotesEmbedded user={user} notesData={notesData} notes={notes} onCreateNote={onCreateNote} refetchId={refetchId} setRefetchId={setRefetchId}/>
                </Container>
              </div>
            </div>
          </Allotment.Pane>
        }
      </Allotment>

    </div>
        


        
      </div>

      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
        anchor="right"
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleToggleDrawerOpen}>{open ? <ChevronRightIcon /> : <ChevronLeftIcon />}</IconButton>
          {inMeeting && <MeetingTimer currentSessionSubscription={currentSessionSubscription} stepTimerRef={stepTimerRef} displayType={`countDownMeeting`} meetingDuration={meetingDuration} stepDuration={stepDuration}/>}
        </div>
        <Divider />
        <List className={styles.menulist}>
          {inMeeting && !meetingAlreadyEnded ? (
            steps.map(({ name, value, duration }, i) => {
              const isCurrentStep = i === currentStepIndex;
              const isReviewed = completedStepIndex > i;
              const stepStartTime = _.get(stepTime, `${i}.stepStartTime`);
              const timeSpentInSeconds = _.get(stepTime, `${i}.timeSpentInSeconds`) || 0;

        
              // const isLastStep = i === steps.length - 1;
              return (
                <TimerTooltip isCurrentStep={isCurrentStep} stepName={name} stepTimerRef={stepTimerRef} stepDuration={stepDuration} key={i}>
                  <div>
                    <ListItem
                      key={i}
                      className={styles.stepItem}
                      button
                      disabled={subloading || !(canNavigate)}
                      onClick={handleChangeStep(i)}
                    >
                      <ListItemIcon className={classes.listItemIcon}>
                        <Icon
                          path={stepIcons[value]}
                          size={1}
                          color={isCurrentStep ? purple[400] : isReviewed ? green[400] : grey[800]}
                          className={styles.cardIcon}
                        />
                      </ListItemIcon>
                      <ListItemText>
                        <span className={styles.flex}>
                          {name}
                          {isReviewed && <Icon path={mdiCheckCircle} color={green[400]} size={0.75} className={styles.check} />}
                        </span>
                      </ListItemText>
                      {isCurrentStep && (
                        <StepTimeIndicator stepStartTime={stepStartTime} timeSpentInSeconds={timeSpentInSeconds} stepDuration={stepDuration} handleSetStepTimer={handleSetStepTimer}/>
                      )}
                      {/* <ListItemSecondaryAction className={isCurrentStep ? undefined : styles.label}>{duration}m</ListItemSecondaryAction> */}
                    </ListItem>
                  </div>
                </TimerTooltip>
              );
            })
          ) : (
            <ListItem className={styles.stepItem} button disabled>
            </ListItem>
          )
        }
        </List>
        <Divider />
        <List disablePadding>
          <Tooltip title="Leave the Meeting">
            <ListItem
              className={styles.labelEndMeeting}
              button
              disabled={subloading}
              onClick={meetingAlreadyEnded ? handleLeaveMeeting : handleConfirmOpen(true)}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <Icon path={mdiPhoneHangup} size={1} color={"#fff"} className={styles.cardIcon} />
              </ListItemIcon>
              <ListItemText>
                <span className={styles.flex}>{meetingAlreadyEnded ? "Meeting Ended" : "End Meeting"}</span>
              </ListItemText>
            </ListItem>
          </Tooltip>
        </List>
      </Drawer>


    
    </div>

    <ConfirmFinishMeetingDialog
        canEndMeeting = {canEndMeeting}
        confirmOpen={confirmOpen}
        meetingAlreadyEnded={meetingAlreadyEnded}
        handleConfirmOpen={handleConfirmOpen}
        handleFinishAndLeaveMeeting={handleFinishAndLeaveMeeting}
        handleConfirmLeaveChoice={handleConfirmLeaveChoice}
      />    


      <Dialog
          open={restartMeetingDialogOpen}
          onClose={(event, reason) => {
            if (reason !== "backdropClick") {
              setRestartMeetingDialogOpen(false);
            }
          }}
        >
          <DialogTitle>Restart Meeting</DialogTitle>
          <DialogContent>
            Are you sure you wish to restart the Meeting? Participants will return to the lobby and timer progress will be reset.
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              onClick={() => {
                setRestartMeetingDialogOpen(false);
              }}
            >
              Cancel
            </Button>
            <Button 
              color="primary" 
              onClick={() => {
                  handleRestartMeeting();
                  setRestartMeetingDialogOpen(false);
                }} 
              variant="contained">
                Restart
            </Button>
          </DialogActions>
        </Dialog>

    </>

    
  );
};

export default Meeting;

  // New function to format seconds or milliseconds into hh:mm:ss
  const formatTime = (timeInSecondsOrMilliseconds, isMilliseconds = false) => {

    const sign = timeInSecondsOrMilliseconds < 0 ? '-' : '';
    timeInSecondsOrMilliseconds = Math.abs(timeInSecondsOrMilliseconds);
    const totalSeconds = isMilliseconds ? Math.floor(timeInSecondsOrMilliseconds / 1000) : timeInSecondsOrMilliseconds;
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    const pad = (num) => String(num).padStart(2, '0');

    if (hours > 0) {
      return `${sign}${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
    } else {
      return `${sign}${pad(minutes)}:${pad(seconds)}`;
    }
  };

const stepIcons = {
  "lobby": mdiAccountGroup,
  "check-in": mdiAccountGroup,
  headlines: mdiBullhorn,
  scorecard: mdiBullseyeArrow,
  rocks: mdiRhombus,
  issues: mdiAlertDecagram,
  "to-dos": mdiCheckboxMarked,
  "text-block": mdiText,
  conclude: mdiHumanHandsup,
};

const TimerTooltip = ({ stepTimerRef, stepName, isCurrentStep, children, stepDuration }) => {
  const [stepTimer, setStepTimer] = useState(0);
  const interval = useRef(null); 
  
  useEffect(() => {
    if (isCurrentStep) {
      interval.current = setInterval(() => {
      setStepTimer(stepTimerRef.current);  // ✅ UI updates every 5s, but `Meeting` doesn't re-render
         }, 1000);
    }
    else {
      clearInterval(interval.current);
    }
    return () => clearInterval(interval.current);
  }, [stepTimerRef, isCurrentStep]);

  //const stepDuration = _.get(steps, `[${currentStepIndex}].duration`, 0);
  let stepTimerDisplay = stepDuration * 60 - stepTimer;
  let stepTimeRemainingFormatted = formatTime(stepTimerDisplay);
 
  const title = isCurrentStep ? stepTimeRemainingFormatted : stepName;
  return (
    <Tooltip title={title} placement="right">
      {children}
    </Tooltip>
  );
}