import React, { useState } from "react";
import { IconButton, Button, Tooltip, Menu as MaterialUiMenu } from "@material-ui/core";
import Icon from "@mdi/react";
import { mdiDotsHorizontal, mdiChevronDown, mdiPencil, mdiCrown } from "@mdi/js";

const Menu = ({ icon, button, color = "rgba(0, 0, 0, 0.54)", children, className, tooltipText = '' }) => {
  const [menuOpen, setMenuOpen] = useState(null);
  let path;

  switch (icon) {
    case "arrow":
      path = mdiChevronDown;
      break;
    case "pencil":
      path = mdiPencil;
      break;
    case "crown":
      path = mdiCrown;
      break;
    default:
      path = mdiDotsHorizontal;
      break;
  }

  const handleMenuOpen = (open) => (e) => {
    if (open) {
      setMenuOpen(e.currentTarget);
    } else {
      setMenuOpen(null);
    }
  };

  return (
    <>
      <div className={className}>
        <Tooltip title={tooltipText} > 
        {button ? (
          <Button onClick={handleMenuOpen(true)}>{button}</Button>
        ) : (
          <IconButton onClick={handleMenuOpen(true)} size="small">
            <Icon path={path} size={0.75} color={color} />
          </IconButton>
        )}
        </Tooltip>
      </div>

      <MaterialUiMenu
        anchorEl={menuOpen}
        open={Boolean(menuOpen)}
        onClose={handleMenuOpen(false)}
        disableScrollLock={true}
        disableRestoreFocus={true}
      >
        {children}
      </MaterialUiMenu>
    </>
  );
};

export default Menu;
